import { RSAA  } from 'redux-api-middleware';
import { 
    STUDENT_GET_FAILURE,
    STUDENT_GET_REQUEST,
    STUDENT_GET_SUCCESS,
    STUDENT_DELETE_FAILURE,
    STUDENT_DELETE_REQUEST,
    STUDENT_DELETE_SUCCESS,
    STUDENT_EDIT_FAILURE,
    STUDENT_EDIT_REQUEST,
    STUDENT_EDIT_SUCCESS,
    STUDENT_ADD_REQUEST,
    STUDENT_ADD_SUCCESS,
    STUDENT_ADD_FAILURE,
    RFID_STUDENT_GET_REQUEST,
    RFID_STUDENT_GET_SUCCESS,
    RFID_STUDENT_GET_FAILURE,
    STUDNET_UPLOAD_SUCCESS,
    STUDNET_UPLOAD_FAILURE,
    STUDNET_UPLOAD_REQUEST,
    STUDENT_FOCUS_SUCCESS,
    STUDENT_FOCUS_REQUEST,
    STUDENT_FOCUS_FAILURE,
    RFID_SET

} from './Types';
import {url} from '../url';


export const getStudent = (idSubject) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/student/getStudent?id=${idSubject}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                types: [
                    STUDENT_GET_REQUEST,
                    {
                        type: STUDENT_GET_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            return response;
                        }
                    },
                    STUDENT_GET_FAILURE
                ]
            },
        })
    }
   
}
 
export const delteStudentSubject = (idRegister,idSubject,status) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/student/deleteStudent?id=${idRegister}`,
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                types: [
                    STUDENT_DELETE_REQUEST,
                    {
                        type: STUDENT_DELETE_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            if(status===undefined ||status===null){
                                dispatch(getStudent(idSubject))
                            }else{
                                dispatch(getStudentRegisterRFID(idSubject))
                            }   
                            return response;
                        }
                    },
                    STUDENT_DELETE_FAILURE
                ]
            },
        })
    }
   
}

export const editStudentSubject = (_id,group,idSubject) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/student/editGroup`,
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                body: JSON.stringify({
                    _id,
                    group
                }),
                types: [
                    STUDENT_EDIT_REQUEST,
                    {
                        type: STUDENT_EDIT_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            dispatch(getStudent(idSubject))
                            return response;
                        }
                    },
                    STUDENT_EDIT_FAILURE
                ]
            },
        })
    }
   
}

export const addStudentSubject = (classId, studentId, group) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/student/addStudent`,
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                body: JSON.stringify({
                    classId,
                    studentId,
                    group
                }),
                types: [
                    STUDENT_ADD_REQUEST,
                    {
                        type: STUDENT_ADD_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            dispatch(getStudent(classId))
                            return response;
                        }
                    },
                    STUDENT_ADD_FAILURE
                ]
            },
        })
    }
   
}

export const resetValidate = (text) => {
    return {
        type: STUDENT_ADD_FAILURE,
        payload: {response : text}
    }
}

export const getStudentRegisterRFID = (idSubject) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/student/rfidRegister?id=${idSubject}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                types: [
                    RFID_STUDENT_GET_REQUEST,
                    {
                        type: RFID_STUDENT_GET_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            return response;
                        }
                    },
                    RFID_STUDENT_GET_FAILURE
                ]
            },
        })
    }
}

export const setDataStudnet = (arr) => {
    return (dispatch) => {
        dispatch({
            type: RFID_SET,
            payload: arr
        })
   
    }
}



export const addStudentExcel = (dataStudnet,idSubject) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/student/addStudentExcel`,
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                body: JSON.stringify(
                    dataStudnet
                ),
                types: [
                    STUDNET_UPLOAD_REQUEST,
                    {
                        type: STUDNET_UPLOAD_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            dispatch(getStudent(idSubject))
                            return response;
                        }
                    },
                    STUDNET_UPLOAD_FAILURE
                ]
            },
        })
    }
} 

export const focusStudent = (_id,status) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/student/setFocus`,
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                body: JSON.stringify({
                    _id,
                    status
                }),
                types: [
                    STUDENT_FOCUS_REQUEST,
                    {
                        type: STUDENT_FOCUS_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            return response;
                        }
                    },
                    STUDENT_FOCUS_FAILURE
                ]
            },
        })
    }
   
}