import {
    LIST_SUBJECT_GET_SUCCESS,
    LIST_SUBJECT_GET_REQUEST,
    LIST_SUBJECT_SET
} from '../actions/Types';

const initialState = {
    loading: false,
    listSubjects: []
};


// const updatePermissionSubjectList = (state, action) => {
//     return [...action.payload]
// }

export default (state = initialState, action) => {
    switch (action.type) {
        case LIST_SUBJECT_GET_SUCCESS:
            return { ...state, listSubjects: action.payload, loading: false }
        case LIST_SUBJECT_GET_REQUEST:
            return { ...state, loading: true }
        case LIST_SUBJECT_SET:
            return { ...state, listSubjects: action.payload, }
        default:
            return state;
    }
};