import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import TableResult from './table'
import {
    setPath,
} from '../../actions';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 1,
        overflowX: 'auto',
    },
    table: {
        minWidth: '100%',
        maxWidth: '100%',
    },
    tableWrapper: {
        overflowX: 'auto',
        marginTop: theme.spacing.unit,
        margin: theme.spacing.unit * 2,
    },
    headerpaper: {
        overflowX: 'auto',
        marginTop: '0%',
        margin: theme.spacing.unit * 2,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        gridbutton: {
            marginRight: '-100%'
        }
    },
    gridlefttype: {
        marginLeft: '2%',
    },
    fontstyle: {
        fontSize: '150%',
        marginTop: '1%',
        textAlign: 'Left'
    },
    progress: {
        margin: theme.spacing.unit * 20,
    },
});

class TabResult extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            group: '',
            category: '',

        };
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    render() {    
        const { classes } = this.props;
        return (
            <Paper className={classes.root}>
                <Grid  xs={12} sm={6} md={6} alignItems="flex-end" justify="flex-start" >
                <div style={{margin: '3% 3% 2% 5%'  }}>
                    <font>วันที่: {this.props.dateList}</font>
                    </div>
                </Grid>
                <div className={classes.tableWrapper}>
                    <TableResult report={this.props.report} />
                </div>
            </Paper>
        );
    }
}

TabResult.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
    }
}
export default connect(mapStateToProps, {
    setPath
})(withStyles(styles)(TabResult))
