import {
    SUBJECT_GET_SUCCESS,
    SUBJECT_GET_REQUEST,
    SUBJECT_SET
} from '../actions/Types';

const initialState = {
    loading: false,
    subjects: []
    // title: '',
    // group: '',
    // term: '',
    // checklist: '',
    // id: '',
    // colorCode: '',
    // name: '',
    // idclass: '',
    // JustTerm: ''
};


// const updatePermissionSubject = (state, action) => {
//     return [...action.payload]
// }

export default (state = initialState, action) => {
    switch (action.type) {
        case SUBJECT_GET_SUCCESS:
            return { ...state, subjects: action.payload, loading: false }
        case SUBJECT_GET_REQUEST:
            return { ...state,  loading: true }
        case SUBJECT_SET:
            return {...state, subjects: action.payload}
        default:
            return state;
    }
};