import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import deleteIcon from '../../icon/IconDelete.png';
import Button from '@material-ui/core/Button';
import '../../App.css';

class DeleteDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    };
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    if(this.props.statusDelete){
      this.props.handleClose()
    }
  };

  confirm = () => {
    this.props.onConfirmCallback();
    this.handleClose();
    if(this.props.statusDelete){
      this.props.handleClose()
    }
  }

  componentWillReceiveProps(nextprops) {
    if (this.state.open !== nextprops.openDelete) {
      this.setState({
        open: nextprops.openDelete
      })
    }
  }


  render() {
    const btncancel = {
      color: '#f1f8e9',
      backgroundColor: '#ef5350'
    }
    return (
      <div style={{ width: '20%' }}>
        <div onClick={this.handleClickOpen} >
          {this.props.statusDelete ?
            <font  style={{ fontSize: '1.8vh' }}>ลบ</font>
            :
            <Button title="ลบ" onClick={this.handleClickOpen}  ><img src={deleteIcon} alt={"delete"} height='25vh' /></Button>
          }
        </div>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='xs'
          fullWidth
        >
          <DialogTitle id="alert-dialog-title" ><font>{"ยืนยันการลบ"}</font> </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.textDelete}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} style={btncancel} variant="contained">
              <font> ยกเลิก</font>
            </Button>
            <Button onClick={this.confirm} color="primary" autoFocus variant="contained">
              <font> ยืนยัน</font>
            </Button>
          </DialogActions>
        </Dialog>


      </div>
    )
  }
}
export default DeleteDialog;