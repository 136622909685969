import React, { Component } from 'react';
import './index.css';
import { Route, Switch, Redirect} from 'react-router-dom';
// import history from "./history";
import Dashboard from './component/Dashboard';
import NameChecklist from './component/NameChecklist';
import Makrubcheck from './component/MakrubCheck';
import Report from './component//ReportCheckName'
import ManageStudent from './component/ManageStudent';
import Profile from './component/Profile';
import RegisterRFID from './component/RegisterRFID';
import ManageBeacon from './component/ManageBecon';
import Manual from './component/ManualCheck';
import ResultCheckName from './component/ResultCheckName';
import ReportIntime from './component/ReportIntime';
import ReportOuttime from './component/ReportOuttime';
import ReportLate from './component/ReportLate';
import ReportAll from './component/ReportAll';
class RouterContent extends Component {
    render() {
        
        return (
            
                <Switch>
                        <Route exact path="/dashboard" component={Dashboard}/>
                        <Route path="/profile" component={Profile}/>
                        <Route path="/NameChecklist" component={NameChecklist} />
                        <Route path="/Makrubcheck" component={Makrubcheck} />
                        <Route path="/Reportcheck" component={Report} /> 
                        <Route path="/ReportIntime" component={ReportIntime} />
                        <Route path="/ReportOuttime" component={ReportOuttime} /> 
                        <Route path="/ReportLate" component={ReportLate} /> 
                        <Route path="/ReportAll" component={ReportAll} /> 
                        <Route path="/Resultcheck" component={ResultCheckName} /> 
                        <Route path="/Manage" component={ManageStudent} />
                        <Route path="/RegisterRFID" component={RegisterRFID}/>
                        <Route path="/ManageBeacon" component={ManageBeacon}/>
                        <Route path="/Manual" component={Manual} />
                        <Redirect to='/login' />
                </Switch>
        )
    }
}
export default RouterContent





