import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import DropdownTerm from './DropdownTerm';
import Grid from '@material-ui/core/Grid';
import {styles} from './styleSorting'

class HeaderSorting extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <AppBar position="static" color="default">
                    <div className={classes.headerpaper}>
                        <Grid item xs={12}>
                            <Grid container  >
                                <Grid item xs={6} container direction="column" justify="flex-end" alignItems="flex-start">
                                    <font className={classes.fontstyle}>ภาคเรียน</font>
                                    <DropdownTerm />
                                </Grid>
                                <Grid item xs={6} container direction="row" justify="flex-end" alignItems="flex-end">
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </AppBar>
            </div>
        );
    }
}
HeaderSorting.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HeaderSorting);