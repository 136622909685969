import {
    REPORTSTUDENT_GET_REQUEST,
    REPORTSTUDENT_GET_SUCCESS,
    REPORT_GET_REQUEST,
    REPORT_GET_SUCCESS,
    REPORTALL_GET_REQUEST,
    REPORTALL_GET_SUCCESS,
    REPORT_GROUP_GET_SUCCESS,
    SUBJECT_GROUP_SET
} from '../actions/Types';

const initialState = {
    loading: false,
    reportForListSubject: [],
    report: [],
    reportAll:[],
    groupStudnet:[],
    listStudent:[],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REPORT_GET_SUCCESS:
            return { ...state, report: action.payload, loading: false }
        case REPORT_GET_REQUEST:
            return { ...state, loading: true }
        case REPORTALL_GET_SUCCESS:
            return { ...state, reportAll: action.payload, loading: false }
        case REPORTALL_GET_REQUEST:
            return { ...state, loading: true }
        case REPORTSTUDENT_GET_SUCCESS:
            return { ...state, reportForListSubject: action.payload, loading: false }
        case REPORTSTUDENT_GET_REQUEST:
            return { ...state, loading: true }
        case REPORT_GROUP_GET_SUCCESS:
            return {
                    ...state,
                    groupStudnet:action.payload
            }
        case SUBJECT_GROUP_SET: 
            return {...state,listStudent:action.payload}
        default:
            return state;
    }
};