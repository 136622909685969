import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import QCIcon from '../../icon/Qc_icon.png';
import QC_Icon from '../../icon/Makub_1.png';
import QRCode from 'qrcode-react';
import PropTypes from 'prop-types';
const styles = theme => ({
  Contentstyle: {
    margin: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
    textAlign: 'center'
  },
  lightTooltip: {
    backgroundColor:' white',
    color: 'black',
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
});

const CustomButton = withStyles({
  root: {
    backgroundColor: '#249640',
    fontFamily: "'Prompt', sans-serif",
    color: "white",
    width: '100%',
    minWidth: '50%'
  }
})(Button);

const createicon = {
  marginRight: '5%'
}

class Qrc extends Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Tooltip title="สำหรับการเช็คชื่อผ่าน Makrub Application" placement="bottom-end" classes={{ tooltip: classes.lightTooltip }}>
          <CustomButton variant="contained" onClick={this.handleClickOpen} >
            <img src={QCIcon} height="30" width="30" style={createicon} alt="QRIcon" ></img> QRCode
        </CustomButton>
        </Tooltip>

        <Dialog
          open={this.state.open}
          keepMounted
          onClose={this.handleClose}
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogContent className={classes.Contentstyle}>
            <QRCode value={this.props.qr} size={500}  logo={QC_Icon} />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}


Qrc.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Qrc);