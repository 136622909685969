import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  CustomTableHead,
  CustomTableCell1,
  CustomTableCellbody,
  styles,
  PaperStyle,
  ButtonPass,
} from './style'
import {
  getStudentRegisterRFID,
  delteStudentCheck,
  editStatusCheckName,
  setDataStudnet,

} from '../../actions';
import DeleteDialog from '../Dialog/DeleteDialog';
import WarningRFID from '../Dialog/WarningRFID';
import imageIcon from '../../icon/handCard.png'


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}
function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}



class TableCheck extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      order: '-desc',
      orderBy: 'rank',
      rowsPerPage: 10,
      page: 0,

    };
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };
  render() {
    const { classes } = this.props;
    const { rowsPerPage, page } = this.state;

    return (

      <Paper style={PaperStyle}>
        <Table >
          <CustomTableHead style={{
            fontSize: '1.8vh',
            background: "#14a37f",
            color: "white",
            textAlign: 'center',
            fontFamily: "'Prompt', sans-serif",
          }} >
            <TableRow >
              <CustomTableCell1 align="center">ลำดับ</CustomTableCell1>
              <CustomTableCell1 align="center">รหัสนิสิต</CustomTableCell1>
              <CustomTableCell1 align="center">ชื่อ - นามสกุล</CustomTableCell1>
              <CustomTableCell1 align="center">กลุ่ม</CustomTableCell1>
              <CustomTableCell1 align="center">สถานะ</CustomTableCell1>
              <CustomTableCell1 align="center">เครื่องมือ</CustomTableCell1>
            </TableRow>
          </CustomTableHead>
          <TableBody >
            {stableSort(this.props.stduentRow,
              getSorting(this.state.order, this.state.orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                return (
                  <TableRow key={row.rank} className={classes.row}>
                    <CustomTableCellbody component="th" scope="row" align="center" style={{fontSize: '1.7vh'}}>{row.rank}</CustomTableCellbody>
                    <CustomTableCellbody align="center" style={{fontSize: '1.7vh'}}>{row.idstu}</CustomTableCellbody>
                    <CustomTableCellbody align="center" style={{fontSize: '1.7vh'}}>{row.name}</CustomTableCellbody>
                    <CustomTableCellbody align="center" style={{fontSize: '1.7vh'}}>{row.group}</CustomTableCellbody>
                    <CustomTableCellbody align="center" style={{fontSize: '1.7vh'}}>
                      {row.rfid === null ? 'ยังไม่ลงทะเบียน' : 'ลงทะเบียนแล้ว'}
                    </CustomTableCellbody>
                    {row.rfid ?
                      <CustomTableCellbody align="center">
                        <div style={{ display: 'flex', flexDirection: 'row',justifyContent:'center'}}>
                          <WarningRFID
                            onConfirmCallback={() => this.props.focus(row,true)}
                            textWarning="เมื่อผู้ใช้กดลงทะเบียนจะเป็นการลงทะเบียนใหม่ต้องการดำเนินการต่อหรือไม่?"
                            typeWarning="registerCheck"
                          />
                          <DeleteDialog
                            onConfirmCallback={(data) => this.props.confirmDelete(row.idstu)}
                            textDelete="รายชื่อและข้อมูลของผู้ใช้งานที่เลือกจะถูกลบออกจากคลาสเรียนต้องการดำเนินการต่อหรือไม่?" />
                        </div>
                      </CustomTableCellbody>
                      :
                      <CustomTableCellbody align="center">
                        <div style={{ display: 'flex', flexDirection: 'row',justifyContent:'center' }}>
                          <ButtonPass title="ลงทะเบียนบัตร" onClick={() => this.props.focus(row)}>
                            <img src={imageIcon} alt='icon-register' style={{ width: '2vh', height: '2vh' }} />
                          </ButtonPass>
                          <DeleteDialog
                            onConfirmCallback={(data) => this.props.confirmDelete(row.idstu)}
                            textDelete="รายชื่อและข้อมูลของผู้ใช้งานที่เลือกจะถูกลบออกจากคลาสเรียนต้องการดำเนินการต่อหรือไม่?"
                          />
                        </div>
                      </CustomTableCellbody>
                    }
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={this.props.stduentRow.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    )
  }
}
TableCheck.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

export default connect(mapStateToProps, {
  getStudentRegisterRFID,
  delteStudentCheck,
  editStatusCheckName,
  setDataStudnet,
})(withStyles(styles)(TableCheck))