import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import './style';

import editicon from '../../icon/icon-edit.png';


const status = [
    {
        value: 'ทันเวลา',
        label: 'ทันเวลา',
    },
    {
        value: 'สาย',
        label: 'สาย',
    },
];

class EditStatus extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            status: this.props.statusProps
        }
    }
    handleClickOpenOrClaose = (check) => {
        this.setState({ open: check });
    };
    handleSetState = (value) => event => {
        this.setState({
            [value]: event.target.value,
        })
    }

    // onConfirmEdit
    confirm = (staus) =>{
        this.props.onConfirmEdit(staus);
        this.handleClickOpenOrClaose(false);
      }


    render() {
        const headerstyle = {
            color: "white",
            backgroundColor: "#14a37f",
            fontFamily: "'Open Sans', sans-serif",
            fonSize: '100%'
        }
        const btncancel = {
            color: '#f1f8e9',
            backgroundColor: '#ef5350'
          }
        return (
            <div>
                <Button title="แก้ไข"  onClick={() => this.handleClickOpenOrClaose(true)} ><img src={editicon} alt={"editicon"} height='25' /></Button>
                <Dialog
                    open={this.state.open}
                    onClose={() => this.handleClickOpenOrClaose(false)}
                    aria-labelledby="form-dialog-CreateClass"
                    fullWidth
                    maxWidth="xs"
                >
                    <DialogTitle id="form-dialog-Class" style={headerstyle}><div className="TopColor">แก้ไขสถานะ</div></DialogTitle>
                    <br />
                    <DialogContent>
                        <font>สถานะ</font>
                    <TextField
                        id="status"
                        select
                        value={this.state.status}
                        onChange={this.handleSetState('status')}
                        variant="outlined"
                        fullWidth>
                         { status.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => this.handleClickOpenOrClaose(false)} style={btncancel} variant="contained">
                     <font> ยกเลิก</font>
                    </Button>
                    <Button onClick={() => this.confirm(this.state.status)} color="primary" autoFocus variant="contained">
                     <font> ยืนยัน</font>
                    </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }
}
export default EditStatus
