import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton } from '@material-ui/core';
import Home from '@material-ui/icons/Home';
import history from "../../history";
import AuthService from '../../service/AuthService';
import DrawerList from './DrawerList';
import RegisterRFID from '../Dialog/RegisterRFID';
import * as Services from '../../service/UserServices';
import ConfirmRegisterRFID from '../Dialog/confirmRegisRFID';
import { connect } from 'react-redux';
import {
    getSubjects,
    statusRegister
} from '../../actions';

const Auth = new AuthService()


const styles = theme => ({
    list: {
        width: 280,
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
});
const createicon = {
    marginRight: '5%',
    // width: '1.2vw'
    width: '8%'
}

class Tabdrawer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            left: false,
            rows: [],
            dialogStatus: false,
            dialogConfirm: false,
            status: false,
            edit: false,
            datas:{},

        };
        this.getDatas()
    };
    getDatas = async(status)=>{
        //off register student
        let dataSubject = this.props.subjects.subjects
        let check = dataSubject.filter(row => {
                return row.registerStatus === true
        })
        check.map(rows => this.props.statusRegister(rows._id,false))

        //set Check RFID
        const datas = await Services.RegisterRFID(true,false)
        await this.setState({datas})

    }
    handleCloseDialog = () => {
        this.setState({ dialogStatus: false });
        this.getDatas(false,false)
    };

    handleCloseDialogConfirm = ()=>{
        this.setState({ dialogConfirm: false });
    }

    handleOpenDialog = async(data) => {            
        await this.getDatas(true)
        if(data.rfid){
            this.setState({ dialogConfirm: true ,  left: false})

        }else{
            this.setState({ dialogStatus: true ,  left: false})
        }
    }


    toggleDrawer = (side, open) => {
        this.setState({
            [side]: open,
        });
    };

    handleLogout() {
        Auth.logout()
        this.props.history.replace('/login');
    };

    setLeft() {
        this.setState({ left: false });
    }

    //historystart
    linkdashboard = (side, open) => {
        this.setState({
            [side]: open,
        });
        history.push({
            pathname: '/dashboard',
        });
    };
    linkManageBeacon = () => {
        // ManageBeacon
        this.setState({
            left: false
        });
        history.push({
            pathname: '/ManageBeacon',
        });
    }


    componentDidMount() {
        this.props.getSubjects();
        let dataSubject = this.props.subjects.subjects
        let check = dataSubject.filter(row => {
                return row.registerStatus === true
        })
        window.onbeforeunload = async () => {  
          await  Services.RegisterRFID(false,true)
          await  this.props.statusRegister(check[0]._id,false)

        }
    }



    render() {
        const { classes, subjects } = this.props;
        const menuButton = {
            marginLeft: -12,
            marginRight: 20,
        }

        const sideList = (
            <div className={classes.list}>
                <List component="nav"
                    className={classes.root}
                >

                    <ListItem button divider onClick={() => this.linkdashboard('left', false)}>
                        <ListItemIcon><Home /></ListItemIcon>
                        <ListItemText primary={<font>หน้าหลัก</font>} />
                    </ListItem>

                    <ListItem divider >
                        <ListItemText primary={<font>รายวิชาของฉัน</font>} />
                    </ListItem>
                    <List>
                        {subjects.subjects.map((row, index) => {
                            return (
                                <DrawerList
                                    key={index}
                                    title={`${row.classCode} ${row.className}`}
                                    checklist={`${row.classCode} ${row.className} G ${row.section}`}
                                    classid={row._id}
                                    func={() => this.setLeft()}
                                />
                            );
                        })}</List>
                    <ListItem button divider onClick={this.linkManageBeacon}>
                        {/* <ion-icon name="bluetooth"  style={createicon}></ion-icon> */}
                        <font >ลงทะเบียน BEACONS</font>
                    </ListItem>

                    <ListItem button divider onClick={()=>this.handleOpenDialog(this.state.datas)}>
                        {/* <ion-icon name="bluetooth"  style={createicon}></ion-icon> */}
                        <font >ลงทะเบียนบัตรประจำตัวผู้สอน</font>
                    </ListItem>

                </List>
                <ListItem button divider onClick={() => this.handleLogout()}>
                    <ion-icon name="exit" style={createicon}></ion-icon> <font >ออกจากระบบ</font>
                </ListItem>

            </div>
        );

        return (
            <div>
                <IconButton color="inherit" aria-label="Menu" style={menuButton} onClick={() => this.toggleDrawer('left', true)}>
                    <MenuIcon />
                </IconButton>

                <Drawer open={this.state.left} onClose={() => this.toggleDrawer('left', false)}>
                    <div
                        tabIndex={0}
                        role="button"
                        onKeyDown={() => this.toggleDrawer('left', false)}
                    >
                        {sideList}
                    </div>
                </Drawer>
                <RegisterRFID dialogStatus={this.state.dialogStatus} handleCloseDialog={this.handleCloseDialog}/>
                <ConfirmRegisterRFID dialogStatus={this.state.dialogConfirm} handleCloseDialogConfirm={this.handleCloseDialogConfirm} />

            </div>
        );
    }
}

Tabdrawer.propTypes = {
    classes: PropTypes.object.isRequired,
};



const mapStateToProps = (state) => {
    return {
        ...state,
    }
}

export default connect(mapStateToProps, {
    getSubjects,
    statusRegister
})(withStyles(styles)(Tabdrawer))
