import { RSAA  } from 'redux-api-middleware';
import { 
    USER_GET_REQUEST,
    USER_GET_SUCCESS,
    USER_GET_FAILURE,
    UP_IMAGE_REQUEST,
    UP_IMAGE_SUCCESS,
    UP_IMAGE_FAILURE
} from './Types';
import {url} from '../url';

export const getProfile = () => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/user`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                types: [
                    USER_GET_REQUEST,
                    {
                        type: USER_GET_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            return response;
                        }
                    },
                    USER_GET_FAILURE
                ]
            },
        })
    }
   
}

export const UploadProfile =  (img) =>{  
    const formData = new FormData()
     formData.append('image', img);
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/user/upload`,
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                body: formData,
                types: [
                    UP_IMAGE_REQUEST,
                    {
                        type: UP_IMAGE_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            dispatch(getProfile())
                            return response;
                        }
                    },
                    UP_IMAGE_FAILURE
                ]
            },
        })
    }
}