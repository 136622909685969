import React from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';
import DropDown from '../../icon/arrow_drop_down.png';
import IconButton from '@material-ui/core/IconButton';
import history from '../../history';
import AuthService from '../../service/AuthService';
import ChangePasswordDialog from '../Dialog/ChangePasswordDialog';
const Auth = new AuthService()

const styles = theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing.unit * 2,
    },
    createicon: {
        marginRight: '10%'
    },
    dropDownBox: {
        marginRight: theme.spacing.unit * 15,
    },
    styleMenu: {
        display: 'flex',
        alignItems: 'center',
        height: '2.5vh'
    }
});

class EditProfile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            username: 'JohnWaso',
            email: 'jonh.was@buu.ac.th',
            dialogStatus: false
        };
    };
    handleLogout = (event) => {
        this.handleClose(event)
        Auth.logout()
        setTimeout(() => {
            history.push({
                pathname: '/login',
            });
            window.location.reload()
        }, 1000)

    };
    handleToggle = () => {
        this.setState(state => ({ open: !state.open }));
    };

    handleClose = event => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }
        this.setState({ open: false });
    };

    handleProfile = (event) => {
        this.handleClose(event)
        history.push({
            pathname: '/profile',
        });
    }
    handleChangePassword = (event) => {
        this.handleClose(event)
       this.setState({dialogStatus:true})
       
    }
    handleCloseDialog = () => {
        this.setState({ dialogStatus: false });
        
    };


    render() {
        const { classes } = this.props;
        const { open } = this.state;

        return (
            <div className={classes.root}>
                <div>
                    <IconButton
                        buttonRef={node => {
                            this.anchorEl = node;
                        }}
                        aria-owns={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleToggle}
                    >
                        <img src={DropDown} alt={"DropDown"} />
                    </IconButton>
                    <Popper open={open} anchorEl={this.anchorEl} transition disablePortal style={{ zIndex: 99999999 }}>
                        {({
                            TransitionProps, placement }) => (
                                < Grow
                                    {...TransitionProps}
                                    id="menu-list-grow"
                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                >
                                    <div className={classes.dropDownBox}>
                                        <Paper >
                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                <MenuList>
                                                    <MenuItem className={classes.styleMenu} onClick={this.handleProfile}>
                                                        <font style={{fontSize: '1.7vh' ,padding: '2%'}}>ข้อมูลส่วนตัว</font>
                                                    </MenuItem>
                                                    <MenuItem className={classes.styleMenu} onClick={this.handleChangePassword}>
                                                    <font style={{fontSize: '1.7vh' ,padding: '2%'}}>เปลี่ยนรหัสผ่าน</font>                                                        
                                                    </MenuItem>
                                                    <MenuItem className={classes.styleMenu} onClick={this.handleLogout}>                                                        
                                                    <font style={{fontSize: '1.7vh' ,padding: '2%'}}>ออกจากระบบ</font>
                                                        </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </div>
                                </Grow>
                            )}
                    </Popper>
                </div>
                <ChangePasswordDialog dialogStatus={this.state.dialogStatus} handleCloseDialog={this.handleCloseDialog}/>
            </div>
        );
    }
}

EditProfile.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default (withStyles(styles))(EditProfile)