import React from 'react'
import { connect } from 'react-redux';
import history from '../../history';
import Tab from './tab';
import * as Services from '../../service/ReportService';
import Loading from '../Loading';

import {
    setPath,
  } from '../../actions';
// import tab from './tab';

class ReportLate extends React.Component {
  constructor(props){
    super(props)
    this.state={
      datas:[],
      loading: false
    }
  }
  componentDidMount() {        
    const paths = [
      {
        name: 'รายวิชา ',
        func: () => history.push({
          pathname: '/dashboard',
        })
      },
      {
        name: ` /รายงานการเช็คชื่อวิชา ${this.props.location.state.routeName}`,
        func: () => history.push({
          pathname: '/Reportcheck',
          state:{
            idSubject: this.props.location.state.idSubject,
            routeName: this.props.location.state.routeName
            },
        })
      },
      {
        name: '/ผลการเช็คเข้าสาย ',
        func: () => history.push({
          pathname: '/ReportLate',
          state:{
            idSubject:this.props.location.state.idSubject,
            routeName:this.props.location.state.routeName
          },
        })
      },
    ]
    this.props.setPath(paths)
    this.getReportLateTime()
  }

  getReportLateTime = async () => {
    this.setState({
      loading: true
    })
    const datas = await Services.getReportLateTime(this.props.location.state.idlist)
    this.setState({
      datas,
      loading:false
    })
  }

    render(){
      if (this.state.loading) {
        return <Loading />
      }
        return(
            <div style={{marginTop:'10px'}}>
           <Tab reports={this.state.datas} dateList={this.props.location.state.dateList}/>
            </div>
            
        )
    }
}

const mapStateToProps = (state) => {
    return {
      ...state,
    }
  }

export default connect(mapStateToProps, {
    setPath
  })(ReportLate)