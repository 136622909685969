import { RSAA  } from 'redux-api-middleware';
import { 
    REPORT_GET_FAILURE,
    REPORT_GET_REQUEST,
    REPORT_GET_SUCCESS,
    REPORTSTUDENT_GET_FAILURE,
    REPORTSTUDENT_GET_REQUEST,
    REPORTSTUDENT_GET_SUCCESS,
    REPORTALL_GET_FAILURE,
    REPORTALL_GET_REQUEST,
    REPORTALL_GET_SUCCESS,
    REPORT_GROUP_GET_REQUEST,
    REPORT_GROUP_GET_SUCCESS,
    REPORT_GROUP_GET_FAILURE
} from './Types';
import {url} from '../url';


export const getReportForSubjects= (idSubject) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/classCheckList/report?id=${idSubject}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                types: [
                    REPORT_GET_REQUEST,
                    {
                        type: REPORT_GET_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            
                            return response;
                        }
                    },
                    REPORT_GET_FAILURE
                ]
            },
        })
    }
   
}



export const getReportForListSubject = (idSubject) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/class/report?id=${idSubject}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                types: [
                    REPORTSTUDENT_GET_REQUEST,
                    {
                        type: REPORTSTUDENT_GET_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            return response;
                        }
                    },
                    REPORTSTUDENT_GET_FAILURE
                ]
            },
        })
    }
   
}

export const getReportForStatus = (idListSubject) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/classCheckList/reportSubjectlist?id=${idListSubject}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                types: [
                    REPORTALL_GET_REQUEST,
                    {
                        type: REPORTALL_GET_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            return response;
                        }
                    },
                    REPORTALL_GET_FAILURE
                ]
            },
        })
    }
   
}

export const getGroup = (idSubject) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/class/group?id=${idSubject}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                types: [
                    REPORT_GROUP_GET_REQUEST,
                    {
                        type: REPORT_GROUP_GET_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            return response;
                        }
                    },
                    REPORT_GROUP_GET_FAILURE
                ]
            },
        })
    }
   
}
