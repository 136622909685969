import React from 'react'
import { connect } from 'react-redux';
import history from '../../history';
import Tab from './tab';
import Loading from '../Loading';
import * as Services from '../../service/ReportService';
import {
  setPath,

} from '../../actions';

class ReportIntime extends React.Component {
  constructor(props){
    super(props)
    this.state={
      datas:[],
      loading: false
    }
  }
  componentDidMount() {
    const paths = [
      {
        name: 'รายวิชา ',
        func: () => history.push({
          pathname: '/dashboard',
        })
      },
      {
        name: ` /รายงานการเช็คชื่อวิชา ${this.props.location.state.routeName}`,
        func: () => history.push({
          pathname: '/Reportcheck',
          state: {
            idSubject: this.props.location.state.idSubject,
            routeName: this.props.location.state.routeName
          },
        })
      },
      {
        name: '/ผลการเช็คชื่อเข้าเรียน ',
        func: () => history.push({
          pathname: '/ReportIntime',
          state: {
            idSubject: this.props.location.state.idSubject,
            routeName: this.props.location.state.routeName
          },
        })
      },
    ]
    this.props.setPath(paths)
    this.getReportInTime()
  }
  getReportInTime = async () => {
    this.setState({
      loading: true
    })
    const datas = await Services.getReportInTime(this.props.location.state.idlist)
    this.setState({
      datas,
      loading:false
    })
  }
  render() {
    
    if (this.state.loading) {
      return <Loading />
    }

    return (
      <div style={{ marginTop: '10px' }}>
        <Tab reports={this.state.datas} dateList={this.props.location.state.dateList}/>
      </div>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

export default connect(mapStateToProps, {
  setPath
})(ReportIntime)