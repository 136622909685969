import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import '../../App.css';
import editicon from '../../icon/icon-edit.png';
import * as BeaconsServices from '../../service/BeaconsServices'

const ATitle = withStyles({
    root: {
        background: "#14a37f",
        height: '0.4cm'
    },
})(DialogTitle);


const DButton = withStyles({
    root: {
        width: '100%',
        fontFamily: "'Prompt', sans-serif",
        fontSize: "120%",
        background: "#14a37f",
        color: "white"
    },
})(Button);

const styles = theme => ({
    buttontop: {
        marginRight: '1vw',
        width: 'auto',
        fontSize: 'auto',
        color: "white",
    },
});

class EditBeacons extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            name: this.props.nameEdit,
            uuid: this.props.uuidEdit,
            major:this.props.majorEdit,
            minor: this.props.minorEdit,
            text:'',
            load: false
        };
    }
    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ 
            open: false,text:''
        });
    };

    handleSetState = (value) => event => {
        this.setState({
            [value]: event.target.value,
        })
    }

    editBeacons= async(nameBeacon,uuid,major,minor)=>{
        if((nameBeacon && uuid && major && minor ) ==='' ){
            this.setState({
                text:'*กรุณาใส่ข้อมูลให้ครบ'
            })
        }else{
            const beacons = {
                name: nameBeacon,
                uuid: uuid,
                major:major,
                minor:minor,
                _id : this.props.beaconID
               } 
               
            
            await BeaconsServices.editBeacons(beacons)
            this.props.renderBeacons()
            this.handleClose()
            this.setState({ text:''})
        }
        
    }

    render() {
        
        return (
            <div className="App">
                <Button title="แก้ไข" onClick={this.handleClickOpen} ><img src={editicon} alt={"editicon"} height='25vh' /></Button>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="sm"
                >
                    <ATitle id="form-dialog-title" ><font className="TopColor">แก้ไขบีคอน</font></ATitle>
                    <DialogContent >
                        <div style={{ marginTop: '1%' }}>
                            <font>ชื่อ</font>
                            <TextField
                             value={this.state.name}
                             type="text"
                             onChange={this.handleSetState('name')}
                             variant="outlined"
                             fullWidth                              
                            />
                        </div>
                        <div style={{ marginTop: '1%' }}>
                            <font>uuid</font>
                            <TextField
                                value={this.state.uuid}
                                type="text"
                                onChange={this.handleSetState('uuid')}
                                variant="outlined"
                                fullWidth
                            />
                        </div>
                        <div style={{ marginTop: '1%' }}>
                            <font>major</font>
                            <TextField
                                value={this.state.major}
                                type="text"
                                onChange={this.handleSetState('major')}
                                variant="outlined"
                                fullWidth
                            />
                        </div>
                        <div style={{ marginTop: '1%' }}>
                            <font>minor</font>
                            <TextField
                                value={this.state.minor}
                                type="text"
                                onChange={this.handleSetState('minor')}
                                variant="outlined"
                                fullWidth
                            />
                        </div>
                        <div style={{ color: "red" }}>{this.state.text}</div>
                    </DialogContent>
                    <DialogActions >
                        <Grid item xs container spacing={8} >
                            <Grid item xs={12}>
                                <DButton onClick={()=>this.editBeacons(this.state.name, this.state.uuid, this.state.major, this.state.minor)} variant="contained"  >
                                    ยืนยัน
                                 </DButton>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


export default withStyles(styles)(EditBeacons);