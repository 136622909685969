import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import BT from '../../icon/bluetooth.png';
import Cancle from '../../icon/NotBeacon.png';
import Check from '../../icon/USeBeacon.png';
import Warn from '../../icon/WarnBeacon.png';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import * as ListSubjectsServices from '../../service/ServiceListName';

const styles = theme => ({
    Contentstyle: {
        textAlign: 'center'
    },
    lightTooltip: {
        backgroundColor: ' white',
        color: 'black',
        boxShadow: theme.shadows[1],
        fontSize: 16,
    },
});

const CustomButton = withStyles({
    root: {
        fontFamily: "'Prompt', sans-serif",
        color: "white",
        width: '100%',
        minWidth: '50%'
    }
})(Button);

const createicon = {
    marginRight: '5%'
}

class BeaconState extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            statusButton: '#7a7e82',
            statusBeacon: false,
            datasList: [],
            dialogstate: 'OPEN'
        };
    };
    async componentDidMount() {
        this.getDataList()
    }

    getDataList = async () => {
        const datasList = await ListSubjectsServices.getSubjectsList(this.props.idList)
        this.setState({
            datasList: datasList[0],
            statusBeacon: datasList[0].statusBeacon
        })
        this.onchangButton(this.state.statusBeacon)
    }

    handleClickOpen = async () => {
        this.onCheckData()
        this.setState({
            open: true,
        });
        setTimeout(() => {
            this.setState({
                open: false
            })
        }, 3000)
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    //เปลี่ยนสีปุ่ม
    onchangButton = async (val) => {
        if (val) {
            this.setState({
                statusButton: '#3187c6',
            });
        }
        else if (!val) {
            this.setState({
                statusButton: '#7a7e82',
            });
        }
    }

    onCheckData = async () => {
        const {
            statusBeacon,
        } = this.state
        if (!statusBeacon) {
            const status = await ListSubjectsServices.updateStatusBeacons(this.props.idList, true, this.props.idSubjects)
            if (status === 'status beacon not success') {
                this.setState({
                    dialogstate: 'NOT'
                })
            } else {
                this.setState({
                    statusButton: '#3187c6',
                    dialogstate: 'OPEN',
                    statusBeacon: true
                });
            }
        }
        else {
            const status = await ListSubjectsServices.updateStatusBeacons(this.props.idList, false, this.props.idSubjects)
            if (status === 'status beacon not success') {
                this.setState({
                    dialogstate: 'NOT'
                })
            } else {
                this.setState({
                    statusButton: '#7a7e82',
                    dialogstate: 'CLOSE',
                    statusBeacon: false
                });
            }
        }
    }

    async componentWillUnmount() {
        await ListSubjectsServices.updateStatusBeacons(this.props.idList, false, this.props.idSubjects)
    }
    render() {
        const {
            classes,
        } = this.props;
        const {
            dialogstate
        } = this.state

        return (
            <div>
                <Tooltip title="เปิดใช้งาน Beacons เพื่อพิสูจน์ตัวตนผู้เรียน" placement="bottom-end" classes={{ tooltip: classes.lightTooltip }}>
                    <CustomButton variant="contained" onClick={this.handleClickOpen} style={{ backgroundColor: this.state.statusButton }}>
                        <img src={BT} height="30" width="30" style={createicon} alt="bluetooth" ></img> Beacon
        </CustomButton>
                </Tooltip>
                <Dialog
                    open={this.state.open}
                    keepMounted
                    onClose={this.handleClose}
                    fullWidth={true}
                    maxWidth="sm"
                >
                    <DialogContent className={classes.Contentstyle}>
                        {dialogstate === 'OPEN' ?
                            <Grid
                                direction="column"
                                alignItems="center"
                                justify="center"
                                style={{ paddingTop: '8.5vh', paddingBottom: '8.5vh' }}
                            >
                                <Grid item xs={12}  >
                                    <img src={Check} height="20%" width="20%" alt="QR"></img>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: '5%' }}>
                                    <font style={{ fontSize: '30px' }}>บีคอนพร้อมใช้งาน</font>
                                </Grid>
                            </Grid>
                            : dialogstate === 'NOT' ?       
                                <Grid
                                    direction="column"
                                    alignItems="center"
                                    justify="center"
                                    style={{ paddingTop: '8.5vh', paddingBottom: '8.5vh' }}
                                >
                                    <Grid item xs={12}  >
                                    <img src={Warn} height="20%" width="20%" alt="Warn"></img>
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: '5%' }}>
                                    <font style={{ fontSize: '30px' }}>ไม่มีการลงทะเบียนบีคอน</font>
                                    </Grid>
                                </Grid>
                                :
                                <Grid
                                direction="column"
                                alignItems="center"
                                justify="center"
                                style={{ paddingTop: '8.5vh', paddingBottom: '8.5vh' }}
                            >
                                <Grid item xs={12}  >
                                <img src={Cancle} height="20%" width="20%" alt="Cancle"></img>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: '5%' }}>
                                <font style={{ fontSize: '30px' }}>ปิดการใช้งานบีคอน</font>                                
                                </Grid>
                            </Grid>                               
                        }
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}


BeaconState.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default (withStyles(styles)(BeaconState))

