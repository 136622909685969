import {
    CHECKNAME_GET_SUCCESS,
} from '../actions/Types';

const initialState = [];


const updatePermissionCheckNameList = (state, action) => {
    return [...action.payload]
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CHECKNAME_GET_SUCCESS:
            return updatePermissionCheckNameList(state, action);
        default:
            return state;
    }
};