import { RSAA  } from 'redux-api-middleware';
import { 
    CHECKNAME_GET_FAILURE,
    CHECKNAME_GET_REQUEST,
    CHECKNAME_GET_SUCCESS,
    CHECKNAME_DELETE_FAILURE,
    CHECKNAME_DELETE_REQUEST,
    CHECKNAME_DELETE_SUCCESS,
    CHCEKNAME_MANNUL_REQUEST,
    CHCEKNAME_MANNUL_SUCCESS,
    CHCEKNAME_MANNUL_FAILURE

} from './Types';
import {url} from '../url';

export const getListName = (idlist) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/checkName?id=${idlist}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                types: [
                    CHECKNAME_GET_REQUEST,
                    {
                        type: CHECKNAME_GET_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            return response;
                        }
                    },
                    CHECKNAME_GET_FAILURE
                ]
            },
        })
    }
   
}

export const delteStudentCheck = (idStudent,idlist) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/checkName/deleteCheck?id=${idStudent}`,
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                types: [
                    CHECKNAME_DELETE_REQUEST,
                    {
                        type: CHECKNAME_DELETE_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            dispatch(getListName(idlist))
                            return response;
                        }
                    },
                    CHECKNAME_DELETE_FAILURE
                ]
            },
        })
    }
   
}

export const editStatusCheckName = (idcheck,status,idlist) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/checkName/editCheck`,
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                body: JSON.stringify({
                    idcheck,
                    status
                }),
                types: [
                    CHECKNAME_DELETE_REQUEST,
                    {
                        type: CHECKNAME_DELETE_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            dispatch(getListName(idlist))
                            return response;
                        }
                    },
                    CHECKNAME_DELETE_FAILURE
                ]
            },
        })
    }
   
}

export const checkNameMannul = (subjectListId,userId,status, idlist) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/checkName/checkNameFromManul`,
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                body: JSON.stringify({
                    subjectListId,userId,status
                }),
                
                types: [
                    CHCEKNAME_MANNUL_REQUEST,
                    {
                        type: CHCEKNAME_MANNUL_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            dispatch(getListName(idlist))
                            return response;
                        }
                    },
                    CHCEKNAME_MANNUL_FAILURE
                ]
            },
        })
    }
}



