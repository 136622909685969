import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import imageIcon from '../../icon/handCard.png'
import RegisterRFID from './RegisterRFID';

class WarningRFID extends React.Component {
    state = {
        open: false,
        dialog:false,
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false});
    };
    confirm = () => {
        this.setState({dialog:true})
        this.props.onConfirmCallback();
        this.handleClose();
    }

    handleCloseRFID = () => {
        this.setState({ dialog:false });
    }

    render() {
        const btncancel = {
            color: '#f1f8e9',
            backgroundColor: '#ef5350'
        }
        return (
            <div>
                <Button variant="outlined" color="primary" onClick={this.handleClickOpen}
                    title="ลงทะเบียนบัตร"
                    style={{
                        fontSize: "100%",
                        fontFamily: "'Prompt', sans-serif",
                        color: "white",
                        backgroundColor: "#7a7e82",
                    }}>
                    <img src={imageIcon} alt='icon-register' style={{ width: '2vh', height: '2vh' }} />
                    {/* ลงทะเบียน */}
                </Button>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    // PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    maxWidth='xs'
                    fullWidth
                >
                    <DialogTitle id="draggable-dialog-title"><font>ยืนยันการลงทะเบียน</font></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <font>
                                {this.props.textWarning}
                            </font>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} style={btncancel} variant="contained">
                            <font> ยกเลิก </font>
                        </Button>
                        <Button onClick={this.confirm} color="primary" autoFocus variant="contained">
                            <font> ยืนยัน </font>
                        </Button>
                    </DialogActions>
                </Dialog>
                <RegisterRFID dialogStatus={this.state.dialog} handleCloseDialog={this.handleCloseRFID} />
            </div>
        );
    }
}

export default WarningRFID