// import data from './data.json';
import {
    SUBJECT_GET_SUCCESS,
    SUBJECT_GET_REQUEST,
    STUDENT_ADD_FAILURE,
    RFID_STUDENT_GET_SUCCESS,
    SUBJECT_GET_TERM_SUCCESS,
    LIST_SUBJECT_GET_SUCCESS,
    USER_GET_SUCCESS,
    REPORTSTUDENT_GET_SUCCESS
} from '../actions/Types';

const initialState = {
    loading: false,
    validate:'',
    status:false,
    defaultStudnet:[],
    defaultSubject:[],
    year:[],
    defaultListSubject:[],
    dataUser:'',
    defaultGroup :[]
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SUBJECT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                status:false,
                defaultSubject:action.payload
            };
        case SUBJECT_GET_REQUEST:
            return {
                ...state,
                loading: true
            };
        case STUDENT_ADD_FAILURE:
            return {
                ...state,
                validate: action.payload.response,
                status:true
            };
        case RFID_STUDENT_GET_SUCCESS:
            return {
                ...state,
                defaultStudnet:action.payload
            }
        case SUBJECT_GET_TERM_SUCCESS:
            return {
                ...state,
                year:action.payload
            }
        case LIST_SUBJECT_GET_SUCCESS:
            return {
                ...state,
                defaultListSubject:action.payload
            }
        case USER_GET_SUCCESS:
        return {
            ...state,
            dataUser:action.payload
        }
        case REPORTSTUDENT_GET_SUCCESS:
        return {
            ...state,
            defaultGroup:action.payload

        }
 
        default:
            return state;
    }
};