import { url } from '../url';

var dd = new Date().getDate()
var mm = (new Date().getMonth()) + 1
var yy = new Date().getFullYear()
if (mm < 10) { mm = '0' + mm }
if (dd < 10) { dd = '0' + dd }

export const creactList = (classId, title, type, start, end, lateTime, totalHour) => {
  var s1 = yy + '-' + mm + '-' + dd + 'T' + start + ':00.000Z'
  var s2 = yy + '-' + mm + '-' + dd + 'T' + end + ':00.000Z'
  var timeStartCheckName = new Date(s1).toISOString();
  var timeEndCheckName = new Date(s2).toISOString();

  return fetch(url + '/classCheckList/create', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem('id_token')
    },
    body: JSON.stringify({
      classId,
      title,
      type,
      timeStartCheckName,
      timeEndCheckName,
      lateTime,
      totalHour,
    })

  })
}


export const getClassList = (idlist) => {
  return fetch(`${url}/classCheckList?id=${idlist}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('id_token')
    }
  }).then(response => response.json())
    .then(responseJson => {
      return responseJson
    })
}

export const delteClassList = (_id) => {
  return fetch(url + '/classCheckList/delete', {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('id_token')
    },
    body: JSON.stringify({
      _id
    })
  })
}

export const editClassList = (_id, title, type, start, end, lateTime, totalHour) => {

  var s1 = yy + '-' + mm + '-' + dd + 'T' + start + ':00.000Z'
  var s2 = yy + '-' + mm + '-' + dd + 'T' + end + ':00.000Z'
  var timeStartCheckName = new Date(s1).toISOString();
  var timeEndCheckName = new Date(s2).toISOString();

  return fetch(url + '/classCheckList/edit', {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('id_token')
    },
    body: JSON.stringify({
      _id,
      title,
      type,
      timeStartCheckName,
      timeEndCheckName,
      lateTime,
      totalHour,
    })
  })
}

//get รายการเช็คชื่อ
export const getSubjectsList = (idlist) => {
  return fetch(url + '/classCheckList/getSubjectsList', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('id_token')
    },
    body: JSON.stringify({
      idlist
    })
  }).then(response => response.json())
    .then(responseJson => {
      return responseJson
    })
}


//update beacon status
export const updateStatusBeacons = (id, statusBeacon, classId) => {
  return fetch(url + '/classCheckList/beaconStatus', {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('id_token')
    },
    body: JSON.stringify({
      id,
      statusBeacon,
      classId
    })
  }).then(response => response.json())  
    .then(responseJson => {
      return responseJson
    })
}

