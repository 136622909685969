import { 
   ROUTING_GET_PATH,
   ROUTING_SET_PATH,
} from './Types';

export const getPath = () => {
    return {
        type: ROUTING_GET_PATH
    }
} 

export const setPath = (paths) => {
    return {
        type: ROUTING_SET_PATH,
        payload: paths
    }
} 