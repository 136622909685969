import React, { Component } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import RegisCard from '../../icon/checkCard.png';
import Correct from '../../icon/check.png';
import Cancle from '../../icon/cancle.png';
import io from 'socket.io-client';
import { urlIO } from '../../url'

const socket = io.connect(urlIO);

class RegisterRFID extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            dialogStatusResult: false,
            dialogStatusResultFail: false,
            teacherRegister:false

        }
        this.subscribeServer((err, data) => {
            if (data === 'success') {
                this.handleOpenResult()
            }
            else {
                this.handleOpenResultFail()
                console.log("No");

            }
            this.setState({teacherRegister:true})
        })

    }

    async  componentDidMount() {
        await this.setState({
            open: this.props.dialogStatus,
        })
    }
    async componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.dialogStatus !== this.props.dialogStatus) {
            console.log(nextProps.dialogStatus)
            await this.setState({
                open: nextProps.dialogStatus,
            })
            if(nextProps.dialogStatus){
                this.subscribeServerStu((err, data) => {
                    this.setState({teacherRegister:false})
                    if(data && this.state.teacherRegister === false){
                        this.handleOpenResult()
                    }
                })
            }

        }

    }

    subscribeServer(cb) {
        socket.on('teacher', data => {
            cb(null, data);
        })
    }

    subscribeServerStu(cb) {
        socket.on('register', data => {
            cb(null, data);
        })
    }



    handleClickOpen = () => {
        this.setState({ open: true });
    };


    handleClose = () => {        
        this.props.handleCloseDialog()
        
    }
    handleOpenResult =()=>{
        this.setState({ dialogStatusResult: true })
        this.props.handleCloseDialog()

        setTimeout(() => {
            this.setState({
                dialogStatusResult: false
            })
        }, 3000)
    }


    handleCloseResult = () => {
        this.setState({ dialogStatusResult: false })
    }

    handleOpenResultFail =()=>{
        this.setState({ dialogStatusResultFail: true })
        this.props.handleCloseDialog()

        setTimeout(() => {
            this.setState({
                dialogStatusResultFail: false
            })
        }, 3000)
    }


    handleCloseResultFail = () => {
        this.setState({ dialogStatusResult: false })
    }


    render() {
        return (
            <div className="App">
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    // fullWidth={true}
                >
                    <DialogContent>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '26vh',width: '30vw' }}>
                            <img src={RegisCard} alt="checkCard" style={{ width: '20vh', height: 'auto', marginBottom: '5%' }} />
                            <font style={{ fontSize: '1.9vh' }}>กรุณาแตะบัตรประจำตัวที่ Ma Krub Box</font>
                        </div>
                    </DialogContent>
                </Dialog>
                <ResultRegisSuscess dialogStatus={this.state.dialogStatusResult} handleCloseResult={this.handleCloseResult}/>
                <ResultRegisFail dialogStatus={this.state.dialogStatusResultFail} handleCloseResultFail={this.handleCloseResultFail}/>

            </div>
        )
    }
}

RegisterRFID.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default RegisterRFID


class ResultRegisSuscess extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
        }
    }

    async  componentDidMount() {
        await this.setState({
            open: this.props.dialogStatus,
        })

    }
    async componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.dialogStatus !== this.props.dialogStatus) {
            await this.setState({
                open: nextProps.dialogStatus,
            })
        }

    }


    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.props.handleCloseResult()
    }

    render() {
        return (
            <div className="App">
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                >
                    <DialogContent>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '26vh' }}>
                            <img src={Correct} alt="Correct" style={{ width: '15vh', height: 'auto', marginBottom: '5%' }} />
                            <font style={{ fontSize: '1.9vh' }}>ลงทะเบียนสำเร็จ</font>
                        </div>
                    </DialogContent>
                </Dialog>

            </div>
        )
    }
}


class ResultRegisFail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false, 
        }
    }

    async  componentDidMount() {
        await this.setState({
            open: this.props.dialogStatus,
        })

    }
    async componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.dialogStatus !== this.props.dialogStatus) {
            await this.setState({
                open: nextProps.dialogStatus,
            })
        }

    }


    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.props.handleCloseResultFail()
    }

    render() {
        return (
            <div className="App">
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                >
                    <DialogContent>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '26vh' }}>
                            <img src={Cancle} alt="Cancle" style={{ width: '15vh', height: 'auto', marginBottom: '5%' }} />
                            <font style={{ fontSize: '1.9vh' }}>ลงทะเบียนไม่สำเร็จ</font>
                        </div>
                    </DialogContent>
                </Dialog>

            </div>
        )
    }
}
