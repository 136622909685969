import React from 'react'
import { connect } from 'react-redux';

import TableManulCheck from './TableManulCheck'
// import HeadPaper from './HeadPaper';
import history from '../../history';
import {
    setPath,
  } from '../../actions';

class NameChecklist extends React.Component {
  componentDidMount() {        
    const paths = [
      {
        name: 'รายวิชา ',
        func: () => history.push({
          pathname: '/dashboard',
        })
      },
      {
        name: ` /${this.props.location.state.routeName}`,
        func: () => history.push({
          pathname: '/NameChecklist',
          state:{
            idSubject:this.props.location.state.idSubject,
            routeName:this.props.location.state.routeName
          },
        })
      },
      {
        name: '/เช็คชื่อ ',
        func: () => history.push({
          pathname: '/Manual',
          state:{
            idSubject:this.props.location.state.idSubject,
            routeName:this.props.location.state.routeName
          },
        })
      },
    ]
    this.props.setPath(paths)
  }

    render(){
        return(
            <div style={{marginTop:'10px'}}>
                <TableManulCheck idSubject={this.props.location.state.idSubject} idList={this.props.location.state.idList} routeName={this.props.location.state.routeName} />
            </div>
            
        )
    }
}

const mapStateToProps = (state) => {
    return {
      ...state,
    }
  }

export default connect(mapStateToProps, {
    setPath
  })(NameChecklist)