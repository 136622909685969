import {
    UP_IMAGE_SUCCESS,
    USER_GET_SUCCESS
} from '../actions/Types';

const initialState = {
    urlImage: [],
    dataUser:''
};


export default (state = initialState, action) => {
    switch (action.type) {
        case UP_IMAGE_SUCCESS:
            return { ...state, urlImage: action.payload }
        case USER_GET_SUCCESS:
            return {...state, dataUser:action.payload }
        default:
            return state;
    }
};