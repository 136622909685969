import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import PicProfile from '../../background/Profile.jpg';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Edit from '../../icon/edit.png';
import * as ChangePasswordServices from '../../service/UserServices';
import ShowPicture from './ShowPicture';
import history from '../../history';
import { url } from '../../url';
import {
    setPath,
    getProfile,
    UploadProfile,
} from '../../actions';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        // padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginTop: '2%',
        marginBottom: '2%',
        marginLeft: '2%',
        marginRight: '2%',
    },
    bigAvatar: {
        width: '20vh',
        height: '20vh',

    },
    paperProfile: {
        padding: '0.5vw',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '4%',
        marginBottom: '4%',
        marginLeft: '2%',
        marginRight: '2%',
    },
    paperBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        textAlign: 'center',
    },
    textField: {
        width: '45vw',
        marginBottom: '1vw',
    },
    barStyle: {
        backgroundColor: "#14a37f",
    },
    img: {
        width: '1.5vh',
        height: '1.5vh',

    }

});
const CustomButton = withStyles({
    root: {
        background: "#00a152",
        color: "white",
        width: "45vw",
        height: '5vh',
        marginTop: '1.5vh',
        fontSize: "0.4cm",
        marginBottom: '4vh',
    },
})(Button);


function buildFileSelector() {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', 'image/*');
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.setAttribute('id', 'img');
    // fileSelector.addEventListener('change','')
    return fileSelector;
}


class Profile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            user: null,
            img: '',
            imagUrl: PicProfile,
            validation:'',
        };
    };

    componentWillMount() {
        const paths = [
            {
                name: 'รายวิชา ',
                func: () => history.push({
                    pathname: '/dashboard',
                })
            },
            {
                name: '/ข้อมูลส่วนตัว',
                func: () => history.push({
                    pathname: '/profile',
                })
            },

        ]
        this.props.setPath(paths)
        this.props.getProfile()
        this.fileSelector = buildFileSelector();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user !== this.props.user) {
            if (nextProps.user.dataUser.imageUrl !== undefined || nextProps.user.dataUser.imageUrl) {
                this.setState({ imagUrl: url + '/' + nextProps.user.dataUser.imageUrl })
            }
            this.setState({
                user:nextProps.user.dataUser
            })
        }
    }

    handleSetState = (value) => event => {
        this.setState({
            user: { ...this.state.user, [value]: event.target.value }
        })
    }

    handleClose = async (event) => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }

        this.setState({ open: false });

    };
    handleCloseDialog = () => {
        this.setState({ dialogStatus: false });
    };

    handleFileSelect = (e) => {
        this.handleClose(e)
        e.preventDefault();
        this.fileSelector.click();
        this.fileSelector.onchange = () => {
            let img = this.fileSelector.files[0]
            this.setState({
                img: img,
                dialogStatus: true
            })
        }
    }

    onsubmit = async () => {
        let name= this.state.user.name.trim().length
        let lastName = this.state.user.lastName.trim().length
        let email = this.state.user.email.trim().length
        if(name !== 0 && lastName !== 0  && email !==0 ){
            const status = await ChangePasswordServices.EditProfile(this.state.user)
            if (status === 'edite Profile success') {
                history.push({
                    pathname: '/dashboard',
                });
            }else{
                this.setState({
                    validation: status
                })
            }
        }
        
    }

    render() {

        const { classes } = this.props;
        const {
            open,
            user

        } = this.state;
        if (!user) {
            return null
        }
        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item md={4} xs={12} >
                        <Paper className={classes.paperProfile}>
                            <div style={{
                                marginLeft: '90%',
                            }}>
                                < IconButton buttonRef={node => {
                                    this.anchorEl = node;
                                }}
                                    aria-owns={open ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleFileSelect}>
                                    <img src={Edit} alt={"Edit"} className={classes.img} />
                                </IconButton>
                            </div>
                            <div className={classes.paperBox}>
                                <Avatar alt="PicProfile" src={this.state.imagUrl} className={classes.bigAvatar} />

                                <div style={{ marginTop: '5%' , marginBottom: '7%'}}>
                                    <Typography style={{ fontSize: '1.8vh' }}>ID: {user.username}</Typography>
                                    <Typography style={{ fontSize: '1.6vh' }} color="textSecondary">{user.email}</Typography>
                                </div>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs  >
                        <Paper className={classes.paper}>
                            <AppBar position="static" color="default">
                                <Toolbar className={classes.barStyle}>
                                    <Typography variant="h6" color="inherit" style={{ fontSize: '2.2vh', color: 'white' }}>
                                        ข้อมูลส่วนตัว
                                </Typography>
                                </Toolbar>
                            </AppBar>
                            <div style={{ marginTop: '1.5vh', }}>
                                <Grid>
                                    <Grid style={{ textAlign: 'left', marginLeft: '15%' }}>
                                        <font style={{ fontSize: '1.8vh' }}>ชื่อผู้ใช้</font>
                                    </Grid>

                                    <TextField
                                        //   id="idclass"
                                        disabled
                                        value={user.username}
                                        onChange={this.handleSetState('username')}
                                        className={classes.textField}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid >
                                    <Grid style={{ textAlign: 'left', marginLeft: '15%' }}>
                                        <font style={{ fontSize: '1.8vh' }}>ชื่อ</font>
                                    </Grid>

                                    <TextField
                                        value={user.name}
                                        onChange={this.handleSetState('name')}
                                        className={classes.textField}
                                        variant="outlined"
                                    />
                                    <Grid style={{ textAlign: 'left', marginLeft: '15%' }}>
                                        <font style={{ fontSize: '1.8vh' }}>นามสกุล</font>
                                    </Grid>

                                    <TextField
                                        value={user.lastName}
                                        onChange={this.handleSetState('lastName')}
                                        className={classes.textField}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid>
                                    <Grid style={{ textAlign: 'left', marginLeft: '15%' }}>
                                        <font style={{ fontSize: '1.8vh' }}>โทรศัพท์</font>
                                    </Grid>

                                    <TextField
                                        value={user.phone}
                                        onChange={this.handleSetState('phone')}
                                        className={classes.textField}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid>
                                    <Grid style={{ textAlign: 'left', marginLeft: '15%' }}>
                                        <font style={{ fontSize: '1.8vh' }}>อีเมล</font>
                                    </Grid>

                                    <TextField
                                        value={user.email}
                                        onChange={this.handleSetState('email')}
                                        className={classes.textField}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                            </div>
                                {
                                    this.state.validation !== '' ? <div>
                                        {this.state.validation}
                                    </div>:null
                                }
                            <Grid container item xs={12} alignItems="center" justify="center">
                                <CustomButton onClick={this.onsubmit} variant="contained" color="primary" >
                                    <font style={{ fontSize: '1.7vh' }}>ยืนยัน</font>
                                </CustomButton>
                            </Grid>
                        </Paper>
                    </Grid>

                </Grid >
                <ShowPicture dialogStatus={this.state.dialogStatus} handleCloseDialog={this.handleCloseDialog} img={this.state.img} />
            </div >
        );
    }
}

Profile.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => {
    return {
        ...state,
    }
}

export default connect(mapStateToProps, {
    setPath,
    getProfile,
    UploadProfile,
})(withStyles(styles)(Profile))
