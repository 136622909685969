import { url } from '../url';

export const getBeacons = () => {
    return fetch(
        url + '/Beacons/getBeacons',
        {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('id_token')
            },
        }).then(response => response.json())
        .then(responseJson => {

            return responseJson
        })
}


export const createBeacons = async (name, type, uuid, major, minor, macAddress, statusBeacon) => {

    return fetch(
        url + '/Beacons/createBeacons',
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('id_token')
            },
            body: JSON.stringify({
                name, type, uuid, major, minor, macAddress, statusBeacon
            })
        }).then(response => response.json())
        .then(responseJson => {
            // return responseJson
        })
}


export const editBeacons = (beacons) => {

    return fetch(
        url + '/Beacons/editBeacons',
        {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem('id_token')
            },
            body: JSON.stringify(
                beacons
            )
        }).then(response => response.json())
        .then(responseJson => {
            return responseJson
        })
}



export const deleteBeacons = (id) => {
    return fetch(url + '/Beacons/deleteBeaacons', {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('id_token')
        },
        body: JSON.stringify({
            id
        })
    }).then(response => response.json())
        .then(responseJson => {
            // return responseJson
        })
}


// export const updateStatusBeacon = (statusBeacon,id) => {
//     return fetch(
//         url + '/Beacons/updateStatus',
//         {
//             method: 'PUT',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json',
//                 "Authorization": "Bearer " + localStorage.getItem('id_token')
//             },
//             body: JSON.stringify({
//                 id,
//                 statusBeacon
//             })
//         })
//         .then(response => response.json())
//         .then(responseJson => {
//             // return responseJson
//         })
// }

