import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import history from '../../history'
import QRdialog from '../Dialog/QRdialog';
import BeaconDialog from '../Dialog/BeaconDialog';
import ImageShow from './ImageShow';
import TableCheckName from './TableCheckName'
import { connect } from 'react-redux';
import {
    setPath,
    getListSubjects,
} from '../../actions';
import io from 'socket.io-client';
import { urlIO } from '../../url'
const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 1,
        overflowX: 'auto',
    },
    buttontop: {
        marginTop: 30,
        marginLeft: 25,
        fontSize: 18,
        backgroundColor: "#14a37f",
        color: "white",
    },
    gridlefttype: {
        marginLeft: '2%',
        marginTop: '2%'
    },
    gridrighttype: {
        marginTop: '1%',
        marginRight: '1%',
        margin: 'auto'
    },
    button: {
        // margin: theme.spacing.unit,
        margin: theme.spacing.unit * 2,
        padding: theme.spacing.unit * 1,
        // paddingLeft:  theme.spacing.unit * 4,
        // paddingRight:  theme.spacing.unit * 4,
        textAlign: 'center',
        backgroundColor: '#78B6E4',
        color: 'white'
    },
    input: {
        display: 'none',
    },

});
const socket = io.connect(urlIO);
class Makrubcheckcontent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            listStudent: [],
            statusBox:'กล่องไม่พร้อมใช้งาน',
            boxId:'กล่องไม่พร้อมใช้งาน'
        };
        this.props.getListSubjects(this.props.location.state.idlist)
        // this.subscribeServer((err, data) => {
        //     if(data){
        //         this.setState({
        //             statusBox:'พร้อมใช้งาน',
        //             boxId:data.BoxId
        //         }) 
        //     }else{
        //         this.setState({
        //             statusBox:'กล่องไม่พร้อมใช้งาน',
        //             boxId:'กล่องไม่พร้อมใช้งาน'
        //         }) 
        //     }
        //   })
    };
    componentDidMount() {
        const paths = [
            {
                name: 'รายวิชา ',
                func: () => history.push({
                    pathname: '/dashboard',
                })
            },
            {
                name: ` /${this.props.location.state.routeName}`,
                func: () => history.push({
                    pathname: '/NameChecklist',
                    state: {
                        idSubject: this.props.location.state.idSubject,
                        routeName: this.props.location.state.routeName
                    },
                })
            },
            {
                name: `/Ma-Krub-Check`,
                func: () => history.push({
                    pathname: '/Makrubcheck',
                    state: {
                        idSubject: this.props.location.state.idSubject,
                        routeName: this.props.location.state.routeName,
                        hour: this.props.location.state.hour,
                    }
                })
            }
        ]
        this.props.setPath(paths)
    }

    subscribeServer(cb) {
        socket.on('statusBox', data => {
          cb(null, data);
        })
      }

    render() {
        const { classes } = this.props;
        const { hour, idlist, idSubject } = this.props.location.state

        return (
            <Paper className={classes.root}>
                <Grid item xs={12} className={classes.gridtop}>
                    <Grid container >
                        <Grid className={classes.gridlefttype}>
                            {/* <div >
                                <font style={{fontSize: '2.2vh'}}>หมายเลขอุปกรณ์: {this.state.boxId}</font><br />
                                <font style={{fontSize: '2.2vh'}}>สถานะอุปกรณ์: {this.state.statusBox}</font>
                            </div> */}
                        </Grid>
                        <Grid className={classes.gridrighttype}>
                            <QRdialog qr={this.props.location.state.idlist} />
                            <div style={{ marginTop: '7%' }}>
                                <BeaconDialog idList={idlist} idSubjects={idSubject} />
                            </div>
                        </Grid>
                    </Grid>
                    <div>
                        <ImageShow idlist={idlist} />
                    </div>
                </Grid>
                <Grid >
                    <TableCheckName hour={hour} classes={classes} idlist={idlist} />
                </Grid>
            </Paper>

        )
    }
}

Makrubcheckcontent.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => {
    return {
        ...state,
    }
}
export default connect(mapStateToProps, {
    getListSubjects,
    setPath,
})(withStyles(styles)(Makrubcheckcontent))
