import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Loading from '../Loading';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
    CustomTableHead,
    CustomTableCell1,
    CustomTableCellbody,
    styles,
    PaperStyle,
} from './style'
import {
    setDataGroup,

} from '../../actions';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}
function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// const rows = [
//     { id: 'id', numeric: true, disablePadding: false, label: 'ลำดับ' },
//     { id: 'idStu', numeric: true, disablePadding: false, label: 'รหัสนิสิต' },
//     { id: 'name', numeric: false, disablePadding: false, label: 'ชื่อ - นามสกุล' },
//     { id: 'group', numeric: true, disablePadding: false, label: 'กลุ่ม' },
//     { id: 'list', numeric: false, disablePadding: false, label: '1' },
//     { id: 'totalIncome', numeric: false, disablePadding: false, label: 'ทันเวลา' },
//     { id: 'totalLate', numeric: false, disablePadding: false, label: 'สาย' },
//     { id: 'totalOutcome', numeric: false, disablePadding: false, label: 'ขาดเรียน' },
// ];


class TableResult extends Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    constructor(props) {
        super(props)
        this.state = {
            //   loading: false,
            order: '-desc',
            orderBy: 'id',
            rowsPerPage: 10,
            page: 0,
            selected: [],
            totalList: 0,

            datas: []

        };
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.report !== this.props.report) {
            if(nextProps.report.listStudent.length === 0){
                this.datacheck(nextProps.report.reportForListSubject)
            }else{
                this.datacheck(nextProps.report.listStudent)
            }
        }
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };
    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState(state => ({ selected: state.datas.map(n => n.id) }));
            return;
        }
        this.setState({ selected: [] });
    };
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    datacheck = (report) => {
        const datas = report.map((row, index) => {
            const stu = row[row.length - 1]
            let inTime = 0
            let late = 0
            let outTime = 0

            for (let i = 0; i < row.length - 1; i++) {
                if (row[i] === null) {
                    outTime++
                }
                else if (row[i].status === 'InTime') {
                    inTime++
                }
                else {
                    late++
                }
            }
            return {
                idStu: stu.studentId,
                name: stu.name + ' ' + stu.lastName,
                group: stu.group,
                totalIncome: inTime,
                totalLate: late,
                totalOutcome: outTime,
                list: row.slice(0, row.length - 1)
            }
        })


        this.setState({
            datas,
        })
    }

    getClassList = (list) => {
        if (list === null) {
            return <font style={{ color: "red" }}>ขาด</font>
        }
        else if (list.status === 'InTime') {
            return "ทันเวลา"
        }
        else {
            return "สาย"
        }

    }
    render() {
        const { classes } = this.props;
        const { rowsPerPage, page, order, orderBy, datas } = this.state;
        let cnt = 0;
        if (this.props.report.loading) {
            return <Loading />
        }
        return (
            <Paper style={PaperStyle}>
                <Table style={{ width: '100%', maxWidth: '200vw' }}>
                    <CustomTableHead style={{
                        background: "#14a37f",
                        color: "white",
                        textAlign: 'center',
                        fontFamily: "'Prompt', sans-serif",
                    }} >

                        {/* <tr style={{height: '4.5vh' , backgroundColor: '#14a37f'}}><th colSpan={7}/><th colSpan={this.state.totalList.length} >คาบเรียน</th></tr> */}
                        <TableRow >
                            <CustomTableCell1 rowSpan={2}  align="center" >ลำดับ</CustomTableCell1>
                            <CustomTableCell1 rowSpan={2}  align="center" >รหัสนิสิต</CustomTableCell1>
                            <CustomTableCell1 rowSpan={2}  align="center" >ชื่อ - นามสกุล</CustomTableCell1>
                            <CustomTableCell1 rowSpan={2} align="center" >กลุ่ม</CustomTableCell1>
                            <CustomTableCell1 rowSpan={2} align="center" >ทันเวลา</CustomTableCell1>
                            <CustomTableCell1 rowSpan={2} align="center" >สาย</CustomTableCell1>
                            <CustomTableCell1 rowSpan={2} align="center" >ขาดเรียน</CustomTableCell1>
                            <CustomTableCell1 colSpan={datas.length > 0 ? datas[0].list.length : 0} align="center" style={{ borderLeft: ' 1px solid rgba(224, 224, 224, 1)' }}>คาบเรียน</CustomTableCell1>
                        </TableRow>
                        <TableRow >
                            {datas.length > 0 ? datas[0].list.map(index => {
                                return <CustomTableCell1 align="center">{cnt += 1}</CustomTableCell1>

                            }) : <CustomTableCell1 align="center"></CustomTableCell1>
                            }

                        </TableRow>
                    </CustomTableHead>
                    <TableBody style={{
                        fontFamily: "'Prompt', sans-serif",
                    }}
                    >
                        {stableSort(datas,
                            getSorting(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <TableRow key={index} className={classes.row}>
                                        <CustomTableCellbody component="th" scope="row"  align="center">{index + 1}</CustomTableCellbody>
                                        <CustomTableCellbody  align="center">{row.idStu}</CustomTableCellbody>
                                        <CustomTableCellbody  align="center">{row.name}</CustomTableCellbody>
                                        <CustomTableCellbody align="center">{row.group}</CustomTableCellbody>

                                        <CustomTableCellbody align="center">{row.totalIncome}</CustomTableCellbody>
                                        <CustomTableCellbody align="center">{row.totalLate}</CustomTableCellbody>
                                        <CustomTableCellbody align="center">{row.totalOutcome}</CustomTableCellbody>
                                        {row.list.map(item => {
                                            return <CustomTableCellbody align="center"> {this.getClassList(item)}</CustomTableCellbody>
                                        })}

                                    </TableRow>
                                );
                            })}


                    </TableBody>

                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={datas.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />


            </Paper>
        )
    }
}
TableResult.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}


export default connect(mapStateToProps, {
    setDataGroup
})(withStyles(styles)(TableResult))
