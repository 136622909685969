import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';
// import history from "../../history";
import { withRouter } from 'react-router-dom'
import SubjectDialog from '../Dialog/SubjectDialog';
import DeleteDialog from '../Dialog/DeleteDialog';
import { connect } from 'react-redux';
import { CustomTypography, CustomCardHeader, CustomCard, styles } from './styleCard'
import {
    setPath,
} from '../../actions';

class CardSubject extends React.Component {
    state = {
        open: false,
        anchorEl: null,
        openDelete: false,
        openEdit: false
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null, open: false });
    }

    headercolor = function (options) {
        return {
            background: this.props.headercolor,
            textAlign: 'left',
            height: '4.5vh',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }

    linkListcheck = () => {
        this.props.history.push({
            pathname: '/NameChecklist',
            state: {
                idSubject: this.props.idSubject,
                routeName: this.props.routeName
            }
        });
    };
    linkReportcheck = () => {
        this.props.history.push({
            pathname: '/Reportcheck',
            state: {
                idSubject: this.props.idSubject,
                routeName: this.props.routeName
            }
        });
    }

    handleSetState = (value, bool) => {
        this.setState({
            [value]: bool,
        })
    }

    render() {
        const { classes } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        return (
            <div className={classes.root}>
                <CustomCard>
                    <CardContent style={this.headercolor()}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={11} container direction="row" justify="flex-start" alignItems="center" onClick={this.linkListcheck}>
                                        <Typography noWrap >
                                            <ButtonBase onClick={this.linkListcheck} >
                                                <CustomCardHeader>
                                                    {this.props.subjectsName}
                                                </CustomCardHeader>
                                            </ButtonBase>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} container direction="row" justify="flex-start" alignItems="center">
                                            <IconButton
                                                aria-owns={open ? 'fade-menu' : undefined}
                                                aria-haspopup="true"
                                                onClick={this.handleClick}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                        <Menu
                                            id="fade-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={this.handleClose}
                                            TransitionComponent={Fade}
                                        >
                                            <MenuItem onClick={this.linkListcheck} ><font style={{ fontSize: '1.8vh' }}>รายการเช็คชื่อ</font></MenuItem>
                                            <MenuItem onClick={this.linkReportcheck}><font style={{ fontSize: '1.8vh' }}>รายงานการเช็คชื่อ</font></MenuItem>
                                            <MenuItem>
                                                <SubjectDialog
                                                    statusCreate={true}
                                                    subjectCode={this.props.subjectCode}
                                                    subjectName={this.props.nameClass}
                                                    subjectGroup={this.props.subjectGroup}
                                                    subjectHeadcolor={this.props.headercolor}
                                                    subjectTerm={this.props.JusTerm}
                                                    idSubject={this.props.idSubject}
                                                    year={this.props.year}
                                                    closeDialog={this.handleClose}
                                                />
                                            </MenuItem>
                                            <MenuItem>
                                                <DeleteDialog statusDelete={true} onConfirmCallback={this.props.funcDelete}  handleClose={this.handleClose} textDelete="รายวิชานี้จะถูกลบออกจากระบบต้องการดำเนินการต่อหรือไม่?" />
                                            </MenuItem>
                                        </Menu>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardContent onClick={this.linkListcheck}>
                        <Grid item xs zeroMinWidth>
                            <CustomTypography noWrap>
                                กลุ่ม : {this.props.subjectGroup}
                            </CustomTypography></Grid>
                        <CustomTypography >
                            {this.props.term}
                        </CustomTypography>
                    </CardContent>
                </CustomCard>
            </div>
        )
    }
}

Card.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
    }
}

export default withRouter(connect(mapStateToProps, {
    setPath,
})(withStyles(styles)(CardSubject)))

