import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import EnhancedTableHead from '../EnhancedTableHead'
import { CustomTableCellbody, styles } from './style'
import { connect } from 'react-redux'
import {
  setPath,
  getStudent,
  getListName,
  checkNameMannul,
  delteStudentCheck
} from '../../actions';



function createData(orderStu, idStu, name, lastName, group, statusCheck,subjectId,userId) {
  name = name + ' ' + lastName
  return { id: orderStu, orderStu, idStu, name, group, statusCheck ,subjectId,userId};
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'orderStu', numeric: true, disablePadding: false, label: 'ลำดับ' },
  { id: 'idStu', numeric: true, disablePadding: false, label: 'รหัสนิสิต' },
  { id: 'name', numeric: false, disablePadding: false, label: 'ชื่อ - นามสกุล' },
  { id: 'group', numeric: true, disablePadding: false, label: 'กลุ่ม' },
  { id: 'inTime', numeric: false, disablePadding: false, label: 'ทันเวลา' },
  { id: 'outTime', numeric: false, disablePadding: false, label: 'สาย' },
];

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      order: 'asc',
      orderBy: 'orderStu',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 10,
    };
    this.props.getStudent(this.props.idSubject)
    this.props.getListName(this.props.idList)
  };


   componentWillReceiveProps(nextProps) {
    if (nextProps.student !== this.props.student || nextProps.checkname !== this.props.checkname) {
      let dataStudent = nextProps.student.map((row, index) => {
        let checkStatus = nextProps.checkname.filter((rows => {
          if (rows.userId.studentId === row.userId.studentId) {
            let dataCheck = {_id:rows._id,status:rows.status}
            return dataCheck
          } else {
            return null
          }
        }))
        return createData(index + 1, row.userId.studentId, row.userId.name, row.userId.lastName, row.group, checkStatus[0],this.props.idList,row.userId._id)
      })
      this.setState({
        data: dataStudent,
      })
      
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  checkNameStatus = (data) => {
    if (data) {
      return data.status
    }else{
      return false
    } 
  }

  ChangePass = async(subjectId,userId,status,data) => {
    if(data){
        if(data.status !== status){
          this.props.checkNameMannul(subjectId,userId,status, this.props.idList)
        }else{
          this.props.delteStudentCheck(data._id, this.props.idList)
        }
    }else{
      this.props.checkNameMannul(subjectId,userId,status, this.props.idList)
    }
  }

  render() {
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    return (
      <div>
        <Paper>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
                rows={rows}
              />
              <TableBody>
                {stableSort(data, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(n => {
                    const isSelected = this.isSelected(n.id);
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.id}
                        selected={isSelected}
                        className={classes.row}
                      >
                        <CustomTableCellbody align="center" ><font style={{fontSize: '1.7vh'}}>{n.orderStu}</font></CustomTableCellbody>
                        <CustomTableCellbody align="center"><font style={{fontSize: '1.7vh'}}>{n.idStu}</font></CustomTableCellbody >
                        <CustomTableCellbody align="center"><font style={{fontSize: '1.7vh'}}>{n.name}</font></CustomTableCellbody >
                        <CustomTableCellbody align="center"><font style={{fontSize: '1.7vh'}}>{n.group}</font></CustomTableCellbody>
                        <CustomTableCellbody align="center">
                            <Checkbox color="primary" onClick={()=>this.ChangePass(n.subjectId,n.userId,'InTime',n.statusCheck) } checked={this.checkNameStatus(n.statusCheck) === "InTime" ? true : false} />
                        </CustomTableCellbody>
                        <CustomTableCellbody  align="center">
                            <Checkbox color="primary" onClick={()=>this.ChangePass(n.subjectId,n.userId,'Late',n.statusCheck)} checked={this.checkNameStatus(n.statusCheck) === "Late" ? true : false} />
                        </CustomTableCellbody >

                      </TableRow>
                    );
                  })}

              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>

    );
  }
}
EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}
export default connect(mapStateToProps, {
  setPath,
  getStudent,
  getListName,
  checkNameMannul,
  delteStudentCheck
})(withStyles(styles)(EnhancedTable))
