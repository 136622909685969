import React, { Component } from 'react';
import RouterContent from './RouterContent'
import Headerbar from './component/Headerbar';
import HeaderbarRoute from './component/HeaderbarRoute';
import PicProfile from './background/Profile.jpg';
import { connect } from 'react-redux'
import {
  getProfile
} from './actions';
import {url}  from './url';

import './App.css';

class App extends Component {
  constructor(props){
    super(props)
    this.state = {
      imageUrl: PicProfile
    }
    this.props.getProfile()
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.user !== this.props.user){
      if(nextProps.user.dataUser.imageUrl){
        this.setState({ imageUrl: url+ '/' + nextProps.user.dataUser.imageUrl })
    }
    }
  }

  render() {
    
    return (
        <div>
          <Headerbar history = {this.props.history} imageUrl = {this.state.imageUrl}/>
          <HeaderbarRoute />
          <RouterContent/>
        </div>
    );
  }
}

// export default App;
const mapStateToProps = (state) => {
  return {
      ...state,
  }
}
export default connect(mapStateToProps, {
  getProfile
})(App)
