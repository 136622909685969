import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import DialogContentText from '@material-ui/core/DialogContentText';


const ATitle = withStyles({
    root: {
      background: "#14a37f",
      height: '0.4cm',
    },
  })(DialogTitle);
  
const AButton = withStyles({
    root: {
      background: "#007bb2",
      color: "white",
  
    },
  })(Button);

const DownLoadButton = withStyles({
    root: {
      width: '100%',
      backgroundColor: "#a359b0",
      fontFamily: "'Prompt', sans-serif",
      fontSize: "110%",
      color: "white",
  
    },
  })(Button);
  
const DButton = withStyles({
    root: {
      width: '100%',
      fontFamily: "'Prompt', sans-serif",
      fontSize: "120%",
      background: "#14a37f",
      color: "white"
    },
  })(Button);

const createicon = {
    marginRight: '3%'
  }

const styles = theme => ({
    buttontop: {
      marginRight: '1vw',
      width: 'auto',
      fontSize: 'auto',
      color: "white",
    },
    root: {
      margin: theme.spacing.unit * 2,
    },
    content:{
      marginTop: theme.spacing.unit
    },
    
  });
const UpButton = withStyles({
      root: {
        width: '100%',
        fontSize: '110%',
        background: "#72cff8",
        fontFamily: "'Prompt', sans-serif",
        color: "white",
      },
    })(Button);
const displaynone = {
      display: 'none'
  }

const ADialogContentText = withStyles({
  root: {
      width: 100
  },
})(DialogContentText);

const tooltipStyle = theme =>({
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'black',
    boxShadow: theme.shadows[1],
    fontSize: 18,
  },
})


export {ATitle,AButton,DownLoadButton,DButton,createicon,styles,UpButton,displaynone,ADialogContentText,tooltipStyle}