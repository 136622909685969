import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import indigo from '@material-ui/core/colors/indigo';
import Tabdrawer from '../TabDrawer';
import Avatar from '@material-ui/core/Avatar';
import DropDownProfile from '../DropDownManage/profile'

const styles = {
  root: {
    flexGrow: 1,
  },
  TitleFont: {
    flexGrow: 1,
    textAlign: 'left',
    fontSize: '2vh',
    fontFamily: "'Prompt', sans-serif",
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  yellowAvatar: {
    color: indigo[900],
  },
  bigAvatar: {
    margin: '0.5vh',
    width: '4.5vh',
    height: '4.5vh',
  },
  barStyle: {
    backgroundColor: "#80cbc4",
  },
  avatar: {
    margin: 10,
    color: 'green',
    backgroundColor: '#fff',
  },
};

function Headerbar(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <AppBar position="static" >
        <Toolbar className={classes.barStyle}>
          <Tabdrawer history={props.history} />
          <Typography color="inherit" className={classes.TitleFont}>
            MA-KRUB
          </Typography>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <Avatar className={classes.avatar} alt="PicProfile" src={props.imageUrl}></Avatar>
          <DropDownProfile/>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

Headerbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Headerbar);