import React from 'react'
import { connect } from 'react-redux';
import TableNameCheck from './TableNameCheck';
import HeadPaper from './HeadPaper';
import history from '../../history';
import {
    setPath,
  } from '../../actions';

class NameChecklist extends React.Component {
    componentDidMount() {
        const paths = [
          {
            name: 'รายวิชา ',
            func: () => history.push({
              pathname: '/dashboard',
            })
          },
          {
            name: ` /${this.props.location.state.routeName}`,
            func: () => history.push({
              pathname: '/NameChecklist',
              state:{
                idSubject:this.props.location.state.idSubject,
                routeName:this.props.location.state.routeName
              },
            })
          },
        ]
        this.props.setPath(paths)
      }

    render(){
        return(
            <div>
                <HeadPaper idSubject = {this.props.location.state.idSubject} routeName ={this.props.location.state.routeName}/>
                <TableNameCheck idSubject={this.props.location.state.idSubject}  routeName={this.props.location.state.routeName} />
            </div>
            
        )
    }
}

const mapStateToProps = (state) => {
    return {
      ...state,
    }
  }

export default connect(mapStateToProps, {
    setPath,
  })(NameChecklist)