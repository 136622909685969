import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Foldericon from '@material-ui/icons/Folder';
import '../../App.css';
import ButtonBase from '@material-ui/core/ButtonBase';
import history from "../../history";
import {
  setPath
} from '../../actions';
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from 'react-socks';
import Grid from '@material-ui/core/Grid';
setDefaultBreakpoints([
  { xsmall: 0 }, // all mobile devices
  { small: 576 }, // mobile devices (not sure which one's this big)
  { medium: 600 }, // ipad, ipad pro, ipad mini, etc
]);
const styles = {
  root: {
    flexGrow: 1,
  },
  fontstyle: {
    fontSize: '150%',
  },
  icon: {
    fontSize: '180%',
    marginRight: '1%'
  },
};

class NevManubar extends Component {
  //historystart
  linkdashboard = () => {
    history.push({
      pathname: '/dashboard',
    });
  };

  //historyend
  render() {
    const { classes, nameRout } = this.props;

    return (
      <div className={classes.root} >
        <BreakpointProvider>
          <Breakpoint small down>
            <Grid container item xs={12}>
              <AppBar position="static" color="default" >
                <Toolbar>
                  <Foldericon className={classes.icon} />
                  <Grid item xs={12} container justify="flex-start" alignItems="center">
                    {nameRout.map((row, index) => {
                      return <ButtonBase key={index} onClick={row.func} >
                        <Typography color="inherit" noWrap>
                        <font style={{ fontSize: '2.2vh',}}>{row.name}</font>                          
                        </Typography>
                      </ButtonBase>
                    })}
                  </Grid>

                </Toolbar>
              </AppBar>
            </Grid>
          </Breakpoint>

          <Breakpoint medium up>
            <Grid container item xs={12}>
              <AppBar position="static" color="default" >
                <Toolbar>
                  <Foldericon className={classes.icon} />
                  <Grid item xs={12} container justify="flex-start" alignItems="center">
                    {nameRout.map((row, index) => {
                      return <ButtonBase key={index} onClick={row.func} >
                        <Typography color="inherit" noWrap>
                          <font style={{ fontSize: '2.2vh',}}>{row.name}</font>
                        </Typography>
                      </ButtonBase>
                    })}
                  </Grid>

                </Toolbar>
              </AppBar>
            </Grid>
          </Breakpoint>

        </BreakpointProvider>
      </div>
    );
  }
}

NevManubar.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

export default connect(mapStateToProps, {
  setPath
})(withStyles(styles)(NevManubar))