import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import '../../App.css';
import editicon from '../../icon/icon-edit.png';

const ATitle = withStyles({
    root: {
        background: "#14a37f",
        height: '0.4cm'
    },
})(DialogTitle);

const ADialogContentText = withStyles({
    root: {
        width: 100
    },
})(DialogContentText);
const DButton = withStyles({
    root: {
        width: '100%',
        fontFamily: "'Prompt', sans-serif",
        fontSize: "120%",
        background: "#14a37f",
        color: "white"
    },
})(Button);

const styles = theme => ({
    buttontop: {
        marginRight: '1vw',
        width: 'auto',
        fontSize: 'auto',
        color: "white",
    },
});

class EditDialog extends React.Component {
    state = {
        open: false,
    };
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            idStu: this.props.idStuEdit,
            name: this.props.nameEdit,
            faculty: this.props.facEdit,
            major: this.props.majorEdit,
            group: this.props.groupEdit,
            text:''
        };
    }
    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false,text:'',group:this.props.groupEdit });
    };

    handleSetState = (value) => event => {
        this.setState({
            [value]: event.target.value,
        })
    }

    editGroup=(group)=>{
        if(group ==='' || group<=0){
            this.setState({
                text:'*กรุณาใส่ข้อมูลให้ถูกต้อง'
            })
        }else{
            this.props.editRow(this.props.id,group)
            this.handleClose()
        }
        
    }

    render() {
        return (
            <div className="App">
                <Button title="แก้ไข" onClick={this.handleClickOpen} ><img src={editicon} alt={"editicon"} height='25vh' /></Button>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="sm"
                >
                    <ATitle id="form-dialog-title" ><font className="TopColor">แก้ไขกลุ่ม</font></ATitle>
                    <DialogContent>
                        <ADialogContentText />
                        <font>กลุ่ม</font>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="group"
                            value={this.state.group}
                            type="number"
                            variant="outlined"
                            onChange={this.handleSetState('group')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                        <div style={{color:"red"}}>{this.state.text}</div>
                    </DialogContent>
                    <DialogActions >
                        <Grid item xs container spacing={8} >
                            <Grid item xs={12}>
                                <DButton onClick={()=>this.editGroup(this.state.group)} variant="contained"  >
                                    ยืนยัน
                                 </DButton>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


export default withStyles(styles)(EditDialog);