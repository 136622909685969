import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { stylesDrodown } from './styleSorting'
import { connect } from 'react-redux';
import {
    getYear,
    setDataSubject
} from '../../actions';

class DropdownTerm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            term: '',
            termYear: [],
        };
        this.props.getYear()
    };

    async componentWillReceiveProps(nextProps) {
        if (this.props.main.year !== nextProps.main.year ) {
           await this.renderTerm()
        }
        if(this.props.main.defaultSubject !== nextProps.main.defaultSubject){
            this.setState({
                term:''
            })
            this.props.getYear()
        }
    }

    handleChange = term => event => {
        this.setState({ [term]: event.target.value });
        let data = event.target.value.split('/')
        let dataTerm = data[0]
        let dataYear = data[1]
        let setData = this.props.main.defaultSubject.filter(row => {
            if (dataTerm === row.term && dataYear === row.year) {
                return row
            }
            return ''
        })
        if(event.target.value){
            this.props.setDataSubject(setData)
        }else{
            this.props.setDataSubject(this.props.main.defaultSubject)
        }   
    };

    renderTerm = async () => {
     await this.setState({
            termYear: this.props.main.year.map((row, index) => {
                return row._id.term + '/' + row._id.year
            }
            )
        })
    }


    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <FormControl className={classes.formControl}>
                    <Select
                        value={this.state.term}
                        onChange={this.handleChange('term')}
                        displayEmpty
                        name="term"
                        className={classes.selectEmpty}
                    >
                        <MenuItem value=""><font style={{ fontSize: '2vh' }}>ทั้งหมด</font></MenuItem>
                        {this.state.termYear.map((row, index) => {
                            return (
                                <MenuItem key={index} value={row} ><font style={{ fontSize: '2vh' }} >{row}</font></MenuItem>
                            )
                        })}
                    </Select>

                </FormControl>
            </div>
        );
    }
}

DropdownTerm.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
    }
}

export default connect(mapStateToProps, {
    getYear,
    setDataSubject
})(withStyles(stylesDrodown)(DropdownTerm))



