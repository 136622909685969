const styles = theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing.unit * 1,
      overflowX: 'auto',
    },
    table: {
      minWidth: '100%',
      maxWidth: '100%',
    },
    tableWrapper: {
      overflowX: 'auto',
      marginTop: theme.spacing.unit,
      margin: theme.spacing.unit * 2,
    },
    headerpaper: {
      overflowX: 'auto',
      marginTop: '0%',
      margin: theme.spacing.unit * 2,
    },
    dropdown: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    buttontop: {
      marginRight: '1vh',
      marginLeft: '1vh',
      // width: 'auto',
      fontSize: 'auto',
      backgroundColor: "#14a37f",
      color: "white",
      height:'35px',
      width: '145px',
    },
    buttontopMB: {
      width: '145px',
      height:'35px',
      marginTop: '1vh',
      marginBottom: '1vh',
      fontSize: 'auto',
      backgroundColor: "#14a37f",
      color: "white",
    },
    gridtop: {
      margin: theme.spacing.unit,
    },
    gridtop2: {
      marginTop: '0%',
    },
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
      gridbutton: {
        marginRight: '-100%'
      }
    },
    gridrighttype: {
      margin: 'auto'
    },
    gridrighttype2: {
      marginRight: '0%',
      margin: 'auto'
    },
    fontstyle: {
      fontSize: '1.8vh',
      marginTop: '1%',
    },
    progress: {
      margin: theme.spacing.unit * 20,
    },
    formControl: {
      marginTop: '-1%',
      minWidth: 100,
      alignItem:'left'
    },
    selectEmpty: {
      marginTop: theme.spacing.unit * 2,
    },
  });

  export {styles}