import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';



const CustomTableHead = withStyles({
    root: {
      backgroundColor: "#35baf6",
      fontFamily: "'Prompt', sans-serif",
    }
  })(TableHead);
  
  const CustomTableCell1 = withStyles({
    root: {
      fontSize: "100%",
      color: "white",
      fontFamily: "'Prompt', sans-serif",
    }
  })(TableCell);
  
  const CustomTableCellbody = withStyles({
    root: {
      fontSize: "90%",
      fontFamily: "'Prompt', sans-serif",
      color: "black",
    }
  })(TableCell);
  
  const ButtonPass = withStyles({
    root: {
      fontSize: "100%",
      fontFamily: "'Prompt', sans-serif",
      color: "white",
      backgroundColor: "#00a152",
      width: "2.5cm"
    }
  })(Button);
  
  const ButtonLate = withStyles({
    root: {
      fontSize: "100%",
      fontFamily: "'Prompt', sans-serif",
      color: "white",
      backgroundColor: "#FB9F31",
      width: "2.5cm"
    }
  })(Button);
  
  const PaperStyle = {
    overflowX: 'auto',
    marginLeft: '1%',
    marginRight: '1%',
    marginTop: '1%'
  }
  const styles = theme => ({
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  });

  const root = {
    flexGrow: 1,
    width: '100%'
  }
  const Gridstyle = {
    margin: '1.5%',
  }
  const paper = {
    margin: 'auto',
    minWidth: '50vw',
    maxWidth: '55vw',
    maxHeight: '100%',
    height: '100%',
  }
  const img = {
    display: 'block',
    minWidth: 'calc(2+(13vw)',
    maxWidth: '28vw' ,
    minHeight: 'calc(2+(15vh)',
    maxHeight: '33vh',
  }
  
  const past = {
    fontSize: "0.6cm",
    fontFamily: "'Prompt', sans-serif",
    color: "white",
    backgroundColor: "#00a152",
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: '70%',
    minHeight: '60%',
    marginTop: '2%'
  }
  
  const late = {
    fontSize: "0.6cm",
    fontFamily: "'Prompt', sans-serif",
    color: "white",
    backgroundColor: "#FB9F31",
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: '60%',
    minHeight: '60%',
    marginTop: '2%'
  }

  const defaultStatus ={
    fontSize: "0.6cm",
    fontFamily: "'Prompt', sans-serif",
    color: "white",
    backgroundColor: "white",
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: '60%',
    minHeight: '60%',
    marginTop: '2%'
  }
  
  export {root,Gridstyle,paper,img,past,late,CustomTableHead,CustomTableCell1,CustomTableCellbody,ButtonPass,styles,ButtonLate,PaperStyle,defaultStatus}