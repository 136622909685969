import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import {
  setDataListSubject
} from '../../actions';
const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    alignItem:'left',
    width: '15vh'
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  fontstyle: {

  }
});

class Typedropdown extends React.Component {
  state = {
    category: '',

  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
    let setData = this.props.main.defaultListSubject.filter(row => {
      if (event.target.value === row.type) {
        return row
      }
      return ''
    })
    if(event.target.value){
      this.props.setDataListSubject(setData)
    }else{
      this.props.setDataListSubject(this.props.main.defaultListSubject)
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <Select
            value={this.state.category}
            onChange={this.handleChange('category')}
            displayEmpty
            name="category"
            className={classes.selectEmpty}
          >
            <MenuItem value=""><font style={{fontSize: '1.7vh'}}>ทั้งหมด</font></MenuItem>
            <MenuItem value={"Lecture"}><font style={{fontSize: '1.7vh'}}>Lecture</font></MenuItem>
            <MenuItem value={"Lab"}><font style={{fontSize: '1.7vh'}}>Lab</font></MenuItem>
          </Select> 
        </FormControl>
      </div>
    );
  }
}

Typedropdown.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

export default connect(mapStateToProps, {
  setDataListSubject
})(withStyles(styles)(Typedropdown))

