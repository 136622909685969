export const SUBJECT_GET_SUCCESS = 'SUBJECT_GET_SUCCESS';
export const SUBJECT_GET_FAILURE = 'SUBJECT_GET_FAILURE';
export const SUBJECT_GET_REQUEST = 'SUBJECT_GET_REQUEST';

export const SUBJECT_UPLOAD_SUCCESS = 'SUBJECT_UPLOAD_SUCCESS'
export const SUBJECT_UPLOAD_FAILURE = 'SUBJECT_UPLOAD_FAILURE';
export const SUBJECT_UPLOAD_REQUEST = 'SUBJECT_UPLOAD_REQUEST';

export const SUBJECT_DELETE_FAILURE = 'SUBJECT_DELETE_FAILURE';
export const SUBJECT_DELETE_REQUEST = 'SUBJECT_DELETE_REQUEST';
export const SUBJECT_DELETE_SUCCESS = 'SUBJECT_DELETE_SUCCESS';

export const SUBJECT_EDIT_FAILURE = 'SUBJECT_EDIT_FAILURE';
export const SUBJECT_EDIT_REQUEST = 'SUBJECT_EDIT_REQUEST';
export const SUBJECT_EDIT_SUCCESS = 'SUBJECT_EDIT_SUCCESS';

export const SUBJECT_REGIS_RFID_SUCCESS = 'SUBJECT_REGIS_RFID_SUCCESS';
export const SUBJECT_REGIS_RFID_FAILURE = 'SUBJECT_REGIS_RFID_FAILURE';
export const SUBJECT_REGIS_RFID_REQUEST = 'SUBJECT_REGIS_RFID_REQUEST';


export const ROUTING_GET_PATH = 'ROUTING_GET_PATH';
export const ROUTING_SET_PATH = 'ROUTING_SET_PATH';

export const LIST_SUBJECT_GET_FAILURE = 'LIST_SUBJECT_GET_FAILURE';
export const LIST_SUBJECT_GET_REQUEST = 'LIST_SUBJECT_GET_REQUEST';
export const LIST_SUBJECT_GET_SUCCESS = 'LIST_SUBJECT_GET_SUCCESS';

export const LIST_SUBJECT_UPLOAD_SUCCESS = 'LIST_SUBJECT_UPLOAD_SUCCESS';
export const LIST_SUBJECT_UPLOAD_REQUEST = 'LIST_SUBJECT_UPLOAD_REQUEST';
export const LIST_SUBJECT_UPLOAD_FAILURE = 'LIST_SUBJECT_UPLOAD_FAILURE';

export const LIST_SUBJECT_DELETE_FAILURE = 'LIST_SUBJECT_DELETE_FAILURE';
export const LIST_SUBJECT_DELETE_REQUEST = 'LIST_SUBJECT_DELETE_REQUEST';
export const LIST_SUBJECT_DELETE_SUCCESS = 'LIST_SUBJECT_DELETE_SUCCESS';

export const LIST_SUBJECT_EDIT_FAILURE = 'LIST_SUBJECT_EDIT_FAILURE';
export const LIST_SUBJECT_EDIT_REQUEST = 'LIST_SUBJECT_EDIT_REQUEST';
export const LIST_SUBJECT_EDIT_SUCCESS = 'LIST_SUBJECT_EDIT_SUCCESS';

export const CHECKNAME_GET_FAILURE = 'CHECKNAME_GET_FAILURE';
export const CHECKNAME_GET_REQUEST = 'CHECKNAME_GET_REQUEST';
export const CHECKNAME_GET_SUCCESS = 'CHECKNAME_GET_SUCCESS';

export const CHECKNAME_DELETE_FAILURE = 'CHECKNAME_DELETE_FAILURE';
export const CHECKNAME_DELETE_REQUEST = 'CHECKNAME_DELETE_REQUEST';
export const CHECKNAME_DELETE_SUCCESS = 'CHECKNAME_DELETE_SUCCESS';

export const STUDENT_GET_FAILURE = 'STUDENT_GET_FAILURE';
export const STUDENT_GET_REQUEST = 'STUDENT_GET_REQUEST';
export const STUDENT_GET_SUCCESS = 'STUDENT_GET_SUCCESS';

export const STUDENT_DELETE_FAILURE = 'STUDENT_DELETE_FAILURE';
export const STUDENT_DELETE_REQUEST = 'STUDENT_DELETE_REQUEST';
export const STUDENT_DELETE_SUCCESS = 'STUDENT_DELETE_SUCCESS';

export const STUDENT_EDIT_FAILURE = 'STUDENT_EDIT_FAILURE';
export const STUDENT_EDIT_REQUEST = 'STUDENT_EDIT_REQUEST';
export const STUDENT_EDIT_SUCCESS = 'STUDENT_EDIT_SUCCESS';

export const STUDENT_ADD_REQUEST = 'STUDENT_ADD_REQUEST';
export const STUDENT_ADD_SUCCESS = 'STUDENT_ADD_SUCCESS';
export const STUDENT_ADD_FAILURE = 'STUDENT_ADD_FAILURE';

export const STUDNET_UPLOAD_SUCCESS = 'STUDENT_UPLOAD_SUCCESS'
export const STUDNET_UPLOAD_FAILURE = 'STUDENT_UPLOAD_FAILURE';
export const STUDNET_UPLOAD_REQUEST = 'STUDENT_UPLOAD_REQUEST';

export const STUDENT_FOCUS_SUCCESS = 'STUDENT_FOCUS_SUCCESS';
export const STUDENT_FOCUS_REQUEST = 'STUDENT_FOCUS_REQUEST';
export const STUDENT_FOCUS_FAILURE = 'STUDENT_FOCUS_FAILURE';

export const RFID_STUDENT_GET_REQUEST = 'RFID_STUDENT_ADD_REQUEST';
export const RFID_STUDENT_GET_SUCCESS = 'RFID_STUDENT_ADD_SUCCESS';
export const RFID_STUDENT_GET_FAILURE = 'RFID_STUDENT_ADD_FAILURE';

export const RFID_SET = 'RFID_SET';
export const SUBJECT_SET = 'SUBJECT_SET';
export const LIST_SUBJECT_SET = 'LIST_SUBJECT_SET'

export const SUBJECT_GET_TERM_REQUEST = 'SUBJECT_GET_TERM_REQUEST';
export const SUBJECT_GET_TERM_SUCCESS = 'SUBJECT_GET_TERM_SUCCESS';
export const SUBJECT_GET_TERM_FAILURE = 'SUBJECT_GET_TERM_FAILURE';

export const CHCEKNAME_MANNUL_REQUEST = 'CHCEKNAME_MANNUL_REQUEST';
export const CHCEKNAME_MANNUL_SUCCESS = 'CHCEKNAME_MANNUL_SUCCESS';
export const CHCEKNAME_MANNUL_FAILURE = 'CHCEKNAME_MANNUL_FAILURE';

export const USER_GET_REQUEST = 'USER_GET_REQUEST';
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS';
export const USER_GET_FAILURE = 'USER_GET_FAILURE';

export const REPORT_GET_FAILURE= 'REPORT_GET_FAILURE';
export const REPORT_GET_REQUEST= 'REPORT_GET_REQUEST';
export const REPORT_GET_SUCCESS= 'REPORT_GET_SUCCESS';

export const REPORTSTUDENT_GET_FAILURE= 'REPORTSTUDENT_GET_FAILURE';
export const REPORTSTUDENT_GET_REQUEST= 'REPORTSTUDENT_GET_REQUEST';
export const REPORTSTUDENT_GET_SUCCESS= 'REPORTSTUDENT_GET_SUCCESS';

export const REPORTALL_GET_FAILURE ='REPORTALL_GET_FAILURE';
export const REPORTALL_GET_REQUEST ='REPORTALL_GET_REQUEST';
export const REPORTALL_GET_SUCCESS ='REPORTALL_GET_SUCCESS';

export const REPORT_GROUP_GET_REQUEST = 'REPORT_GROUP_GET_REQUEST'; 
export const REPORT_GROUP_GET_SUCCESS = 'REPORT_GROUP_GET_SUCCESS';
export const REPORT_GROUP_GET_FAILURE = 'REPORT_GROUP_GET_FAILURE';

export const SUBJECT_GROUP_SET = 'SUBJECT_GROUP_SET';

export const UP_IMAGE_REQUEST = 'UP_IMAGE_REQUEST'; 
export const UP_IMAGE_SUCCESS = 'UP_IMAGE_SUCCESS';
export const UP_IMAGE_FAILURE = 'UP_IMAGE_FAILURE';