// import data from './data.json';
import {
    STUDENT_GET_SUCCESS,
    RFID_STUDENT_GET_SUCCESS,
    RFID_SET
} from '../actions/Types';

const initialState = [];


const updatePermissionStudent = (state, action) => {
    return [...action.payload]
}

const updatePermissionRegisterRFID = (state, action) => {
    return [...action.payload]
}

const updatePermissionSetRFID = (state, action) => {
    return [...action.payload]
}

export default (state = initialState, action) => {
    switch (action.type) {
        case STUDENT_GET_SUCCESS:
            return updatePermissionStudent(state, action);
        case RFID_STUDENT_GET_SUCCESS:
            return updatePermissionRegisterRFID(state, action);
        case RFID_SET:
            return updatePermissionSetRFID(state, action);
        default:
            return state;
    }
};