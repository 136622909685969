import {url} from '../url';

export const getUser = () =>{
    return fetch(
        url+'/user',
        {
            method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('id_token')
        },
    }).then(response => response.json())
        .then(responseJson => {
            
            return responseJson
    })
}





export const ChangePassword = (oldpass, newpass,confirmpass) =>{
        return fetch(
            url+'/user/resetpass',
            {
                method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer "+localStorage.getItem('id_token')
            },
            body: JSON.stringify({
                oldpass,
                newpass,
                confirmpass
            })
        }).then(response => response.json())
            .then(responseJson => {
                
                return responseJson
        })
}


export const EditProfile = (user) =>{
    return fetch(
        url+'/user/edit',
        {
            method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer "+localStorage.getItem('id_token')
        },
        body: JSON.stringify(
            user
        )
    }).then(response => response.json())
        .then(responseJson => {
            return responseJson
    })
}

export const UploadProfile = async (img) =>{    
    const formData = new FormData()
    await formData.append('image', img);
    return fetch(
        url+'/user/upload',
        {
            method: 'POST',
        headers: {
            "Authorization": "Bearer "+localStorage.getItem('id_token')
        },
        body: formData
    }).then(response =>{return response} )

}



export const RegisterUser = (username,password,conpassword,name,lastName,email,department,phone) => {
    return fetch(url + '/user/sendMail', {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer "+localStorage.getItem('id_token')

        },
        body: JSON.stringify({
            username,
            password,
            conpassword,
            name,
            lastName,
            email,
            department,
            phone
        })
    }).then(response => response.json())
        .then(responseJson => {
            return responseJson
        })
}

export const RegisterRFID = (status,edit) => {
    return fetch(url + '/user/RFIDregiser',{
        method: "PUT",
        headers: {
            "Content-Type" : "application/json",
            "Authorization": "Bearer "+localStorage.getItem('id_token')
        },
        body: JSON.stringify({
            status,
            edit
        })    
    }).then(response => response.json())
    .then(responseJson => {
        console.log(responseJson);
        
        return responseJson
    })
}
