import {url} from '../url';

export const getListName = (classCheckListId) => {
    return fetch(url + '/checkName?id=' + classCheckListId, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + localStorage.getItem('id_token')
        }
    }).then(response => response.json())

}

export const getStudent = (idclass) => {
    return fetch(url + '/student/getStudent?id=' + idclass, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + localStorage.getItem('id_token')
        }
    }).then(response => response.json())

}

export const searchStudent = (idStudent) => {
    return fetch(url + '/student/search?id=' + idStudent, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + localStorage.getItem('id_token')
        }
    }).then(response => response.json())

}

export const addStudent = (classId, studentId, group) => {
    return fetch(url + '/student/addStudent', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + localStorage.getItem('id_token')
        },
        body: JSON.stringify({
            classId,
            studentId,
            group
        })

    })
}
// delete

export const deleteStudent = (idStudent) => {
    return fetch(url+'/student/deleteStudent?id=' + idStudent, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('id_token')
        }
    })
}

export const editGroup = (_id,group) => {
    return fetch(url+'/student/editGroup', {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('id_token')
        },
        body: JSON.stringify({
            _id,
            group
        })
    })
}

export const deleteCheck= (idcheck) => {
    return fetch(url+'/checkName/deleteCheck?id=' + idcheck, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('id_token')
        }
    })
}

export const editCheck= (idcheck,status) => {
    return fetch(url+'/checkName/editCheck', {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('id_token')
        },
        body: JSON.stringify({
            idcheck,
            status
        })
    })
}
