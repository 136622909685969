import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from 'react-socks';
import {stylesHead} from './style'
import DropDownManage from '../DropDownManage'
setDefaultBreakpoints([
    { xsmall: 0 }, // all mobile devices
    { small: 576 }, // mobile devices (not sure which one's this big)
    { medium: 600 }, // ipad, ipad pro, ipad mini, etc
]);


class BreakpointUp extends Component {
    constructor(props) {
        super(props)
        this.state = {};
    };

    

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Paper className={classes.root}>
                    <BreakpointProvider>
                    <div>
                        <Breakpoint small down>
                            <div className={classes.headerpaper}>
                                <Grid container item xs={12}>
                                    <Grid item xs={12} container justify="flex-start" alignItems="center">
                                    </Grid>
                                    <Grid item xs={12} container direction="row" justify="flex-end" alignItems="center" >
                                        {/* <AddStudent idSubject={this.props.idSubject}/> */}
                                        <DropDownManage  idSubject={this.props.idSubject}  routeName ={this.props.routeName}/>
                                    </Grid>
                                </Grid>
                            </div>
                        </Breakpoint>
                        <Breakpoint medium up>
                            <div className={classes.headerpaper}>
                                <Grid container item xs={12}>
                                    <Grid item xs={6} container justify="flex-start" alignItems="center">

                                    </Grid>
                                    <Grid item xs={6} container direction="row" justify="flex-end" alignItems="center" >
                                        {/* <AddStudent idSubject={this.props.idSubject} /> */}
                                        <DropDownManage  idSubject={this.props.idSubject}  routeName ={this.props.routeName}/>
                                    </Grid>
                                </Grid>
                            </div>
                        </Breakpoint>
                    </div>
                    </BreakpointProvider>
                </Paper>
            </div>
        )
    }
}
BreakpointUp.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
};

export default withStyles(stylesHead)(BreakpointUp)