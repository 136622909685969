import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import LineIcon from '../../icon/lineicon.png';
import TooltipLine from '../../icon/ToolTipLine.png';
import *as Service from '../../service/ClassService'
import '../../App.css'

const styles = theme => ({
  grid: {
    width: '60%',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  buttontop: {
    display: 'flex',
    flexDirection: 'row',
    width: '150px',
    height: '35px',
    backgroundColor: "#14a37f",
    color: "white",
  },
  lightTooltip: {
    backgroundColor: ' white',
    color: 'black',
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
});
const blackground = {
  backgroundColor: '#14a37f',
  color: 'white'
}
const createicon = {
  marginRight: 4
}
const fontStyle = {
  fontSize: '0.95vh  '
}

class CodeLine extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      openTip: false,
      content: ''
    };
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = (value) => event => {
    this.setState({
      [value]: event.target.value,
    })
  }

  handleTooltipClose = () => {
    this.setState({ openTip: false });
  };

  handleTooltipOpen = () => {
    this.setState({ openTip: true });
  };
  
  addLine = () => {
    if (this.state.content !== '' || undefined) {
      Service.addLine(this.props.idSubject, this.state.content).then(res => {
      })
      this.handleClose();
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Tooltip 
         open={this.state.openTip}
         onClose={this.handleTooltipClose}
        title={ <img src={TooltipLine} alt="TooltipLine" height="100%" width="100%" />} 
        placement="bottom-end" 
        classes={{ tooltip: classes.lightTooltip }}>
          <Button variant="contained" className={classes.buttontop} onClick={this.handleClickOpen} onTouchEnd={this.handleTooltipOpen} onMouseOver={this.handleTooltipOpen}>
            <img src={LineIcon} alt={"LineIcon"} height='24vh' style={createicon} />
            <font style={fontStyle} > Group Line ID </font>
          </Button>
        </Tooltip>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle style={blackground}>  <font className="TopColor">กรุณากรอกรหัสกลุ่มไลน์</font> </DialogTitle>
          <br />
          <DialogContent>
            <TextField
              autoFocus
              variant="outlined"
              margin="dense"
              onChange={this.handleChange('content')}
              id="name"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.addLine} variant="contained" style={blackground}>
              <font> ยืนยัน </font>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
CodeLine.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CodeLine)