import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import {
  UploadProfile,
  getProfile
} from '../../actions';

class ShowPicture extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            dialogStatus: false,
            img: '',
            imageSrc: ''
        };
    };

    async  componentDidMount() {
        await this.setState({
            open: this.props.dialogStatus,
            img: this.props.img
        })
        this.readURL(this.state.img)

    }
    async componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.dialogStatus !== this.props.dialogStatus) {
            await this.setState({
                open: nextProps.dialogStatus,
                img: nextProps.img
            })
            this.readURL(this.state.img)
        }

    }
    readURL = (input) => {

        if (input) {
            var reader = new FileReader();

            reader.onload = (e) => {

                this.setState({
                    imageSrc: e.target.result,
                })
            };

            reader.readAsDataURL(input);
        }
    }
    handleClickOpen = () => {
        this.setState({ open: true });
    };
    handleClose = ()=>{
        this.props.handleCloseDialog()

    }

    onsubmit = async() => {
        this.props.handleCloseDialog()
        await this.props.UploadProfile(this.state.img)
        await this.props.getProfile()
        // this.setState({ open: false });
    };

    render() {
        return (
            <div>
                {/* <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
          Open alert dialog
        </Button> */}
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">โปรไฟล์</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <img style={{ width: '30vw', height: 'auto' }} src={this.state.imageSrc} alt="imageSrc"/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Disagree
            </Button>
                        <Button onClick={this.onsubmit} color="primary" autoFocus>
                            Agree
            </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
      ...state,
    }
  }
  
export default connect(mapStateToProps, {
    UploadProfile,
    getProfile
  })(ShowPicture)