import React, { Component } from 'react';
import { connect } from 'react-redux';
import withAuth from './WithAuth';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import HeaderSorting from '../HeaderSorting';
import Card from '../Card'
import CreateSubjectDialog from '../Dialog/SubjectDialog'
import history from "../../history";
import Loading from '../Loading';
import {
  getSubjects,
  deleteSubject,
  setPath,
  getYear
} from '../../actions';

class DashBoard extends Component {
  componentDidMount() {
    this.props.getSubjects();
    this.props.getYear()
    const paths = [
      {
        name: 'รายวิชา ',
        func: () => history.push({
          pathname: '/dashboard',
        })
      },
    ]
    this.props.setPath(paths)
  }

  handleDelete = (id) => {
    this.props.deleteSubject(id);
  }

  render() {
    const { classes, subjects, } = this.props;
    if (subjects.loading) {
      return (
        <Loading/>
      )
    }

    return (
      <div>
        <HeaderSorting />
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <Grid container>
              {subjects.subjects.map((row, index) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index} >
                    <Card
                      term={`${row.term}/${row.year}`}
                      routeName={`${row.classCode} ${row.className} G ${row.section}`}
                      subjectsName={`${row.classCode} ${row.className}`}
                      subjectGroup={row.section}
                      idSubject={row._id}
                      headercolor={row.colorCode}
                      nameClass={row.className}
                      subjectCode={row.classCode}
                      JusTerm={row.term}
                      year={row.year}
                      funcDelete={() => this.handleDelete(row._id)}
                    />
                  </Grid>
                );
              })}
              <Grid item xs={12} sm={6} md={4} lg={3}
              >
                <div
                  style={{
                    maxWidth: '100%',
                    maxHeight: '20%',
                    marginLeft: '2%',
                    marginBottom: '3%',
                    marginRight: '2%',
                  }}>
                  <CreateSubjectDialog />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}



export default connect(mapStateToProps, {
  getSubjects,
  deleteSubject,
  setPath,
  getYear
})(withAuth(withStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing.unit * 2,
  },
  progress: {
    margin: theme.spacing.unit * 20,
  },
}))(DashBoard)));
