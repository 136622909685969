import { combineReducers } from 'redux';
import subject from './Subject';
import main from './Main';
import nameRout from './NameRouting';
import list_subject from './ListSubject';
import checkname from './CheckNameList';
import student from './Student';
import report from './Report';
import user from './User';

    const reducers = combineReducers({
    subjects: subject,
    nameRout: nameRout,
    main: main,
    list_subject: list_subject,
    checkname: checkname,
    student: student,
    report:report,
    user:user
});

export default reducers;
