import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import purple from '@material-ui/core/colors/purple';



const CustomTableHead = withStyles({
    root: {
      fontSize: '2.0vh',
      backgroundColor: "#35baf6",
      fontFamily: "'Prompt', sans-serif",
    }
  })(TableHead);
  
  const CustomTableCell1 = withStyles({
    root: {
      fontSize: "100%",
      color: "white",
      fontFamily: "'Prompt', sans-serif",
    }
  })(TableCell);
  
  const CustomTableCellbody = withStyles({
    root: {
      fontSize: '2.0vh',
      fontFamily: "'Prompt', sans-serif",
      color: "black",
    }
  })(TableCell);
  
  const ButtonPass = withStyles({
    root: {
      fontSize: "100%",
      fontFamily: "'Prompt', sans-serif",
      color: "white",
      backgroundColor: "#00a152",
      // width: "2.5cm"
      // width: '120px', 
      // height: '40px'
    }
  })(Button);
  
  const ButtonLate = withStyles({
    root: {
      fontSize: "100%",
      fontFamily: "'Prompt', sans-serif",
      color: "white",
      backgroundColor: "#ff1744",
      width: "2.5cm"
    }
  })(Button);

  const ButtonNotPass = withStyles({
    root: {
      fontSize: "100%",
      fontFamily: "'Prompt', sans-serif",
      color: "white",
      backgroundColor: "#7a7e82",
      width: "2.5cm"
    }
  })(Button);
  
  const PaperStyle = {
    overflowX: 'auto',
    marginLeft: '1%',
    marginRight: '1%',
    marginTop: '1%'
  }
  const styles = theme => ({
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
      
    },
  });

  const root = {
    flexGrow: 1,
    width: '100%'
    
  }
  const Gridstyle = {
    margin: '1.5%',
    padding: '4%'
  }
  const paper = {
    margin: 'auto',
    minWidth: '50vw',
    maxWidth: '55vw',
    maxHeight: '100%',
    height: '100%',
  }
  const img = {
    display: 'block',
    minWidth: 'calc(2+(13vw)',
    maxWidth: '28vw' ,
    minHeight: 'calc(2+(15vh)',
    maxHeight: '33vh',
  }
  
  const past = {
    fontSize: "0.6cm",
    fontFamily: "'Prompt', sans-serif",
    color: "white",
    backgroundColor: "white",
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: '70%',
    minHeight: '60%',
    marginTop: '2%'
  }
  
  const late = {
    fontSize: "0.6cm",
    fontFamily: "'Prompt', sans-serif",
    color: "white",
    backgroundColor: "#ff1744",
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: '60%',
    minHeight: '60%',
    marginTop: '2%'
  }

  const styleIndex = theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing.unit * 1,
      overflowX: 'auto',
    },
    buttontop: {
      marginTop: 30,
      marginLeft: 25,
      fontSize: 18,
      backgroundColor: "#14a37f",
      color: "white",
    },
    gridlefttype: {
      marginLeft: '2%',
      marginTop: '2%'
    },
    gridrighttype: {
      marginTop: '1%',
      marginRight: '1%',
      margin: 'auto'
    },
    gridbottomRighttype: {
      marginRight: '1%',
      // marginBottom: '1%',
      margin: 'auto'
    },
    gridbottomLeftttype: {
      marginLeft: '1%',
      // marginBottom: '1%',
      margin: 'auto'
    },
    margin: {
      margin: theme.spacing.unit,
    },
    colorSwitchBase: {
      color: purple[300],
      '&$colorChecked': {
        color: purple[500],
        '& + $colorBar': {
          backgroundColor: purple[500],
        },
      },
    },
    colorBar: {},
    colorChecked: {},
    lightTooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      boxShadow: theme.shadows[1],
      fontSize: 18,
    },
    lightTooltipMobile: {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      boxShadow: theme.shadows[1],
      fontSize: 18,
      marginRight: '50vw',
      marginTop: '-0.5vw'
      
    },
  
  })
  
  const defaultStatus ={
    fontSize: "0.6cm",
    fontFamily: "'Prompt', sans-serif",
    color: "white",
    backgroundColor: "white",
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: '60%',
    minHeight: '60%',
    marginTop: '2%'
  }


  
  export {root,Gridstyle,paper,img,past,late,CustomTableHead,CustomTableCell1,CustomTableCellbody,ButtonPass,styles,ButtonLate,PaperStyle,styleIndex,defaultStatus,ButtonNotPass}