import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Pic from '../../icon/blank_profile.png'
import Button from '@material-ui/core/Button';
import {root,Gridstyle,paper,img,past,late,defaultStatus} from './style'
import '../../App.css'
import io from 'socket.io-client';
import {urlIO} from '../../url'
import {
  getListName,
} from '../../actions';

const socket = io.connect(urlIO);
class GridProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      idStudnet: '',
      name: '',
      lastname: '',
      image: Pic,
      status: '',
      time: ''
    }
    this.props.getListName(this.props.idlist)        
    this.subscribeServer((err,data)=>{
      this.dataSetState(data)
    })
  }

  subscribeServer(cb){
    socket.on('msg',data =>{
      cb(null,data); 
    })
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.checkname!==this.props.checkname){
      this.dataSetState(nextProps.checkname)
    }
  }

  dataSetState = (data) =>{
    if(data.length>0){
      let index = 0;
      let statusChange = data[index].status === "InTime" ? 'ทันเวลา' : 'สาย'
      let hour = new Date(data[index].createdAt).getHours()
      let min = new Date(data[index].createdAt).getMinutes()
          if (min < 10) { min = '0' + min }
          if (hour < 10) { hour = '0' + hour }
    this.setState({
      idStudnet: data[index].userId.studentId,
      name: data[index].userId.name,
      lastname: data[index].userId.lastName,
      image: 'https://reg.buu.ac.th/registrar/getstudentimage.asp?id=' + data[index].userId.studentId,
      status: statusChange,
      time: hour + ':' + min,
    })
    }else{
      this.setState({
        idStudnet: '',
        name: '',
        lastname: '',
        image: Pic,
        status: '',
        time:''
      })
    }
    
  }
  render() {
    return (
      <div style={root}>
        <Paper style={paper} elevation={5}>
          <Grid container direction="row" justify="space-evenly" alignItems="center" style={Gridstyle}>
            <Grid>
              <Button style={this.state.status === 'ทันเวลา' ? past : this.state.status === 'สาย' ? late: defaultStatus} variant="contained">
                <div >
                  <img style={img} alt="complex" src={this.state.image} />
                  <font>{this.state.status}</font>
                </div>
              </Button>
            </Grid>
            <Grid container item xs={6} direction="column" justify="space-evenly" alignItems="center">
              <font style={{fontSize: '2.5vh'}}>รหัสนิสิต : {this.state.idStudnet} </font>
              <font style={{fontSize: '2.5vh', marginTop:'2.5%' , marginBottom: '2.5%'}}>ชื่อ : {this.state.name} {this.state.lastname}</font>
              <font style={{fontSize: '2.5vh'}}>เวลาเช็คชื่อ : {this.state.time !== '' ? this.state.time + ' น.' : ''} </font>
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

export default connect(mapStateToProps,{
  getListName
})(GridProfile)