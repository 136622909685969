import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import EnhancedTableHead from '../EnhancedTableHead'
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import history from '../../history';
import Loading from '../Loading';
import {
    getReportForSubjects,
    setPath,
} from '../../actions';

function createData(id, list, type, hour, date, allpeople, intime, late, missclass, idList) {
    var dd = new Date(date).getDate()
    var mm = (new Date(date).getMonth()) + 1
    var yy = new Date(date).getFullYear() + 543
    if (mm < 10) { mm = '0' + mm }
    if (dd < 10) { dd = '0' + dd }
    date = dd + '/' + mm + '/' + yy
    if (missclass < 0) { missclass = 0 }
    return { id, list, type, hour, date, allpeople, intime, late, missclass, idList };
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 1,
        overflowX: 'auto',
    },
    table: {
        minWidth: '100%',
        maxWidth: '100%',
    },
    tableWrapper: {
        overflowX: 'auto',
        marginTop: theme.spacing.unit,
        margin: theme.spacing.unit * 2,
    },
    headerpaper: {
        overflowX: 'auto',
        marginTop: '0%',
        margin: theme.spacing.unit * 2,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        gridbutton: {
            marginRight: '-100%'
        }
    },
    gridlefttype: {
        marginLeft: '2%',
    },
    fontstyle: {
        fontSize: '150%',
        marginTop: '1%',
        textAlign: 'Left'
    },
    progress: {
        margin: theme.spacing.unit * 20,
    },
});
const ButtonDownLoad = withStyles({
    root: {
        marginTop: '2%',
        backgroundColor: "#15B4D4",
        fontFamily: "'Prompt', sans-serif",
        width: 'auto',
        fontSize: 'auto',
        color: "white",
    }
})(Button);

const rows = [
    { id: 'id', numeric: true, disablePadding: true, label: 'ลำดับ' },
    { id: 'list', disablePadding: true, label: 'รายการ' },
    { id: 'type', disablePadding: true, label: 'ประเภท' },
    { id: 'hour', disablePadding: true, label: 'ชั่วโมง' },
    { id: 'date', disablePadding: true, label: 'วันที่' },
    { id: 'allpeople', disablePadding: true, label: 'จำนวนนิสิต' },
    { id: 'intime', disablePadding: true, label: 'ทันเวลา' },
    { id: 'late', disablePadding: true, label: 'เข้าสาย' },
    { id: 'missclass', disablePadding: true, label: 'ขาดเรียน' },
];

class TableReportcheck extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            order: 'asc',
            orderBy: 'id',
            selected: [],
            data: [],
            page: 0,
            rowsPerPage: 10,
            loading: false,
            category: '',
            group: '',

        };
        this.props.getReportForSubjects(this.props.location.state.idSubject)
    };

    componentDidMount() {
        const paths = [
            {
                name: 'รายวิชา ',
                func: () => history.push({
                    pathname: '/dashboard',
                })
            },
            {
                name: ` /รายงานการเช็คชื่อวิชา ${this.props.location.state.routeName}`,
                func: () => history.push({
                    pathname: '/Reportcheck',
                    state: {
                        idSubject: this.props.location.state.idSubject,
                        routeName: this.props.location.state.routeName
                    },
                })
            }
        ]
        this.props.setPath(paths)
    }
    async componentWillReceiveProps(nextProps) {
        if (nextProps.report.report !== this.props.report.report) {
            await this.getReport(nextProps.report.report)
        }
    }
    
    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState(state => ({ selected: state.data.map(n => n.id) }));
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;





    getReport = async (report) => {
        const numStudents = report.allStudent
        this.setState({
            data: report.result.map((row, index) => {
                return createData(index + 1, row.title, row.type, row.totalHour, row.createdAt, numStudents, row.numberOfIntime, row.numberOfLateime, (numStudents - (row.numberOfIntime + row.numberOfLateime)), row._id)
            })
        })

    }

    resultCheck = () => {
        history.push({
            pathname: '/Resultcheck',
            state: {
                idSubject: this.props.location.state.idSubject,
                routeName: this.props.location.state.routeName,
            }
        });
    }

    ReportAll = (id,date) => {
        history.push({
            pathname: `/ReportAll`,
            state: {
                idlist: id,
                dateList:date,
                idSubject: this.props.location.state.idSubject,
                routeName: this.props.location.state.routeName,

            }
        });
    }
    ReportIntime = (id,date) => {
        history.push({
            pathname: '/ReportIntime',
            state: {
                idlist: id,
                dateList:date,
                idSubject: this.props.location.state.idSubject,
                routeName: this.props.location.state.routeName
            }
        });
    }
    ReportOuttime = (id,date) => {
        history.push({
            pathname: '/ReportOuttime',
            state: {
                idlist: id,
                dateList:date,
                idSubject: this.props.location.state.idSubject,
                routeName: this.props.location.state.routeName
            }
        });
    }
    ReportLate = (id,date) => {
        history.push({
            pathname: '/ReportLate',
            state: {
                idlist: id,
                dateList:date,
                idSubject: this.props.location.state.idSubject,
                routeName: this.props.location.state.routeName
            }
        });
    }
    render() {

        const { classes, report } = this.props;
        const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
        const CustomTableCell = withStyles(theme => ({
            head: {
                fontSize: '1.8',
                background: "#14a37f",
                color: "white",
                textAlign: 'center'
            },
            body: {
                fontSize: '1.7vh',
                textAlign: 'center',
            },

        }))(TableCell);
        if(report.loading){
            return <Loading/>
        }
        return (
            <Paper className={classes.root}>
                <div className={classes.headerpaper}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={6} container direction="row" justify="flex-start" alignItems="center" />
                            <Grid item xs={12} sm={6} md={6} alignItems="flex-end" justify="flex-end" container>
                                <ButtonDownLoad variant="contained" onClick={this.resultCheck}>
                                    <ion-icon name="stats" />  <font>ผลการเช็คชื่อ</font>
                                </ButtonDownLoad>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                            rowCount={data.length}
                            rows={rows}
                        />
                        <TableBody>
                            {stableSort(data, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(n => {
                                    const isSelected = this.isSelected(n.id);
                                    return (
                                        <TableRow
                                            hover
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n.id}
                                            selected={isSelected}
                                            className={classes.row}
                                        >
                                            <CustomTableCell component="th" scope="row" padding="none" >
                                                <font style={{ fontSize: '1.7vh', }}>{n.id}</font>
                                            </CustomTableCell>
                                            <CustomTableCell><font style={{ fontSize: '1.7vh', }}>{n.list}</font></CustomTableCell>
                                            <CustomTableCell><font style={{ fontSize: '1.7vh', }}>{n.type}</font></CustomTableCell>
                                            <CustomTableCell><font style={{ fontSize: '1.7vh', }}>{n.hour}</font></CustomTableCell>
                                            <CustomTableCell><font style={{ fontSize: '1.7vh', }}>{n.date}</font></CustomTableCell>
                                            <CustomTableCell >
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignSelf: 'center',
                                                }}>
                                                    <Button
                                                        style={{
                                                            backgroundColor: '#15B4D4',
                                                            color: 'white',
                                                            fontSize: '1.5vh',
                                                            // width:' 5vw'
                                                        }} onClick={() => this.ReportAll(n.idList,n.date)}>
                                                        {n.allpeople}
                                                    </Button>
                                                </div>
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignSelf: 'center',
                                                }}>
                                                    <Button
                                                        style={{
                                                            backgroundColor: '#00a152',
                                                            color: 'white',
                                                            fontSize: '1.5vh',
                                                        }} onClick={()=>this.ReportIntime(n.idList,n.date)}>
                                                        {n.intime}
                                                    </Button>
                                                </div>
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignSelf: 'center',
                                                }}>
                                                    <Button
                                                        style={{
                                                            backgroundColor: '#FB9F31',
                                                            color: 'white',
                                                            fontSize: '1.5vh',
                                                        }} onClick={()=>this.ReportLate(n.idList,n.date)}>
                                                        {n.late}
                                                    </Button>
                                                </div>
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignSelf: 'center',
                                                }}>
                                                    <Button
                                                        style={{
                                                            backgroundColor: 'red',
                                                            color: 'white',
                                                            fontSize: '1.5vh',
                                                        }} onClick={()=>this.ReportOuttime(n.idList,n.date)}>
                                                        {n.missclass}
                                                    </Button>
                                                </div>

                                            </CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </Paper>
        );
    }
}

TableReportcheck.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
    }
}
export default connect(mapStateToProps, {
    getReportForSubjects,
    setPath
})(withStyles(styles)(TableReportcheck))
