import {
    ROUTING_SET_PATH,
} from '../actions/Types';

const initialState = [];


const updatePermissionRout = (state, action) => {
    return [...action.payload]
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ROUTING_SET_PATH:
            return updatePermissionRout(state, action);
        default:
            return state;
    }
};