import React from 'react'
import { connect } from 'react-redux';
import history from '../../history';
import Tab from './tab';
import {
  setPath,
  getReportForListSubject
} from '../../actions';

class ResultCheckName extends React.Component {
  componentDidMount() {
    const paths = [
      {
        name: 'รายวิชา ',
        func: () => history.push({
          pathname: '/dashboard',
        })
      },
      {
        name: ` /รายงานการเช็คชื่อวิชา ${this.props.location.state.routeName}`,
        func: () => history.push({
          pathname: '/Reportcheck',
          state: {
            idSubject: this.props.location.state.idSubject,
            routeName: this.props.location.state.routeName
          },
        })
      },
      {
        name: '/ผลการเช็คชื่อ ',
        func: () => history.push({
          pathname: '/Resultcheck',
          state: {
            idSubject: this.props.location.state.idSubject,
            routeName: this.props.location.state.routeName
          },
        })
      },
    ]
    this.props.setPath(paths)
    this.props.getReportForListSubject(this.props.location.state.idSubject)
  }

  render() {
    
    return (
      <div style={{ marginTop: '10px' }}>
        <Tab report={this.props.reportForListSubject}  idSubject = {this.props.location.state.idSubject}/>
      </div>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

export default connect(mapStateToProps, {
  setPath,
  getReportForListSubject
})(ResultCheckName)