import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import history from "../../history";

const CustomTypography = withStyles({
    root: {
        fontSize: '100%',
    }
})(Typography);

class Drawerlist extends Component {
    state = {
        open: false,        
       
    };

    


    handleClick = () => {
        this.setState(state => ({ open: !state.open }));
    };
    //historystart
    linklistcheck = () => {
        this.props.func();
        history.push({
            pathname: '/NameChecklist',
            state: { routeName: this.props.checklist, idSubject: this.props.classid }
        });
    };
    linkReportcheck = () => {
        this.props.func();
        history.push({
            pathname: '/Reportcheck',
            state: { routeName: this.props.checklist, idSubject: this.props.classid }
        });
    };

    
    //historyend
    render() {
        return (
            <div>
                <ListItem button onClick={this.handleClick} divider>
                    <ListItemText primary={<CustomTypography noWrap><font>{this.props.title}</font></CustomTypography>} />
                    {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button divider onClick={this.linklistcheck}>
                            <ListItemText inset primary={<font>รายการเช็คชื่อ</font>} />
                        </ListItem>
                        <ListItem button divider onClick={this.linkReportcheck}>
                            <ListItemText inset primary={<font>รายงานการเช็คชื่อ</font>} />
                        </ListItem>
                    </List>
                </Collapse>
            </div>
        )
    }
}

export default Drawerlist