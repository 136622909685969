import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { ATitle,  DButton,  styles,} from './style'
import '../../App.css';
import * as BeaconsServices from '../../service/BeaconsServices'

class AddEddystones extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            name: '',
            type: 'eddystone',
            uuid: '-',
            major: '-',
            minor: '-',
            macAddress: '',
            statusBeacon: false,
            text: ''
        };
    }
    async  componentDidMount() {
        await this.setState({
            open: this.props.dialogEddystone,
        })

    }
    async componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.dialogStatus !== this.props.dialogEddystone) {
            await this.setState({
                open: nextProps.dialogEddystone,
            })
        }

    }
    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleSetState = (value) => event => {
        this.setState({
            [value]: event.target.value,
        })
    }

    handleClose = ()=>{
        this.props.handleCloseDialog()
        this.setState({
            macAddress: '',
            name: '',
            text: ''
        })
    }
    onsubmit = async () => {
        const {
            name, type, uuid, major, minor, macAddress,statusBeacon
        } = this.state
        if ((name && macAddress) === '') {
            this.setState({
                text: '*กรุณากรอกข้อมูลให้ครบถ้วน'
            })
        } else {
            // macAddress.toUpperCase()
            await BeaconsServices.createBeacons(name, type, uuid, major, minor, macAddress,statusBeacon)
           this.handleClose()
        window.location.reload()
        }
    };

    render() {
        // const { classes } = this.props;

        return (
            <div className="App">
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="sm"
                >
                    <ATitle id="form-dialog-title" ><font className="TopColor">Eddystones</font></ATitle>
                    <DialogContent >
                        <div style={{ marginTop: '1%' }}>
                            <font>ชื่อ</font>
                            <TextField
                                type="text"
                                variant="outlined"
                                onChange={this.handleSetState('name')}
                                fullWidth
                                autoComplete='off'
                            />
                        </div>
                        <div style={{ marginTop: '1%' }}>
                            <font>mac address</font>
                            <TextField
                                autoFocus
                                style={{ textTransform: 'uppercase' }}
                                type="text"
                                variant="outlined"
                                onChange={this.handleSetState('macAddress')}
                                fullWidth
                            />
                        </div>
                        <div style={{ color: "red" }}>{this.state.text}</div>
                    </DialogContent>
                    <DialogActions >
                        <Grid item xs container spacing={8} >
                            <Grid item xs={12}>
                                <DButton onClick={this.onsubmit} variant="contained"  >
                                    ยืนยัน
                                </DButton>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

AddEddystones.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default (withStyles(styles)(AddEddystones))
