import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import './styledropdown.css';
import createlisticon from '../../icon/icon-createlist.png';
import editicon from '../../icon/icon-edit.png';
import {
  creactSubjectList,
  editSubjectList
} from '../../actions';

const category = [
  {
    value: 'Lab',
    label: 'Lab',
  },
  {
    value: 'Lecture',
    label: 'Lecture',
  },
];



const Custombutton = withStyles({
  root: {
    color: "white",
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '100%',
    backgroundColor: "#14a37f"
  }
})(Button);

class Createlistname extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      name: '',
      hour: '3',
      category: 'Lecture',
      latetime: '15',
      start: this.setTime(),
      end: this.setTime(),
      text: '',
      nameTable:'สร้างรายการ',
      date:''
    }
  }

  setTime = () => {
    var hh = new Date().getHours()
    var min = new Date().getMinutes()
    if (min < 10) { min = '0' + min }
    if (hh < 10) { hh = '0' + hh }
    var time = hh + ':' + min
    return time
  }

  handleClickOpenOrClaose = (check,edit) => {
    this.setState({ open: check });
    if (check === false) {
      this.setState({
        name: '',
        hour: '3',
        category: 'Lecture',
        latetime: '15',
        start: this.setTime(),
        end: this.setTime(),
        text: '',
      })
    }
    if(edit){
      this.setState({
        name: this.props.dataList.list,
        hour: this.props.dataList.hour,
        category: this.props.dataList.type,
        latetime: '15',
        start: this.props.dataList.timeStart,
        end: this.props.dataList.timeEnd,
        nameTable:'แก้ไขรายการ',
        date:this.props.dataList.date
      })
    }
  };

  checkState = () => {
    
    if ((this.state.name && this.state.hour && this.state.latetime && this.state.start && this.state.end) === '') {
      this.setState({
        text: '*กรุณากรอกข้อมูลให้ครบถ้วน'
      })
    } else if (this.state.start >= this.state.end) {
      this.setState({
        text: '*กรุณากรอกเวลาเริ่มและเวลาสิ้นสุดให้ถูกต้อง'
      })
    } else if (this.state.hour <= 0) {
      this.setState({
        text: '*กรุณากำหนดชั่วโมงเรียนให้ถูกต้อง'
      })
    } else if (this.state.latetime <= 0) {
      this.setState({
        text: '*กรุณากำหนดเวลาเข้าสายให้ถูกต้อง'
      })
    } else {
      this.handleClickOpenOrClaose(false)
      if(this.state.nameTable === 'แก้ไขรายการ'){
        this.props.editSubjectList(this.props.dataList.idList,this.state.name, this.state.category, this.state.start, this.state.end, this.state.latetime, this.state.hour,this.props.idSubject, this.state.date)
      }else{
        this.props.creactSubjectList(this.props.idSubject,this.state.name, this.state.category, this.state.start, this.state.end, this.state.latetime, this.state.hour)
      }
    }
  }

  handleSetState = (value) => event => {
    this.setState({
      [value]: event.target.value,
    })
  }

  render() {
    const headerstyle = {
      color: "white",
      backgroundColor: "#14a37f",
      fontFamily: "'Open Sans', sans-serif",
      fonSize: '100%'
    }
    const button = {
      backgroundColor: "#14a37f"
    }
    const createicon = {
      marginRight: 2
    }
    const buttontop = {
      fontSize: 'auto',
      backgroundColor: "#14a37f",
      color: "white",
      width: '145px',
      height:'35px',
    }


    return (
      <div>{
        this.props.statusCreateList ?
        <Button variant="contained" style={buttontop} onClick={() => this.handleClickOpenOrClaose(true)}>
          <img src={createlisticon} alt={"createlisticon"} height='25vh' style={createicon} />
          <font style={{fontSize: '1.2vh  '}}>สร้างรายการ</font>
        </Button>
        :
        <Button className={button} onClick={() => this.handleClickOpenOrClaose(true,true)} >
          <img src={editicon} alt={"editicon"} height='25' />
        </Button>
      }
        
        <Dialog
          open={this.state.open}
          onClose={() => this.handleClickOpenOrClaose(false)}
          aria-labelledby="form-dialog-CreateClass"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-Class" style={headerstyle}><div className="TopColor">{this.state.nameTable}</div></DialogTitle>
          <DialogContent>
            <br></br>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <font>ชื่อรายการ</font>
                <TextField
                  id="namelist"
                  value={this.state.name}
                  onChange={this.handleSetState('name')}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={24}>
              <Grid item xs={12} >
              <font>ประเภท</font>
                <TextField
                  id="category"
                  select
                  value={this.state.category}
                  onChange={this.handleSetState('category')}
                  variant="outlined"
                  fullWidth
                >
                  {category.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid container spacing={24}>
              <Grid item xs={12} sm={6} md={6}>
                <font>กำหนดชั่วโมงเรียน</font>
                <TextField
                  id="hour"
                  type="number"
                  value={this.state.hour}
                  defaultValue="1"
                  onChange={this.handleSetState('hour')}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <font>กำหนดเวลาเข้าสาย(นาที)</font>
                <TextField
                  id="latetime"
                  value={this.state.latetime}
                  variant="outlined"
                  type="number"
                  onChange={this.handleSetState('latetime')}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={6} md={6}>
                <font>เริ่มเวลาเช็คชื่อ</font>
                <TextField
                  id="start"
                  value={this.state.start}
                  variant="outlined"
                  type="time"
                  onChange={this.handleSetState('start')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <font>สิ้นสุดเวลาเช็คชื่อ</font>
                <TextField
                  id="end"
                  value={this.state.end}
                  variant="outlined"
                  type="time"
                  onChange={this.handleSetState('end')}
                  fullWidth
                />
              </Grid>
            </Grid>
            <div style={{ color: 'red', marginTop: 14 }}> {this.state.text} </div>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <Custombutton variant="contained" style={button} onClick={this.checkState} fullWidth>
                <font> ยืนยัน  </font>
              </Custombutton>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

export default connect(mapStateToProps, {
  creactSubjectList,
  editSubjectList
})(Createlistname)
