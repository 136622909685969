import React, { Component } from 'react';
import '../../App.css';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Buttons, styles } from './style'
import AuthServics from '../../service/AuthService'
import history from "../../history";
import logo from '../../background/logo1.png';
import Register from '../Register';
const Auth = new AuthServics()

class Form extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      showMessage: false,
      value: 0,
    }
    // this.onSubmit = this.onSubmit.bind(this)
  }

  handleSetState = (value) => event => {
    this.setState({
      [value]: event.target.value,
    })

  }

  onSubmit = (e) => {
    e.preventDefault();
    if(this.state.username.trim().length !== 0 && this.state.password.trim().length !== 0){
      Auth.login(this.state.username, this.state.password)
      .then(res => {
        // this.props.history.replace('/dashboard');
        history.push({
          pathname: '/dashboard',
        });
      })
      .catch(err => {
        this.setState({
           showMessage: true 
          })
      })
    }else{
      this.setState({ showMessage: true })
    }
    
  }

  componentWillMount() {
    if (Auth.loggedIn()) {
      this.props.history.replace('/dashboard')
      history.push({
        pathname: '/dashboard',
      });
    }
  }

  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.onSubmit(e)
    }
  }

  handleChange = (event, value) => {
    
    this.setState({ value ,
      showMessage:false
    });
  };


  render() {
    const { value } = this.state;
    const { classes } = this.props;
    return (
      <div className="background">
        <Grid className={classes.root}  >
          <Grid xl={2} lg={3} md={4} sm={5} xs={9}
            style={{
              padding: '2.5% 0.5% 2.5% 0.5%',
              borderRadius: 8,
              background: '#f1f8e9',
              display: 'flex',
              flexDirection: 'column',
              alignItem: 'center',
              margin: "auto",
              textAlign: "center"
            }}
          >
            <Grid xs={12} >
              <img src={logo} alt="logo" className={classes.logoimg} resizeMode="contain" />
              <AppBar position="static" className={classes.appbarcolor}>
                <Tabs value={value} onChange={this.handleChange} indicatorColor="secondary" variant="fullWidth">
                  <Tab label={<font className={classes.textstyle}>เข้าสู่ระบบ</font>} />
                  <Tab label={<font className={classes.textstyle}>สมัครสมาชิก</font>} />
                </Tabs>
              </AppBar>
            </Grid>
            <Grid xs={12} alignItems="center" justify="center">
              {value === 0 &&
                <TabContainer>
                  <Grid xs={12} alignItems="center" justify="center">
                    <TextField
                      id="outlined-name"
                      label={<font>ชื่อผู้ใช้งาน</font>}
                      onChange={this.handleSetState('username')}
                      variant="outlined"
                      margin="normal"
                      onKeyPress={this.onKeyPress}
                    ></TextField>
                  </Grid>
                  <Grid xs={12} alignItems="center" justify="center">
                    <TextField
                      id="outlined-password-input"
                      label={<font>รหัสผ่าน</font>}
                      onChange={this.handleSetState('password')}
                      type="password"
                      autoComplete="current-password"
                      variant="outlined"
                      margin="normal"
                      onKeyPress={this.onKeyPress}
                    ></TextField>
                  </Grid>
                  <Grid xs={12} alignItems="center" justify="center" >
                    <Buttons onClick={this.onSubmit} >
                      <font>เข้าสู่ระบบ</font>
                    </Buttons>
                  </Grid>
                </TabContainer>}
            </Grid>
            <Grid xs={12} alignItems="center" justify="center">
              {value === 1 &&
                <TabContainer>
                  <Register />
                </TabContainer>
              }
            </Grid>
            <Grid xs={12} style={{display: 'flex', alignSelf: 'center'}}>
              {this.state.showMessage ?
                <div >
                 <font  color="red"> *ชื่อผู้ใช้งาน หรือ รหัสผ่าน <br /> ไม่ถูกต้องกรุณากรอกใหม่ </font> 
                </div>
                : null}
            </Grid>

          </Grid>

        </Grid>

      </div>
    )
  }
}
Form.propTypes = {
  classes: PropTypes.object.isRequired,
};

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(Form);

