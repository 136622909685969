import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  CustomTableHead,
  CustomTableCell1,
  CustomTableCellbody,
  ButtonPass, styles,
  ButtonLate, PaperStyle
} from './style'
import io from 'socket.io-client';
import { urlIO } from '../../url'
import {
  getListName,
  delteStudentCheck,
  editStatusCheckName
} from '../../actions';
import DeleteDialog from '../Dialog/DeleteDialog';
import EditStatus from '../Dialog/EditStatusCheckName'

function createData(rank, idstu, name, lastName, time, hour, status, id) {
  name = name + " " + lastName;
  //user.studentCode , user.name, user.lastName, user.createdAt ,2 , user.status)
  var hh = new Date(time).getHours();
  var mm = new Date(time).getMinutes();
  if (hh < 10) { hh = '0' + hh }
  if (mm < 10) { mm = '0' + mm }
  time = hh + ":" + mm
  if (status === "InTime") { status = 'ทันเวลา' }
  if (status === "Late") { status = 'สาย' }
  return { rank, idstu, name, time, hour, status, id };
}
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}
function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const socket = io.connect(urlIO);
function ChangeStatus(status) {
  if (status === 'ทันเวลา') {
    return <CustomTableCellbody align="center"><ButtonPass  ><font style={{fontSize: '1.5vh'}}>ทันเวลา</font></ButtonPass></CustomTableCellbody>
  } else {
    return <CustomTableCellbody align="center"><ButtonLate ><font style={{fontSize: '1.5vh'}}>สาย</font></ButtonLate></CustomTableCellbody>
  }
}
class TableCheck extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  constructor(props) {
    super(props)
    this.state = {
      stduentRow: [],
      loading: false,
      order: '-desc',
      orderBy: 'rank',
    };
    this.props.getListName(this.props.idlist)
    this.subscribeServer((err, data) => {
      this.dataSetState(data)
    })
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.checkname !== this.props.checkname) {
      this.dataSetState(nextProps.checkname)
    }
  }

  dataSetState = async (data) => {
    await this.setState({
      stduentRow: data.map((row, index) => {
        return createData(index + 1, row.userId.studentId, row.userId.name, row.userId.lastName, row.createdAt, this.props.hour, row.status, row._id)
      })
    })
  }

  subscribeServer(cb) {
    socket.on('msg', data => {
      cb(null, data);
    })
  }


  getlistname = async () => {
    await this.setState({
      row: this.props.checkname.map((row, index) => {
        return createData(index + 1, row.userId.studentId, row.userId.name, row.userId.lastName, row.createdAt, this.props.hour, row.status)
      })
    })
  }

  confirmDelete = (idcheck, idlist) => {
    this.props.delteStudentCheck(idcheck, idlist)
    this.props.getListName(this.props.idlist)
  }

  confirmEdit = (idcheck, status) => {
    status === 'สาย' ? status = 'Late' : status = 'InTime'
    this.props.editStatusCheckName(idcheck, status, this.props.idlist)
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper style={PaperStyle}>
        <Table>
          <CustomTableHead >
            <TableRow  >
              <CustomTableCell1 align="center" style={{fontSize: '1.8vh'}}>ลำดับ</CustomTableCell1>
              <CustomTableCell1 align="center" style={{fontSize: '1.8vh'}}>รหัสนิสิต</CustomTableCell1>
              <CustomTableCell1 align="center" style={{fontSize: '1.8vh'}}>ชื่อ - นามสกุล</CustomTableCell1>
              <CustomTableCell1 align="center" style={{fontSize: '1.8vh'}}>เวลา</CustomTableCell1>
              <CustomTableCell1 align="center" style={{fontSize: '1.8vh'}}>ชั่วโมง</CustomTableCell1>
              <CustomTableCell1 align="center" style={{fontSize: '1.8vh'}}>สถานะ</CustomTableCell1>
              <CustomTableCell1 align="center"> <Grid container direction="row" justify="center" alignItems="center" ><font style={{fontSize: '1.8vh'}}>เครื่องมือ</font></Grid></CustomTableCell1>
            </TableRow>
          </CustomTableHead>
          <TableBody>
            {stableSort(this.state.stduentRow, getSorting(this.state.order, this.state.orderBy)).map((row) => {
              return (
                <TableRow key={row.rank} className={classes.row} >
                  <CustomTableCellbody component="th" scope="row" align="center">{row.rank}</CustomTableCellbody>
                  <CustomTableCellbody align="center" style={{fontSize: '1.7vh'}}>{row.idstu}</CustomTableCellbody>
                  <CustomTableCellbody align="center" style={{fontSize: '1.7vh'}}>{row.name}</CustomTableCellbody>
                  <CustomTableCellbody align="center" style={{fontSize: '1.7vh'}}>{row.time}</CustomTableCellbody>
                  <CustomTableCellbody align="center" style={{fontSize: '1.7vh'}}>{row.hour}</CustomTableCellbody>
                  {ChangeStatus(row.status)}
                  <CustomTableCellbody align='center'>
                    <Grid container direction="row" justify="center"  >
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <EditStatus statusProps={row.status} onConfirmEdit={(status) => this.confirmEdit(row.id, status)} />
                        <DeleteDialog onConfirmCallback={() => this.confirmDelete(row.id, this.props.idlist)} />
                      </div>
                    </Grid>
                  </CustomTableCellbody>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    )
  }
}
TableCheck.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

export default connect(mapStateToProps, {
  getListName,
  delteStudentCheck,
  editStatusCheckName
})(withStyles(styles)(TableCheck))