const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing.unit * 1,
    },
    fontstyle: {
        fontSize: '2.2vh',
    },
    iconsearchbutton: {
        padding: 10,
    },
    input: {
        marginLeft: '1%',
        flex: 1,
    },
    papersearch: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 200,
        height: '35%',
        margin: theme.spacing.unit,
    },
    headerpaper: {
        overflowX: 'auto',
        marginTop: '0.3%',
        margin: theme.spacing.unit,
        marginLeft: theme.spacing.unit * 2,
        marginBottom: '0%',
    },

});

const stylesDrodown = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit,
        width: 150
    },
    fontstyle: {
        fontFamily: "'Prompt', sans-serif",
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
      },
      menu: {
        width: 200,
      },
});

export {styles, stylesDrodown}