import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import {
    CustomTableHead,
    CustomTableCell1,
    CustomTableCellbody,
    styles,
    PaperStyle,
} from './style'

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}
function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


class TableResult extends Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    constructor(props) {
        super(props)
        this.state = {
            //   loading: false,
            order: '-desc',
            orderBy: 'id',
            rowsPerPage: 10,
            page: 0,
            selected: [],
            // totalList: [1, 0, 1,0,0,0,0,0,0,0,0],
            datas: []
        };
    };

    componentDidMount(){
        this.setState({
            datas: this.props.reports
         })
    }
    
    componentWillReceiveProps(nextProps) {
        if (nextProps.reports !== this.props.reports) {
             this.setState({
                datas: nextProps.reports 
             })
        }
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };
    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState(state => ({ selected: state.datas.map(n => n.id) }));
            return;
        }
        this.setState({ selected: [] });
    };
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    render() {
        const { classes } = this.props;
        const { datas, rowsPerPage, page, order, orderBy} = this.state;
        return (
            <Paper style={PaperStyle}>
                <Table aria-labelledby="tableTitle" >

                    <CustomTableHead style={{
                        background: "#14a37f",
                        color: "white",
                        // textAlign: 'center',
                        fontFamily: "'Prompt', sans-serif",
                    }} >

                        {/* <tr style={{height: '4.5vh' , backgroundColor: '#14a37f'}}><th colSpan={7}/><th colSpan={this.state.totalList.length} >คาบเรียน</th></tr> */}
                        <TableRow >
                            <CustomTableCell1 rowSpan={2} align="center" >ลำดับ</CustomTableCell1>
                            <CustomTableCell1 rowSpan={2} align="center" >รหัสนิสิต</CustomTableCell1>
                            <CustomTableCell1 rowSpan={2} align="center" >ชื่อ - นามสกุล</CustomTableCell1>
                            <CustomTableCell1 rowSpan={2} align="center" >กลุ่ม</CustomTableCell1>
                            <CustomTableCell1 colSpan={2} align="center" style={{border:' 1px solid rgba(224, 224, 224, 1)'}}>ทันเวลาสะสม</CustomTableCell1>
                        </TableRow>
                        <TableRow >
                            <CustomTableCell1 align="center">ครั้ง</CustomTableCell1>
                            <CustomTableCell1 align="center">ชั่วโมง</CustomTableCell1>
                        </TableRow>
                    </CustomTableHead>
                    <TableBody >
                        {stableSort(datas,
                            getSorting(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row,index) => {
                                return (
                                    <TableRow key={index} className={classes.row}>
                                        <CustomTableCellbody component="th" scope="row" align="center" >{index+1}</CustomTableCellbody>
                                        <CustomTableCellbody align="center">{row.studentId}</CustomTableCellbody>
                                        <CustomTableCellbody align="center">{row.name+" "+row.lastName}</CustomTableCellbody>
                                        <CustomTableCellbody align="center">{row.group}</CustomTableCellbody>
                                        <CustomTableCellbody align="center">{row.inTime.number}</CustomTableCellbody>
                                        <CustomTableCellbody align="center">{row.inTime.hours}</CustomTableCellbody>
                                    </TableRow>
                                );
                            })}


                    </TableBody>

                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={datas.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </Paper>
        )
    }
}
TableResult.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default (withStyles(styles)(TableResult))