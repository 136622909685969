import React, { Component } from 'react';
import Login from './component/Login'
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers';
import { apiMiddleware } from 'redux-api-middleware';
import thunk from 'redux-thunk';
import './index.css';
import { Route, Switch, Router} from 'react-router-dom';
import App from './App';
import history from "./history";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: '#14a37f',
        },
        secondary: {
            main: '#f1f8e9',
        },
    },
},
)

class RouterSuper extends Component {
    render() {
        const store = createStore(reducers, applyMiddleware(thunk, apiMiddleware));
        return (
            <Provider className="App" store={store}>
                <MuiThemeProvider theme={theme}>
                    <Router history={history} >
                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Route path="/login" component={Login}  />
                            <Route component={App}/>
                        </Switch>
                    </Router>
                </MuiThemeProvider>
            </Provider>
            
        )
    }
}

export default RouterSuper




