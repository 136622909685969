import {url} from '../url';

export const creactClass = (className,classCode,section,term,year,totalHour,colorCode) => {
        return fetch( url+'/class/create', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer "+localStorage.getItem('id_token')
            },
            body: JSON.stringify({
                className,
                classCode,
                section,
                term,
                year,
                totalHour,
                colorCode
            })
        }).then(res => {
            // this.setToken(res.token) // Setting the token in localStorage
            // return Promise.resolve(res);
        })
}

export const getClassService = () => {
    return fetch(url+'/class', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('id_token')
        }
      }).then(response => response.json())
        .then(responseJson => {
          return responseJson
        })
}

export const deleteClassService = (id) => {
    return fetch(url+'/class/delete', {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('id_token')
        },
        body: JSON.stringify({
            id
        })
    })
}

export const addLine = (_id,line) => {
    return fetch(url+'/class/addLine', {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('id_token')
        },
        body: JSON.stringify({
            _id,
            line
        })
    })
}

export const editclass = (_id,className,classCode,section,term,year,totalHour,colorCode) => {
    return fetch(url+'/class/editclass', {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('id_token')
        },
        body: JSON.stringify({
            _id,
            className,
            classCode,
            section,
            term,
            year,
            totalHour,
            colorCode
        })
    })
} 

export const getYear = (userId) => {
    return fetch(url+'/class/getYear', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('id_token')
        },
    })
}