import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100vh'

  },
  buttonstyle: {
    align: 'center'
  },
  appbarcolor: {
    background: '#00a152',
    borderRadius: 2
  },
  logoimg: {
    width: '16vh',
    height: '17.5vh'
  },
  textstyle: {
    fontSize: 16
  }
});

const Buttons = withStyles({
  root: {
    background: "#00a152",
    color: "white",
    padding: '3% 30% 3% 30%',
    marginTop: "5%",
    fontSize: "0.4cm",
  },
})(Button);

export { styles, Buttons }
