import { RSAA  } from 'redux-api-middleware';
import { 
    LIST_SUBJECT_GET_FAILURE,
    LIST_SUBJECT_GET_REQUEST,
    LIST_SUBJECT_GET_SUCCESS,
    LIST_SUBJECT_UPLOAD_SUCCESS,
    LIST_SUBJECT_UPLOAD_REQUEST,
    LIST_SUBJECT_UPLOAD_FAILURE,
    LIST_SUBJECT_DELETE_REQUEST,
    LIST_SUBJECT_DELETE_SUCCESS,
    LIST_SUBJECT_DELETE_FAILURE,
    LIST_SUBJECT_EDIT_FAILURE,
    LIST_SUBJECT_EDIT_REQUEST,
    LIST_SUBJECT_EDIT_SUCCESS,
    LIST_SUBJECT_SET
} from './Types';
import {url} from '../url';

export const getListSubjects = (idlist) => {
 
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/classCheckList?id=${idlist}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                types: [
                    LIST_SUBJECT_GET_REQUEST,
                    {
                        type: LIST_SUBJECT_GET_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            return response;
                        }
                    },
                    LIST_SUBJECT_GET_FAILURE
                ]
            },
        })
    }
   
}

export const delteSubjectList = (_id,classId) => {
 
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/classCheckList/delete`,
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                body: JSON.stringify({
                    _id
                }),
                types: [
                    LIST_SUBJECT_DELETE_REQUEST,
                    {
                        type: LIST_SUBJECT_DELETE_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            dispatch(getListSubjects(classId))
                            return response;
                        }
                    },
                    LIST_SUBJECT_DELETE_FAILURE
                ]
            },
        })
    }
   
}

function setdata(time,date){
    var dd,mm,yy;
    if(date){
    let daySplit = date.split('/')
         dd = daySplit[0]
         mm = daySplit[1]
         yy = daySplit[2]-543
    }else{
         dd = new Date().getDate()
         mm = (new Date().getMonth())+1
         yy = new Date().getFullYear()
    if(mm < 10){ mm = '0'+ mm}
    if(dd < 10){ dd = '0'+ dd}
    }

    return yy + '-' + mm + '-' + dd + 'T' + time +':00.000Z'
}
    

export const creactSubjectList = (classId, title, type, start, end, lateTime,totalHour) => {
    var timeStartCheckName = setdata(start)
    var timeEndCheckName = setdata(end)
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/classCheckList/create`,
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                body: JSON.stringify({
                    classId,
                    title, 
                    type, 
                    timeStartCheckName, 
                    timeEndCheckName, 
                    lateTime,
                    totalHour,
                }),
                types: [
                    LIST_SUBJECT_UPLOAD_REQUEST,
                    {
                        type: LIST_SUBJECT_UPLOAD_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            dispatch(getListSubjects(classId))
                            return response;
                        }
                    },
                    LIST_SUBJECT_UPLOAD_FAILURE
                ]
            },
        })
    }
   
}


export const editSubjectList = (_id,title,type,start,end,lateTime,totalHour,classId,date ) => {
    var timeStartCheckName = setdata(start,date)
    var timeEndCheckName = setdata(end,date)
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/classCheckList/edit`,
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                body: JSON.stringify({
                    _id,
                    title,
                    type,
                    timeStartCheckName,
                    timeEndCheckName,
                    lateTime,
                    totalHour,
                }),
                
                types: [
                    LIST_SUBJECT_EDIT_REQUEST,
                    {
                        type: LIST_SUBJECT_EDIT_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            dispatch(getListSubjects(classId))
                            return response;
                        }
                    },
                    LIST_SUBJECT_EDIT_FAILURE
                ]
            },
        })
    }
}


export const setDataListSubject = (arr) => {
    return (dispatch) => {
        dispatch({
            type: LIST_SUBJECT_SET,
            payload: arr
        })
   
    }
}