import React, { Component } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Email from '../../icon/email.png';



class Register extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
        }
    }

    async  componentDidMount() {
        await this.setState({
            open: this.props.dialogStatus,
        })

    }
    async componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.dialogStatus !== this.props.dialogStatus) {
            await this.setState({
                open: nextProps.dialogStatus,
            })
        }

    }


    handleClickOpen = () => {
        this.setState({ open: true });
    };


    handleClose = () => {        
        this.props.handleCloseDialog()        
    }
    
    render() {
        return (
            <div className="App">
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    // fullWidth={true}
                >
                    <DialogContent>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '26vh',width: '30vw' }}>
                            <img src={Email} alt="Email" style={{ width: '20vh', height: 'auto', marginBottom: '5%' }} />
                            <font style={{ fontSize: '1.9vh' }}>กรุณายืนยันการลงทะเบียนที่อีเมลของท่าน</font>
                        </div>
                    </DialogContent>
                </Dialog>

            </div>
        )
    }
}

Register.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default Register
