import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { MenuItem } from '@material-ui/core';
import { ATitle, DButton, styles } from './style'
import '../../App.css';
import * as Service from '../../service/ServicesChecklist';
import { connect } from 'react-redux';
import {
    addStudentSubject,
    resetValidate
} from '../../actions'
class FormDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            dialogStatus: false,
            idStu: '',
            name: '',
            faculty: '',
            major: '',
            group: '',
            loding: false,
            dataSearch: [],
            text: ''
        };
    }
    async  componentDidMount() {
        await this.setState({
            open: this.props.dialogStatus,
        })

    }
    async componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.dialogStatus !== this.props.dialogStatus) {
            await this.setState({
                open: nextProps.dialogStatus,
            })
            if(this.state.open === false){
                this.setState({
                    idStu:'',
                    group:''
                })
            this.props.resetValidate('')
            }
        }

    }
    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleSetState = (value) => event => {
        this.setState({
            [value]: event.target.value,
        })
    }

    confirmAdd = () => {
        if ((this.state.idStu && this.state.group) !== '') {
            this.props.addStudentSubject(this.props.idSubject, this.state.idStu, this.state.group)
            if (!this.props.main.status) {
                this.props.handleCloseDialog()
                this.setState({
                    open: false,
                    idStu: '',
                    group: '',
                    file: ''
                })
                this.props.resetValidate('')
            }
        } else if (this.state.group <= 0) {
            this.props.resetValidate('*กรุณาใส่ข้อมูลกลุ่มให้ถูกต้อง')
        } else {
            this.props.resetValidate('*กรุณาใส่ข้อมูลให้ครับถ้วน')
        }
    }

    searchStudent = () => {
        if (this.state.loding === false) {
            this.setState({ loding: true })
            if (this.state.idStu === '') {
                this.setState({
                    dataSearch: [],
                    loding: false,
                })
            } else {
                Service.searchStudent(this.state.idStu)
                    .then((row,index) => {
                        this.setState({
                            loding: false, dataSearch: row
                        })
                    })
            }

        }
    }

    clearState(id) {
        this.setState({
            idStu: id
        })
        this.setState({
            dataSearch: [],
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="App">
                <Dialog
                    open={this.state.open}
                    onClose={this.props.handleCloseDialog}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="sm"
                >
                    <ATitle id="form-dialog-title" ><font className="TopColor">เพิ่มรายชื่อ</font></ATitle>
                    <DialogContent className={classes.content}>

                        <font>รหัสนิสิต</font>
                        <TextField
                            margin="dense"
                            id="id"
                            value={this.state.idStu}
                            type="text"
                            variant="outlined"
                            onChange={this.handleSetState('idStu')}
                            onKeyUp={this.searchStudent}
                            fullWidth
                            autoComplete='off'
                        />
                        <Paper>
                            {
                                this.state.dataSearch.map(row => {
                                    return <MenuItem
                                        onClick={() => this.clearState(row.studentId)}>
                                        <font>{row.studentId} {row.name} {row.lastName}</font>
                                    </MenuItem>
                                })
                            }
                        </Paper>

                        <font>กลุ่ม</font>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="group"
                            value={this.state.group}
                            type="number"
                            variant="outlined"
                            onChange={this.handleSetState('group')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                        <div style={{ color: "red" }}>{this.props.main.validate}</div>
                    </DialogContent>
                    <DialogActions >
                        <Grid item xs container spacing={8} >
                            <Grid item xs={12}>
                                <DButton onClick={this.confirmAdd} variant="contained"  >
                                    ยืนยัน
                                </DButton>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

FormDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => {
    return {
        ...state,
    }
}

export default connect(mapStateToProps, {
    addStudentSubject,
    resetValidate
})(withStyles(styles)(FormDialog))

