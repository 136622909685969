import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as XLSX from 'xlsx';
import AddStudentDialog from '../Dialog/AddStudentDialog'
import history from '../../history';
import { connect } from 'react-redux';
import {
  setPath,
  addStudentExcel,
  getStudent
} from '../../actions';
import {url} from '../../url'



function buildFileSelector() {
  const fileSelector = document.createElement('input');
  fileSelector.setAttribute('type', 'file');
  fileSelector.setAttribute('accept', '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel ,.ods , .xlxs, .xlsm, .xltx, .xltm,.xlsb,.xlam ,.xlsx, .xlsb, .xltx ,.xltm ,.xls , .xlt ');
  fileSelector.setAttribute('id', 'file');
  return fileSelector;
}

class MenuManage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      dialogStatus: false
    };
  };


  componentDidMount() {
    this.fileSelector = buildFileSelector();
  }
 
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  excel =  async(file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = async(evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const datas = XLSX.utils.sheet_to_json(ws, { header: 1 });
        /* Update state */
        datas.shift()
        let studentId
        const studentData = datas.map(row => {
          if(row.length=== 6){
            let [rank, idStudnet, name, branch, faculty, groupNumber] = row
              if(typeof idStudnet === 'string'){
                studentId = idStudnet.replace(/\s/g,"") 
              }else{
                studentId = idStudnet.toString()
              }
              let group = groupNumber.toString()
            return { rank, studentId, name, branch, faculty, group, classId:this.props.idSubject }
          }
          return null
        })
       await this.props.addStudentExcel(studentData,this.props.idSubject)  
      }
      reader.readAsBinaryString(file);
    }
    this.fileSelector = buildFileSelector();
  }
  handleFileSelect = (e) => {
    e.preventDefault();
    this.fileSelector.click();
    this.fileSelector.onchange = () => {
      let file = this.fileSelector.files[0]
      this.excel(file)
    }
  }

  handleDownLoad = () => {
    window.open(`${url}/upload/file/UploadFormat.xlsx`)
  }

  handleCloseDialog = () => {
    this.setState({ dialogStatus: false });
  };
  addStudent = () => {
    this.handleClose()
    this.setState({ dialogStatus: true })
  }
  registerRFID = () => {
    this.handleClose()
    history.push({
      pathname: '/RegisterRFID',
      state: {
        idSubject: this.props.idSubject,
        routeName: this.props.routeName
      }
    });
  }
  
  render() {
    const { anchorEl } = this.state;
    return (
      <div>
        <Button
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          style={{
          fontFamily: "'Prompt', sans-serif" ,
          marginTop: '2%',
          fontSize: 'auto',
          backgroundColor: "#14a37f",
          color: "white",
          width: '3.5cm'
        }}
        >
          <font style={{ fontSize: '1.2vh  '}}>จัดการข้อมูล</font>
          <ion-icon name="arrow-dropdown"></ion-icon>
        </Button> 
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.addStudent} style={{ justifyContent: 'center' }}>
            <font> เพิ่มรายชื่อ </font>
          </MenuItem>
          <MenuItem onClick={this.handleFileSelect} style={{ justifyContent: 'center' }}>
            <font> อัปโหลดรายชื่อ </font>
          </MenuItem>
          <MenuItem onClick={this.handleDownLoad} style={{ justifyContent: 'center' }}>
            <font> แบบฟอร์มรายชื่อ </font>
          </MenuItem>
          <MenuItem onClick={this.registerRFID} style={{ justifyContent: 'center' }}>
            <font> ลงทะเบียนบัตร </font>
          </MenuItem>
        </Menu>
        <AddStudentDialog dialogStatus={this.state.dialogStatus} handleCloseDialog={this.handleCloseDialog} idSubject={this.props.idSubject} />

      </div>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

export default connect(mapStateToProps, {
  setPath,
  addStudentExcel,
  getStudent
})(MenuManage)