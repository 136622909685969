import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import * as ChangePasswordServices from '../../service/UserServices';

const blackground = {
    backgroundColor: '#14a37f',
    color: 'white'
}
const dialogContent = {
    padding: '5%',
    flex: 1,
    flexDirection: 'column',
}
const buttonConfirm = {
    backgroundColor: '#14a37f',
    color: 'white',
    padding: '2%',
}
const buttonCancle = {
    backgroundColor: '#E19C14',
    color: 'white',
    padding: '2%',
    marginTop: '1%'
}

class ChangePasswordDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            password: '',
            newPassword: '',
            reNewPassword: '',
        };
    };

    componentDidMount() {
        this.setState({ open: this.props.dialogStatus })
    }

    
    componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.dialogStatus !== this.props.dialogStatus) {
            this.setState({ open: nextProps.dialogStatus })
        }
    }
    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = async () => {
        let {
            password,
            newPassword,
            reNewPassword,
        } = this.state

        const datas = await ChangePasswordServices.ChangePassword(password, newPassword, reNewPassword)
        if ((password && newPassword && reNewPassword) === '') {
            this.setState({
                text: '*กรุณากรอกข้อมูลให้ครบถ้วน'
            })
        }
        else if (datas === "password old not match" || datas === 'new password not match') {
            this.setState({
                text: '*รหัสผ่านไม่ถูกต้องกรุณากรอกใหม่อีกครั้ง'
            })
        }
        else if (datas === 'password changes sucess') {
            this.props.handleCloseDialog()
            this.setState({
                password: '',
                newPassword: '',
                reNewPassword: '',
                text: ''
            })

        }
    }

    handleSetState = (value) => event => {
        this.setState({
            [value]: event.target.value,
        })
    }


    render() {
        return (
            <div>
                <Dialog
                    open={this.state.open}
                    onClose={this.props.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle style={blackground}>  <font className="TopColor">เปลี่ยนรหัสผ่าน</font> </DialogTitle>
                    <DialogContent style={dialogContent}>
                        <Grid container spacing={16}>
                            <Grid item xs={12}>
                                <TextField
                                    id="password"
                                    style={{ width: '100%' }}
                                    label={<font style={{ fontSize: '1vw' }}>รหัสผ่านเดิม</font>}
                                    type="password"
                                    value={this.state.password}
                                    onChange={this.handleSetState('password')}
                                    variant="outlined"
                                ></TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    style={{ width: '100%' }}
                                    id="newPassword"
                                    label={<font style={{ fontSize: '1vw' }}>รหัสผ่านใหม่</font>}
                                    type="password"
                                    value={this.state.newPassword}
                                    onChange={this.handleSetState('newPassword')}
                                    variant="outlined"
                                ></TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    style={{ width: '100%' }}
                                    id="reNewPassword"
                                    label={<font style={{ fontSize: '1vw' }}>ยืนยันรหัสผ่านใหม่</font>}
                                    type="password"
                                    value={this.state.reNewPassword}
                                    onChange={this.handleSetState('reNewPassword')}
                                    variant="outlined"
                                ></TextField>
                            </Grid>
                        </Grid>
                        <div style={{ color: 'red', marginTop: 14 }}> {this.state.text} </div>
                    </DialogContent>

                    <DialogActions>
                        <Grid container>
                            <Grid item xs={12}>
                                <Button
                                    onClick={this.handleClose} variant="contained" style={buttonConfirm} fullWidth>
                                    <font> ยืนยัน </font>
                                </Button>
                            </Grid>

                            <Grid item xs={12}>
                                <Button onClick={this.props.handleCloseDialog} variant="contained" style={buttonCancle} fullWidth>
                                    <font> ยกเลิก </font>
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default ChangePasswordDialog;