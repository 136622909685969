import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { connect } from 'react-redux'
import TableResult from './table'
import {
    setPath,
    getGroup,
    setDataGroup
} from '../../actions';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 1,
        overflowX: 'auto',
    },
    table: {
        minWidth: '100%',
        maxWidth: '100%',
    },
    tableWrapper: {
        overflowX: 'auto',
        marginTop: theme.spacing.unit,
        margin: theme.spacing.unit * 2,
    },
    headerpaper: {
        overflowX: 'auto',
        marginTop: '0%',
        margin: theme.spacing.unit * 2,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        gridbutton: {
            marginRight: '-100%'
        }
    },
    gridlefttype: {
        marginLeft: '2%',
    },
    fontstyle: {
       
        fontSize: '150%',
        marginTop: '1%',
        textAlign: 'Left'
    },
    progress: {
        margin: theme.spacing.unit * 20,
    },
});

class TabResult extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            group: ['ทั้งหมด'],
            category: '',
        };
        this.props.getGroup(this.props.idSubject)
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
        let setData = this.props.main.defaultGroup.filter(row => {
            if (row[row.length-1].group === event.target.value) {
                return row
            }
            return ''
        })
        if(event.target.value){
            this.props.setDataGroup(setData)
        }else{
            this.props.setDataGroup(this.props.main.defaultGroup)
        }   
    };

    componentWillReceiveProps(nextProps){
        if(this.props.report.groupStudnet !== nextProps.report.groupStudnet){
            this.setState({
                group:nextProps.report.groupStudnet
            })
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper className={classes.root}>
                <div className={classes.headerpaper}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={6} container direction="row" justify="flex-start" alignItems="center" >
                                <Grid >
                                    <font className={classes.fontstyle}>กลุ่มเรียน</font>
                                    <div className={classes.root}>
                                        <FormControl style={{ width: '10vh' }}>
                                            <Select
                                                value={this.state.category}
                                                onChange={this.handleChange('category')}
                                                displayEmpty
                                                name="category"
                                                style={{ width: 89}}
                                            >
                                                <MenuItem value=""  ><font>ทั้งหมด</font></MenuItem>
                                                {
                                                    this.state.group.map((data,index) => {
                                                        return (
                                                            <MenuItem value={data} key={index}><font>{data}</font></MenuItem>
                                                        )
                                                    })
                                                }
                                                
                                            </Select>
                                        </FormControl>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} alignItems="flex-end" justify="flex-end" container>
                                {/* <ButtonDownLoad variant="contained" className={classes.buttontop} >
                                    <ion-icon name="cloud-download"  ></ion-icon>  <font>ดาวน์โหลดผลการเช็คชื่อ</font>
                                </ButtonDownLoad> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.tableWrapper}>
                    <TableResult report={this.props.report} />
                </div>
            </Paper>
        );
    }
}

TabResult.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state,
    }
}
export default connect(mapStateToProps, {
    setPath,
    getGroup,
    setDataGroup
})(withStyles(styles)(TabResult))
