import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Loading from '../Loading';
import history from '../../history';
import DeleteDialog from '../Dialog/DeleteDialog';
import boxicon from '../../icon/icon-box.png';
import { connect } from 'react-redux';
import { styles } from './style';
import EnhancedTableHead from '../EnhancedTableHead';
import ListCheckDialog from '../Dialog/ListCheckDialog';
import {
  setPath,
  getListSubjects,
  delteSubjectList
} from '../../actions';
import manualicon from '../../icon/icon-manual.png';


function createData(id, list, type, hour, dateS, idList, dateEnd) {
  let data = getDateTime(dateS)
  let date = data.dateFormat
  let timeStart = data.timeFormat
  let dataEnd = getDateTime(dateEnd)
  let timeEnd = dataEnd.timeFormat
  return { id, list, type, hour, date, idList, timeStart, timeEnd };
}

function getDateTime(date) {
  let newDate = new Date(date)
  let dd = newDate.getUTCDate()
  let mm = newDate.getUTCMonth()
  let yy = newDate.getUTCFullYear()
  let hh = newDate.getUTCHours()
  let min = newDate.getUTCMinutes()
  yy += 543;
  mm += 1;
  if (mm < 10) { mm = '0' + mm }
  if (dd < 10) { dd = '0' + dd }
  if (hh < 10) { hh = '0' + hh }
  if (min < 10) { min = '0' + min }

  let dateFormat = dd + '/' + mm + '/' + yy
  let timeFormat = hh + ':' + min
  return { dateFormat, timeFormat }
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'id', numeric: true, disablePadding: true, label: 'ลำดับ' },
  { id: 'list', disablePadding: true, label: 'รายการ' },
  { id: 'type', disablePadding: true, label: 'ประเภท' },
  { id: 'hour', disablePadding: true, label: 'ชั่วโมง' },
  { id: 'date', disablePadding: true, label: 'วันที่' },
  { id: 'check', disablePadding: true, label: 'เช็คชื่อ' },
  { id: 'tool', disablePadding: true, label: 'เครื่องมือ' },

];

class Tablelistcheck extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      order: '-desc',
      orderBy: 'id',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 5,
      check: false
    };
    this.props.getListSubjects(this.props.idSubject)
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  linkmanage = (id) => {
    history.push({
      pathname: '/Manage',
      state: { classname: this.props.class, classid: id }
    });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  componentWillReceiveProps(nextProps) {
    if (nextProps.list_subject.listSubjects !== this.props.list_subject.listSubjects) {
      this.getList(nextProps.list_subject.listSubjects)
    }
  }

  getList = async (listSubjects) => {    
    const data = listSubjects.map((row, index) => {
      return createData((index + 1), row.title, row.type, row.totalHour, row.timeStartCheckName, row._id, row.timeEndCheckName)
    })

    await this.setState({
      data
    })
  }

  deleteList = async (idList, idclass) => {
    await this.props.delteSubjectList(idList, idclass)
  }

  onNext(id, h) {
    history.push({
      pathname: '/Makrubcheck',
      state: {
        idlist: id,
        hour: h,
        classnameMakub: this.props.class,
        idSubject: this.props.idSubject,
        routeName: this.props.routeName
      }
    });
  }

  onNextManul(idList) {
    history.push({
      pathname: '/Manual',
      state: {
        // classnameMakub: this.props.class,
        idSubject: this.props.idSubject,
        routeName: this.props.routeName,
        idList:idList
      }
    });
  }

  render() {    
    const { classes,list_subject } = this.props;    
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    if (list_subject.loading) {
      return (
        <Loading/>
      )
    }
    const CustomTableCell = withStyles(theme => ({
      head: {
        fontSize: '1.8vh',
        background: "#14a37f",
        color: "white",
        textAlign: 'center',
        fontFamily: "'Prompt', sans-serif"
      },
      body: {
        fontSize: '1.7vh',
        fontFamily: "'Prompt', sans-serif",
        textAlign: 'center'
      },

    }))(TableCell);
    return (
  
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              rows={rows}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n, index) => {
                  const isSelected = this.isSelected(index + 1);
                  return (
                    <TableRow
                      hover
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={index + 1}
                      selected={isSelected}
                      className={classes.row}
                    >
                      <CustomTableCell component="th" scope="row" padding="none" >
                        <font >{n.id}</font>
                      </CustomTableCell>
                      <CustomTableCell ><font >{n.list}</font></CustomTableCell>
                      <CustomTableCell ><font >{n.type}</font></CustomTableCell>
                      <CustomTableCell ><font >{n.hour}</font></CustomTableCell>
                      <CustomTableCell ><font >{n.date}</font></CustomTableCell>
                      <CustomTableCell >
                        <Grid container direction="row" justify="center" alignItems="center">
                          <Button className={classes.button} onClick={() => this.onNext(n.idList, n.hour)} ><img src={boxicon} alt="" height='25' /> </Button>
                          <Button className={classes.button} onClick={() => this.onNextManul(n.idList)} > <img src={manualicon} alt="manualicon" height='25' /></Button>
                        </Grid>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Grid container direction="row" justify="center" alignItems="center">
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <ListCheckDialog idSubject={this.props.idSubject} dataList={n} />
                            <DeleteDialog onConfirmCallback={() => this.deleteList(n.idList, this.props.idSubject)}
                              textDelete="รายการเช็คชื่อของผู้ใช้งานที่เลือกจะถูกลบออกจากคลาสเรียนต้องการดำเนินการต่อหรือไม่?"
                            />
                          </div>
                        </Grid>
                      </CustomTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper >
    );

  }
}

Tablelistcheck.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

export default connect(mapStateToProps, {
  setPath,
  getListSubjects,
  delteSubjectList
})(withStyles(styles)(Tablelistcheck))
