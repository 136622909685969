import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import PropTypes from 'prop-types';
import './styledropdown.css';
import { tooltipStyle } from './style'
import { connect } from 'react-redux';
import {
  creactSubject,
  editSubject
} from '../../actions';

const styles = {
  green: {
    color: "#88DDBB",
    '&$checked': {
      color: "#88DDBB",
    },
  },
  lightgreen: {
    color: "#E1FD8E",
    '&$checked': {
      color: "#E1FD8E",
    },
  },
  orange: {
    color: "#FFBE7D",
    '&$checked': {
      color: "#FFBE7D",
    },
  },
  pink: {
    color: "#FDB4BF",
    '&$checked': {
      color: "#FDB4BF",
    },
  },
  lightblue: {
    color: "#88BBDD",
    '&$checked': {
      color: "#88BBDD",
    },
  },
  purple: {
    color: "#D0B3E1",
    '&$checked': {
      color: "#D0B3E1",
    },
  },
  checked: {},
  lightTooltip: {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: '0px 0px 7px -2px rgba(0,0,0,0.75)',
    fontSize: 16,
  },
};

//set date term year
var yearNow = (new Date().getFullYear()) + 543
var year = [];
var term = [];
for (let i = 0; i < 3; i++) {
  let ps = yearNow - i;
  let termNumber = ['1', '2', 'ฤดูร้อน']
  if (i !== 2) {
    year[i] = {
      value: ps,
      label: ps,
    }
  }
  term[i] = {
    value: termNumber[i],
    label: termNumber[i],
  }
}

const Custombutton = withStyles({
  root: {
    color: "white",
    fontFamily: "'Open Sans', sans-serif",

    fontSize: '0.6cm'
  }
})(Button);

class Createclassdialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      code: '',
      name: '',
      group: '',
      year: yearNow,
      term: 1,
      hour: '45',
      misshour: '9',
      color: '#88DDBB',
      nameTable: 'สร้างรายวิชา'
    }
  }

  componentDidMount() {
    if (this.props.statusCreate) {
      this.setState({
        nameTable: 'แก้ไขรายวิชา',
        open: false,
        code: this.props.subjectCode,
        name: this.props.subjectName,
        group: this.props.subjectGroup,
        year: this.props.year,
        term: this.props.subjectTerm,
        hour: '45',
        misshour: '9',
        color: this.props.subjectHeadcolor,
      })
    }
  }

  handleChange = event => {
    this.setState({ color: event.target.value });
  };

  handleClickOpenOrClose = (check) => {
    this.setState({ open: check });
    if (!check) {
      this.handleReset()
      if(this.props.statusCreate){
        this.props.closeDialog()
      }
    }
  };

  handleSetState = (value) => event => {
    this.setState({
      [value]: event.target.value,
    })
  }

  handleReset = () => {
    this.setState({
      open: false,
      code: '',
      name: '',
      group: '',
      year: yearNow,
      term: '1',
      hour: '45',
      color: '#88DDBB',
    })
  }

  handle() {
    if ((this.state.name && this.state.code && this.state.group && this.state.term && this.state.year && this.state.color) === '') {
      console.log('not vale')
    } else {
      this.handleClickOpenOrClose(false)
      if (this.props.statusCreate) {
        this.props.editSubject(this.props.idSubject, this.state.name, this.state.code, this.state.group, this.state.term, this.state.year, this.state.hour, this.state.color)
      } else {
        this.props.creactSubject(this.state.name, this.state.code, this.state.group, this.state.term, this.state.year, this.state.hour, this.state.color)
      }
      this.handleReset()
      if(this.props.statusCreate){
        this.props.closeDialog()
      }
    }
  }

  render() {
    const { classes } = this.props;
    const addstyle = {
      marginTop: '5.5vh',
    }
    const headerstyle = {
      backgroundColor: '#14a37f'
    }
    const button = {
      textSize: 25,
      backgroundColor: '#14a37f'
    }
    const tabcolor = {
      backgroundColor: '#263238'
    }
    const fontStyle = {
      fontSize: '1.8vh',
      width: '1vw'
    }


    return (
      <div style={{ width: '100%' }}>
        
          {
            this.props.statusCreate ?
              <div onClick={() => this.handleClickOpenOrClose(true)} >
                <font style={fontStyle}>แก้ไข </font>
              </div>
              :
              <Tooltip
                disableFocusListener disableTouchListener
                title="สร้างรายวิชา"
                classes={{ tooltip: classes.lightTooltip }}
                aria-label="Add" >
                <Fab color="default" style={addstyle} onClick={() => this.handleClickOpenOrClose(true)} >
                  <AddIcon />
                </Fab>
              </Tooltip>
          }
        <Dialog
          open={this.state.open}
          onClose={() => this.handleClickOpenOrClose(false)}
          aria-labelledby="form-dialog-CreateClass"
        >
          <DialogTitle id="form-dialog-Class" style={headerstyle}><font className="TopColor">{this.state.nameTable}</font></DialogTitle><br />
          <DialogContent>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <font>รหัสรายวิชา</font>
                <TextField
                  id="idclass"
                  value={this.state.code}
                  onChange={this.handleSetState('code')}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <font>ชื่อรายวิชา</font>
                <TextField
                  id="outlined-name"
                  value={this.state.name}
                  onChange={this.handleSetState('name')}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <font>ปีการศึกษา</font>
                <TextField
                  id="year"
                  select
                  value={this.state.year}
                  onChange={this.handleSetState('year')}
                  variant="outlined"
                  fullWidth
                >
                  {year.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <font>ภาคเรียน</font>
                <TextField
                  id="term"
                  select
                  value={this.state.term}
                  onChange={this.handleSetState('term')}
                  variant="outlined"
                  fullWidth
                >
                  {term.map(option => (
                    <MenuItem key={option.value} value={option.value} >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <font>กลุ่ม</font>
                <TextField
                  id="group"
                  value={this.state.group}
                  onChange={this.handleSetState('group')}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <font>จำนวนชั่วโมงเรียนทั้งหมด</font>
                <TextField
                  id="hour"
                  value={this.state.hour}
                  onChange={this.handleSetState('hour')}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <font>สี</font>
                <div style={tabcolor}>
                  <Radio
                    checked={this.state.color === '#88DDBB'}
                    onChange={this.handleChange}
                    value="#88DDBB"
                    name="greenbutton"
                    aria-label="green"
                    classes={{
                      root: classes.green,
                      checked: classes.checked,
                    }}
                  />
                  <Radio
                    checked={this.state.color === '#E1FD8E '}
                    onChange={this.handleChange}
                    value="#E1FD8E "
                    name="lightgreenbutton"
                    aria-label="lightgreen"
                    classes={{
                      root: classes.lightgreen,
                      checked: classes.checked,
                    }}
                  />
                  <Radio
                    checked={this.state.color === '#FFBE7D'}
                    onChange={this.handleChange}
                    value="#FFBE7D"
                    name="orangebutton"
                    aria-label="orange"
                    classes={{
                      root: classes.orange,
                      checked: classes.checked,
                    }}
                  />
                  <Radio
                    checked={this.state.color === '#FDB4BF'}
                    onChange={this.handleChange}
                    value="#FDB4BF"
                    name="pinkbutton"
                    aria-label="pink"
                    classes={{
                      root: classes.pink,
                      checked: classes.checked,
                    }}
                  />
                  <Radio
                    checked={this.state.color === '#88BBDD'}
                    onChange={this.handleChange}
                    value="#88BBDD"
                    name="lightbluebutton"
                    aria-label="lightblue"
                    classes={{
                      root: classes.lightblue,
                      checked: classes.checked,
                    }}
                  />
                  <Radio
                    checked={this.state.color === '#D0B3E1'}
                    onChange={this.handleChange}
                    value="#D0B3E1"
                    name="purplebutton"
                    aria-label="purple"
                    classes={{
                      root: classes.purple,
                      checked: classes.checked,
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Grid item xs={12}>
              <Custombutton variant="contained" style={button} onClick={() => this.handle()} fullWidth>
                <font>ยืนยัน</font>
              </Custombutton>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

Createclassdialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

export default connect(mapStateToProps, {
  creactSubject,
  editSubject
})(withStyles(styles, tooltipStyle)(Createclassdialog))