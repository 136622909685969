import React, { Component } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import RegisterRFID from './RegisterRFID';
import * as Services from '../../service/UserServices';

class ConfirmRegisterRFID extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            dialogStatus: false
        }
    }
    async  componentDidMount() {
        await this.setState({
            open: this.props.dialogStatus,
        })

    }
    async componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.dialogStatus !== this.props.dialogStatus) {
            await this.setState({
                open: nextProps.dialogStatus,
            })
        }

    }

    getDatas = async (status, edit) => {
        await Services.RegisterRFID(status, edit)

    }
    confirm = async () => {
        this.getDatas(true, true)
        this.setState({
            dialogStatus: true 
        })
        this.handleClose();
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.props.handleCloseDialogConfirm()
    }

    handleCloseDialog = () => {
        this.setState({ dialogStatus: false });
        this.getDatas(false,true)
    };

    render() {
        return (
            <div className="App">
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                >
                    <DialogTitle id="draggable-dialog-title"><font>ยืนยันการลงทะเบียน</font></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <font style={{ fontSize: '22px' }}>ท่านได้มีการลงทะเบียนแล้วต้องการลงทะเบียนซ้ำหรือไม่?</font>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions >
                        <Button onClick={this.handleClose}
                            style={{
                                color: '#f1f8e9',
                                backgroundColor: '#ef5350'
                            }}
                            variant="contained">
                            <font> ยกเลิก </font>
                        </Button>
                        <Button onClick={this.confirm} color="primary" autoFocus variant="contained">
                            <font> ยืนยัน </font>
                        </Button>
                    </DialogActions>
                </Dialog>
                <RegisterRFID dialogStatus={this.state.dialogStatus} handleCloseDialog={this.handleCloseDialog} />

            </div>
        )
    }
}

ConfirmRegisterRFID.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default ConfirmRegisterRFID