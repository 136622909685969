import {url} from '../url';

export const getReport = (idclass) => {
    return fetch( url+'/classCheckList/report?id='+ idclass, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('id_token')
        }
      }).then(response => response.json())
        .then(responseJson => {
          return responseJson
        })
}


export const getReportInTime = (idList) => {
  return fetch( url+'/classCheckList/reportInTime?id='+ idList, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('id_token')
      }
    }).then(response => response.json())
      .then(responseJson => {
        
        return responseJson
      })
}

export const getReportLateTime = (idList) => {
  return fetch( url+'/classCheckList/reportLateTime?id='+ idList, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('id_token')
      }
    }).then(response => response.json())
      .then(responseJson => {
        
        return responseJson
      })
}


export const getReportOutTime = (idList) => {
  return fetch( url+'/classCheckList/reportOutTime?id='+ idList, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('id_token')
      }
    }).then(response => response.json())
      .then(responseJson => {
        
        return responseJson
      })
}