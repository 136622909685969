import React from 'react'
import { connect } from 'react-redux';
import history from '../../history';
import Tab from './tab';
import Loading from '../Loading';
import {
    setPath,
    getReportForStatus
  } from '../../actions';

class ReportAll extends React.Component {
  componentDidMount() {        
    const paths = [
      {
        name: 'รายวิชา ',
        func: () => history.push({
          pathname: '/dashboard',
        })
      },
      {
        name: ` /รายงานการเช็คชื่อวิชา ${this.props.location.state.routeName}`,
        func: () => history.push({
          pathname: '/Reportcheck',
          state:{
            idSubject: this.props.location.state.idSubject,
            routeName: this.props.location.state.routeName
            },
        })
      },
      {
        name: '/ผลการเช็คชื่อทั้งหมด ',
        func: () => history.push({
          pathname: '/ReportAll',
          state:{
            idSubject:this.props.location.state.idSubject,
            routeName:this.props.location.state.routeName
          },
        })
      },
    ]
    this.props.setPath(paths)
    this.props.getReportForStatus(this.props.location.state.idlist)
  }

 

    render(){
      if(this.props.report.loading){
        return <Loading/>
      }
        return(
            <div style={{marginTop:'10px'}}>
          <Tab report={this.props.report.reportAll} dateList={this.props.location.state.dateList}/>
          
          {/* <Tab/> */}
            </div>
            
        )
    }
}

const mapStateToProps = (state) => {
    return {
      ...state,
    }
  }

export default connect(mapStateToProps, {
    setPath,
    getReportForStatus
  })(ReportAll)