import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddEstimots from '../Dialog/AddEstimotes';
import AddEddystones from '../Dialog/AddEddystones';
import DropDown from '../../icon/arrow_drop_down.png';
import Typography from '@material-ui/core/Typography';



class MenuBeacon extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      open: false,
      dialogStatus: false,
      dialogEddystone: false

    };
  };


  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  //dialogestimote
  handleCloseDialog = () => {
    this.setState({ dialogStatus: false });
  };

  addEstimote = () => {
    this.handleClose()
    this.setState({ dialogStatus: true })
  }
  //dialogeddystone
  handleCloseDialogEddystone = () => {
    this.setState({ dialogEddystone: false });
  };
  addEddystones = () => {
    this.handleClose()
    this.setState({ dialogEddystone: true })
  }


  render() {

    const {
      anchorEl,
      dialogStatus,
      dialogEddystone
    } = this.state;
    return (
      <div>
        <div>
          <Button
            style={{ backgroundColor: '#279c66' }}
            aria-owns={anchorEl ? 'simple-menu' : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <font style={{ paddingLeft: '0.5vw' ,color: 'white'}}>จัดการ Beacons</font>
            <img src={DropDown} alt={"DropDown"} />
          </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
        >
              <MenuItem onClick={this.addEstimote} style={{ justifyContent: 'center' ,width: 140}}>
              <Typography style={{fontSize: '1.8vh' ,padding: '2%' , height: '2.5vh'}}>estimote</Typography>
              </MenuItem>
              <MenuItem onClick={this.addEddystones} style={{ justifyContent: 'center',width: 140 }}>
              <Typography style={{fontSize: '1.8vh' ,padding: '2%' , height: '2.5vh'}}>eddystone</Typography>
              </MenuItem>
            </Menu>
        </div>
        <AddEstimots dialog={dialogStatus} handleCloseDialog={this.handleCloseDialog} />
        <AddEddystones dialogEddystone={dialogEddystone} handleCloseDialog={this.handleCloseDialogEddystone} />
      </div>

    );
  }
}


export default MenuBeacon