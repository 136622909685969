import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { root, Gridstyle, paper, img, styles, defaultStatus } from './style'
import '../../App.css'
import {
  getListName,
  getStudentRegisterRFID
} from '../../actions';

class GridProfile extends React.Component {
  render() {
    return (
      <div style={root}>
        <Paper style={paper} elevation={5}>
          <Grid container direction="row" justify="space-evenly" alignItems="center" style={Gridstyle}>
            <Grid>
              <Button style={defaultStatus} variant="contained">
                <div >
                  <img style={img}
                    alt="complex"
                    border={5}
                    // borderColor="red"
                    src={this.props.image} />
                </div>
              </Button>
            </Grid>
            <Grid container item xs={6} direction="column" alignItems="center" justify="flex-start">

              <div
                style={{
                  display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
                  marginTop: '5%',
                  marginBottom: '8%',
                  backgroundColor: '#3495EC',
                  boxShadow: '0px 0px 0px 2px #3495EC'
                }}
              >
                <ion-icon name="search"
                  style={{
                    fontSize: "2.5vw",
                    height: '3vw',
                    width: '3vw',
                    color: 'white',
                    backgroundColor: '#3495EC'
                  }} />
                <input
                  placeholder=" ค้นหาด้วยรหัสนิสิต"
                  onChange={(event) => this.props.serachStudnet(event.target.value)}
                  pattern="[0-9]*"
                  style={{
                    backgroundColor: 'white',
                    height: '2.5vw',
                    width: '15vw',
                    fontSize: '1.6vh'
                  }}
                />
              </div>
              <div>
              <font style={{ fontSize: '2.5vh', display: 'flex', flexDirection: 'row' ,marginBottom: '2.5%' }} >รหัสนิสิต : {this.props.idStudnet} </font>
              <font style={{ fontSize: '2.5vh', display: 'flex', flexDirection: 'row', marginBottom: '2.5%' }}>ชื่อ : {this.props.name} {this.props.lastname}</font>
              <font style={{ fontSize: '2.5vh', marginBottom: '2.5%'}} >สถานะ : {this.props.rfid !== null ? 'ลงทะเบียนแล้ว' : 'ยังไม่ลงทะเบียน'}</font>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

export default connect(mapStateToProps, {
  getListName,
  getStudentRegisterRFID
})(withStyles(styles)(GridProfile))

