import React from 'react'
import DropDown from '../DropDown';
import {styles} from './style';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import personicon from '../../icon/icon-person.png';
import CodeLineDialog from '../Dialog/CodeLineDialog';
import ListCheckDialog from '../Dialog/ListCheckDialog';
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from 'react-socks';
import history from '../../history'
import { connect } from 'react-redux';
import {
    setPath,
  } from '../../actions';

setDefaultBreakpoints([
    { xsmall: 0 }, // all mobile devices
    { small: 600 }, // mobile devices (not sure which one's this big)
    { medium: 630 }, // ipad, ipad pro, ipad mini, etc
  ]);
  class HeadPaper extends React.Component {
    linkmanage = (id) => {
      history.push({
        pathname: '/Manage',
        state: { routeName: this.props.routeName, idSubject: id }
      });
    }

    // componentWillReceiveProps(nextProps){
    //   if(this.props.subjects !== nextProps.subjects){
    //   // let dataforLine =  nextProps.subjects.map(row=>{
    //   //     return row._id === this.props.idSubject
    //   //   })
    //   }
    // }

      render(){
        const { classes } = this.props;
        const manageicon = {marginRight: 2 }
        return (
          <BreakpointProvider>

            <div>
              <Breakpoint small down>
                <div className={classes.headerpaper}>
                  <Grid item xs={12} style={{marginTop: '1.5vh' }} >
                    <Grid container >
                      <Grid item xs={6} container direction="column" justify="space-evenly" alignItems="baseline">
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' , alignItems:' baseline'}}>
                        <font className={classes.fontstyle}>ประเภท</font>
                        <DropDown />
                        </div>
                      </Grid>
                      <Grid item xs={6} container direction="column" justify="flex-end" alignItems="flex-end" >
                      <div style={{top: '10vh', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' , alignItems:' flex-end'}}>
                     
                        <CodeLineDialog idSubject={this.props.idSubject} />

                        <Button variant="contained" className={classes.buttontopMB} onClick={() => this.linkmanage(this.props.idSubject)}>
                          <img src={personicon} alt="personicon" height='25vh' style={manageicon} />
                          <font style={{ fontSize: '1.2vh  '}}> จัดการรายชื่อ  </font>
                        </Button>
                        {/* create subject dialog */}
                        <ListCheckDialog idSubject = {this.props.idSubject} statusCreateList = {true} />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Breakpoint>
              <Breakpoint medium up>
                <div className={classes.headerpaper}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} sm={3} md={6} container direction="column" justify="center" alignItems="flex-start">
                        <font className={classes.fontstyle}>ประเภท</font>
                        <DropDown />
                      </Grid>
                      <Grid item xs={6} sm={9} md={6} alignItems="flex-end" justify="flex-end" container>
                        <CodeLineDialog idSubject={this.props.idSubject} />
                        <Button 
                          variant="contained" 
                          className={classes.buttontop} 
                          onClick={() => this.linkmanage(this.props.idSubject)}
                        >
                          <img src={personicon} alt="" height='20vh' style={manageicon} />
                          <font style={{ fontSize: '1.2vh  '}}> จัดการรายชื่อ  </font>
                        </Button>
                        {/* create subject dialog */}
                        <ListCheckDialog idSubject = {this.props.idSubject} statusCreateList = {true}/>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Breakpoint>
            </div>
          </BreakpointProvider>
          
          );
      }
  };


  const mapStateToProps = (state) => {
    return {
      ...state,
    }
  }

export default connect(mapStateToProps, {
  setPath
})(withStyles(styles)(HeadPaper))

