import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';

const CustomTableCellbody = withStyles({
    root: {
      fontSize: "90%",
      fontFamily: "'Prompt', sans-serif",
      color: "black",
      textAlign: 'center',
    }
  })(TableCell);

  const styles = theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing.unit * 1,
      marginLeft: '1%',
      marginRight: '1%'
    },
    table: {
      minWidth: '50%',
      width: '100%',
  
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    spacer: {
      flex: '1 1 100%',
    },
    actions: {
      color: theme.palette.text.secondary,
    },
    title: {
      flex: '0 0 auto',
    },
    papersearch: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '90%',
      height: 30,
    },
    progress: {
      margin: theme.spacing.unit * 20,
    },

  });

  const stylesHead = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 1,
        overflowX: 'auto',
    },
    container: {
        display: 'flex',
        flex: '1 0 auto',
        width: '100%',
        fontSize: '100%'
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        flex: '1 0 auto',
        margin: theme.spacing.unit,
    },
    tabTop: {
        width: '100%',
        marginTop: theme.spacing.unit * 1,
        overflowX: 'auto',
    },
    TabLeft: {
        fontSize: '100%'
    },
    TabRight: {
        marginTop: '1%',
        marginRight: '1%',
        fontSize: '20%',
    },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
    iconsearchbutton: {
        padding: 10,
    },
    input: {
        marginLeft: '1%',
        flex: 1,
        fontFamily: "'Prompt', sans-serif",
    },
    papersearch: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '25%',
        minWidth: 150,
        height: '60%',
        marginBottom: theme.spacing.unit,
    },
    widthFull: {
        width: '100%'
    },
    tablesetup: {
        overflowX: 'auto',
        marginTop: '-0.5%',
        margin: theme.spacing.unit * 2,
    },
    headerpaper: {
        overflowX: 'auto',
        marginTop: '0.3%',
        margin: theme.spacing.unit * 2,

    },
    lightTooltip: {
      backgroundColor: 'white',
      // width: 50,
      // marginRight: 50
      marginRight: '2vw'
      // marginRight: '50px'

      // color: 'rgba(0, 0, 0, 0.87)',
      // boxShadow: theme.shadows[1],
      // fontSize: 11,
    },

});

  export {CustomTableCellbody,styles,stylesHead}