import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { CustomTableCellbody, styles } from './style';
import * as BeaconsServices from '../../service/BeaconsServices';
import EditEddystone from '../Dialog/EditEddystoneDialog';
import EditEstimote from '../Dialog/EditEstimoteDialog';
import DeleteBeacon from '../Dialog/DeleteDialog';
import TableBeaconsHead from '../EnhancedTableHead';

function createData(no, name, type, uuid, major, minor, address, idBecons) {
  return { no, name, type, uuid, major, minor, address, idBecons };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'no', numeric: true, disablePadding: true, label: 'ลำดับ' },
  { id: 'name', numeric: false, disablePadding: false, label: 'ชื่อ' },
  { id: 'type', numeric: false, disablePadding: false, label: 'type' },
  { id: 'uuid', numeric: false, disablePadding: false, label: 'uuid' },
  { id: 'major', numeric: false, disablePadding: false, label: 'major' },
  { id: 'minor', numeric: false, disablePadding: false, label: 'minor' },
  { id: 'address', numeric: false, disablePadding: false, label: 'mac address' },
  { id: 'tool', numeric: false, disablePadding: false, label: 'เครื่องมือ' },

];


class EnhancedTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'no',
    selected: [],
    data: [],
    page: 0,
    rowsPerPage: 10,
    beacons: [],
  };
  async componentDidMount() {
    this.renderBeacons()
  }
  renderBeacons = async () => {
    const datas = await BeaconsServices.getBeacons()
    this.setState({ beacons: datas })
    this.setState({
      beacons: datas,
      data:
        datas.map((datas, index) => {
          return createData(index + 1, datas.name, datas.type, datas.uuid, datas.major, datas.minor, datas.macAddress, datas._id)
        })
    })
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    }
    else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  confirmDelete = async (beaconId) => {
    await BeaconsServices.deleteBeacons(beaconId)
    this.renderBeacons()
  }

  render() {

    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page, beacons } = this.state;
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    if (!beacons) {
      return null
    }

    return (
      <Grid style={{ backgroundColor: 'white', width: '100%', }}>
        <Paper style={{ marginLeft: '0.5vw', marginRight: '0.5vw', marginTop: '1vh' }}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <TableBeaconsHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
                rows={rows}

              />
              <TableBody>
                {stableSort(data, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((n, index) => {
                    const isSelected = this.isSelected(n.id);
                    return (
                      <TableRow
                        key={index}
                        hover
                        onClick={event => this.handleClick(event, n.id)}
                        // role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        selected={isSelected}
                        className={classes.row}
                      >
                        <CustomTableCellbody align="center" style={{fontSize: '1.7vh'}}> {n.no}</CustomTableCellbody>
                        <CustomTableCellbody align="center" style={{fontSize: '1.7vh'}}>{n.name}</CustomTableCellbody>
                        <CustomTableCellbody align="center" style={{fontSize: '1.7vh'}}>{n.type}</CustomTableCellbody>
                        <CustomTableCellbody align="center" style={{fontSize: '1.7vh'}}>{n.uuid}</CustomTableCellbody>
                        <CustomTableCellbody align="center" style={{fontSize: '1.7vh'}}>{n.major}</CustomTableCellbody>
                        <CustomTableCellbody align="center" style={{fontSize: '1.7vh'}}>{n.minor}</CustomTableCellbody>
                        <CustomTableCellbody align="center" style={{fontSize: '1.7vh'}}>{n.address}</CustomTableCellbody>
                        <CustomTableCellbody align="center" >
                          <Grid container direction="row" justify="center" alignItems="center" >
                            {n.type === 'eddystone' ?
                              <EditEddystone nameEdit={n.name} addressEdit={n.address} renderBeacons={this.renderBeacons} beaconID={n.idBecons} />
                              : <EditEstimote nameEdit={n.name} uuidEdit={n.uuid} majorEdit={n.major} minorEdit={n.minor} renderBeacons={this.renderBeacons} beaconID={n.idBecons} />}
                            <DeleteBeacon onConfirmCallback={() => this.confirmDelete(n.idBecons)} textDelete="Beacons ที่เลือกจะถูกลบออกจากระบบต้องการดำเนินการต่อหรือไม่?"/>
                          </Grid>
                        </CustomTableCellbody>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable);