import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Image from './image'
import history from '../../history'
import Table from './Table'
import { styleIndex } from './style'
import { connect } from 'react-redux';
import imagGuide from '../../icon/guide.png'
import Backward from '../../icon/backward.png'
import Next from '../../icon/next.png'


import {
  setPath,
  statusRegister,
  getStudentRegisterRFID,
  focusStudent,
  setDataStudnet,
  delteStudentSubject,
  getProfile
} from '../../actions';
import io from 'socket.io-client';
import { urlIO } from '../../url'
import Pic from '../../icon/blank_profile.png'
const socket = io.connect(urlIO);

function createData(rank, idstu, nameset, lastname, group, rfid, _id) {
  let name = nameset + ' ' + lastname
  return { rank, idstu, name, group, rfid, _id };
}



class registerRFID extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkedA: false,
      idStudnet: '',
      name: '',
      lastname: '',
      image: Pic,
      time: '',
      stduentRow: [],
      serachStu: '',
      open: false,
      defaultData: [],
      loading: false,
      rfid: '',
      statusBox: 'ไม่พร้อมใช้งาน',
      boxId: 'กล่องไม่พร้อมใช้งาน',
    };
    this.props.getStudentRegisterRFID(this.props.location.state.idSubject)
    this.subscribeServer((err, data) => {
      this.props.setDataStudnet(data)
      this.dataSetState(data)
    })

    // this.subscribeServerDB((err, data) => {
    //   if (data) {
    //     this.setState({
    //       statusBox: 'พร้อมใช้งาน',
    //       boxId: data.BoxId
    //     })
    //   } else {
    //     this.setState({
    //       statusBox: 'กล่องไม่พร้อมใช้งาน',
    //       boxId: 'กล่องไม่พร้อมใช้งาน'
    //     })
    //   }
    // })

    this.handleChange()
    this.props.getProfile()
  };
  componentDidMount() {
    const paths = [
      {
        name: 'รายวิชา ',
        func: () => history.push({
          pathname: '/dashboard',
        })
      },
      {
        name: ` /${this.props.location.state.routeName}`,
        func: () => history.push({
          pathname: '/NameChecklist',
          state: {
            idSubject: this.props.location.state.idSubject,
            routeName: this.props.location.state.routeName
          },
        })
      },
      {
        name: '/จัดการรายชื่อ ',
        func: () => history.push({
          pathname: '/Manage',
          state: {
            idSubject: this.props.location.state.idSubject,
            routeName: this.props.location.state.routeName
          },
        })
      },
      {
        name: '/ลงทะเบียนบัตร ',
        func: () => history.push({
          pathname: '/registerRFID',
          state: {
            idSubject: this.props.location.state.idSubject,
            routeName: this.props.location.state.routeName
          },
        })
      },
    ]
    this.props.setPath(paths)
    window.onbeforeunload = () => { this.props.statusRegister(this.props.location.state.idSubject, false) };
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.student !== this.props.student) {
      await this.dataSetState(nextProps.student)
    }
  }

  confirmDelete = async (idStu) => {
    await this.props.delteStudentSubject(idStu, this.props.location.state.idSubject, true)
  }

  subscribeServer(cb) {
    socket.on('register', data => {
      cb(null, data);
    })
  }

  subscribeServerDB(cb) {
    socket.on('statusBox', data => {
      cb(null, data);
    })
  }

  dataSetState = async (data,registerEditStatus) => {
    if (data.length > 0) {
      let index = 0;
      let setData = await data.map((row, index) => {
        return createData(index + 1, row.userNotRFID.studentId, row.userNotRFID.name, row.userNotRFID.lastName, row.group, row.userNotRFID.RFID, row.userNotRFID._id)
      })
      await this.setState({
        idStudnet: data[index].userNotRFID.studentId,
        name: data[index].userNotRFID.name,
        lastname: data[index].userNotRFID.lastName,
        image: 'https://reg.buu.ac.th/registrar/getstudentimage.asp?id=' + data[index].userNotRFID.studentId,
        stduentRow: setData,
        loading: false,
        rfid: data[index].userNotRFID.RFID
      })
      this.props.focusStudent(data[index].userNotRFID._id,registerEditStatus)
      window.onbeforeunload = async() => { await this.props.focusStudent(data[index].userNotRFID._id,false) }
    } else {
      await this.setState({
        idStudnet: '',
        name: '',
        lastname: '',
        image: Pic,
        status: '',
        time: '',
        stduentRow: []
      })
    }
  }

  handleChange = () => {
    this.props.statusRegister(this.props.location.state.idSubject, true)
  };

  connectArray = async () => {
    let shift = await this.props.student.shift()
    this.props.student.push(shift)
    this.props.setDataStudnet(this.props.student)
    this.dataSetState(this.props.student)
  }

  backwardArray = () => {
    let shift = this.props.student.pop()
    this.props.student.unshift(shift)
    this.props.setDataStudnet(this.props.student)
    this.dataSetState(this.props.student)
  }

  focus = async (data,registerEditStatus) => {
    let moveArray = await this.props.student.splice(data.rank - 1, 1)
    await this.props.student.unshift(moveArray[0])
    await this.props.setDataStudnet(this.props.student)
    await this.dataSetState(this.props.student,registerEditStatus)
  }

  serachStudnet = (data) => {
    let obj = this.props.main.defaultStudnet.filter(row => {
      return row.userNotRFID.studentId.indexOf(data) > -1
    })
    if (obj.length < 1) {
      // this.props.getStudentRegisterRFID(this.props.location.state.idSubject)
      this.dataSetState(this.props.main.defaultStudnet)
    }
    else if (data !== '' || obj.length === 0) {
      this.props.setDataStudnet(obj)
      this.dataSetState(obj)
    }
  }

  handleTooltipClose = () => {
    this.setState({ open: false });
  };

  handleTooltipOpen = () => {
    this.setState({ open: true });
  };


  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <Grid item xs={12} className={classes.gridtop}>
          <Grid container >
            <Grid className={classes.gridlefttype}>
              <div >
                {/* <font style={{ fontSize: '2.2vh' }}>หมายเลขอุปกรณ์: {this.state.boxId}</font><br />
                <font style={{ fontSize: '2.2vh' }}>สถานะอุปกรณ์: {this.state.statusBox}</font> */}
              </div>
            </Grid>
            <Grid className={classes.gridrighttype}>
              <Tooltip
                open={this.state.open}
                onClose={this.handleTooltipClose}
                title={
                  <img src={imagGuide} alt="alerRegister" height="100%" width="100%" />
                }
                classes={{ tooltip: classes.lightTooltip }}
                placement="bottom-end"
              >
                <Button onTouchEnd={this.handleTooltipOpen} onMouseOver={this.handleTooltipOpen}>
                  <ion-icon name="help-circle" color="primary" style={{ fontSize: '30px' }} ></ion-icon>
                </Button>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid container>
            <Grid className={classes.gridbottomRighttype}>
              <Tooltip
                title="รายชื่อก่อนหน้า"
                placement="bottom"
                classes={{ tooltip: classes.lightTooltip }}
              >
                <Button style={{ borderRadius: '50%' }} onClick={this.backwardArray}>
                  <img src={Backward} alt="Backward" style={{ width: '5.5vh' }} />
                </Button>
              </Tooltip>
            </Grid>
            <Grid>
              <Image
                idStudnet={this.state.idStudnet}
                name={this.state.name}
                lastname={this.state.lastname}
                image={this.state.image}
                time={this.state.time}
                serachStudnet={(data) => { this.serachStudnet(data) }}
                rfid={this.state.rfid}
              />
            </Grid>
            <Grid className={classes.gridbottomLeftttype}>
              <Tooltip
                title="รายชื่อถัดไป"
                placement="bottom"
                classes={{ tooltip: classes.lightTooltip }}
              >
                <Button style={{ borderRadius: '50%' }} onClick={this.connectArray}>
                  <img src={Next} alt="Next" style={{ width: '5.5vh' }} />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid >
            <Table stduentRow={this.state.stduentRow}
              dataSetState={(data) => this.dataSetState(data)}
              focus={(data,registerEditStatus) => this.focus(data,registerEditStatus)}
              idSubject={this.props.location.state.idSubject}
              confirmDelete={(data) => this.confirmDelete(data)}
            />
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

registerRFID.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

export default connect(mapStateToProps, {
  setPath,
  statusRegister,
  getStudentRegisterRFID,
  focusStudent,
  setDataStudnet,
  delteStudentSubject,
  getProfile
})(withStyles(styleIndex)(registerRFID))
