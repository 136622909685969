import { RSAA  } from 'redux-api-middleware';
import { 
    SUBJECT_GET_FAILURE,
    SUBJECT_GET_REQUEST,
    SUBJECT_GET_SUCCESS,
    SUBJECT_UPLOAD_FAILURE,
    SUBJECT_UPLOAD_REQUEST,
    SUBJECT_UPLOAD_SUCCESS,
    SUBJECT_DELETE_FAILURE,
    SUBJECT_DELETE_REQUEST,
    SUBJECT_DELETE_SUCCESS,
    SUBJECT_EDIT_FAILURE,
    SUBJECT_EDIT_REQUEST,
    SUBJECT_EDIT_SUCCESS,
    SUBJECT_REGIS_RFID_REQUEST,
    SUBJECT_REGIS_RFID_FAILURE,
    SUBJECT_REGIS_RFID_SUCCESS,
    SUBJECT_GET_TERM_REQUEST,
    SUBJECT_GET_TERM_SUCCESS,
    SUBJECT_GET_TERM_FAILURE,
    SUBJECT_SET,
    SUBJECT_GROUP_SET
} from './Types';
import {url} from '../url';


export const getSubjects = () => {
 
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/class`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                types: [
                    SUBJECT_GET_REQUEST,
                    {
                        type: SUBJECT_GET_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            return response;
                        }
                    },
                    SUBJECT_GET_FAILURE
                ]
            },
        })
    }
   
}

export const creactSubject = (className,classCode,section,term,year,totalHour,colorCode) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/class/create`,
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                body: JSON.stringify({
                    className,
                    classCode,
                    section,
                    term,
                    year,
                    totalHour,
                    colorCode
                }),
                
                types: [
                    SUBJECT_UPLOAD_REQUEST,
                    {
                        type: SUBJECT_UPLOAD_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            dispatch(getSubjects())
                            return response;
                        }
                    },
                    SUBJECT_UPLOAD_FAILURE
                ]
            },
        })
    }
}


export const deleteSubject = (id) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/class/delete`,
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                body: JSON.stringify({
                    id
                }),
                types: [
                    SUBJECT_DELETE_REQUEST,
                    {
                        type: SUBJECT_DELETE_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            dispatch(getSubjects())
                            return response;
                        }
                    },
                    SUBJECT_DELETE_FAILURE
                ]
            },
        })
    }
}

export const editSubject = (_id,className,classCode,section,term,year,totalHour,colorCode) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/class/editclass`,
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                body: JSON.stringify({
                    _id,
                    className,
                    classCode,
                    section,
                    term,
                    year,
                    totalHour,
                    colorCode
                }),
                
                types: [
                    SUBJECT_EDIT_REQUEST,
                    {
                        type: SUBJECT_EDIT_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            dispatch(getSubjects())
                            return response;
                        }
                    },
                    SUBJECT_EDIT_FAILURE
                ]
            },
        })
    }
}

export const statusRegister = (subjectID,registerStatus) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/class/registerON`,
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                body: JSON.stringify({
                    subjectID,
                    registerStatus
                }),
                
                types: [
                    SUBJECT_REGIS_RFID_REQUEST,
                    {
                        type: SUBJECT_REGIS_RFID_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            dispatch(getSubjects())
                            return response;
                        }
                    },
                    SUBJECT_REGIS_RFID_FAILURE
                ]
            },
        })
    }
}


export const getYear = (userId) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${url}/class/getYear`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('id_token')
                },
                types: [
                    SUBJECT_GET_TERM_REQUEST,
                    {
                        type: SUBJECT_GET_TERM_SUCCESS,
                        payload: async (action, state, res) => {
                            const response = await res.json();
                            return response;
                        }
                    },
                    SUBJECT_GET_TERM_FAILURE
                ]
            },
        })
    }
   
}

export const setDataSubject = (arr) => {
    return (dispatch) => {
        dispatch({
            type: SUBJECT_SET,
            payload: arr
        })
   
    }
}

export const setDataGroup = (arr) => {
    return (dispatch) => {
        dispatch({
            type: SUBJECT_GROUP_SET,
            payload: arr
        })
   
    }
}