import React from 'react'
import { connect } from 'react-redux';
import Header from './header';
import TableBeacons from './tableBeacons';
import history from '../../history';
import {
    setPath,
} from '../../actions';


class ManageBeacon extends React.Component {
    async componentDidMount() {

        const paths = [
            {
                name: 'รายวิชา ',
                func: () => history.push({
                    pathname: '/dashboard',
                })
            },
            {
                name: '/ลงทะเบียน BEACONS ',
                func: () => history.push({
                    pathname: '/ManageBeacon',
                    state: {
                        idSubject:this.props.location.state.idSubject,
                        // routeName: this.state.routeName
                    },
                })
            },

        ]
        this.props.setPath(paths)
    }
    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    handleCloseDialog = () => {
        this.setState({ dialogStatus: false });
    };

    render() {
        return (
            <div>
                <Header />
                <TableBeacons/>
            </div>

        )
    }
}




const mapStateToProps = (state) => {
    return {
        ...state,
    }
}

export default connect(mapStateToProps, {
    setPath
})(ManageBeacon)
