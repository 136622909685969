import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const CustomTypography = withStyles({
    root: {
        color: "black",
        fontFamily: "'Prompt', sans-serif",
        fontSize: '2.0vh',
        textAlign: 'left',
    },
})(Typography);

const CustomCardHeader = withStyles({
    root: {
        textAlign: 'left',
        fontSize: '2.1vh',
        fontFamily: "'Prompt', sans-serif",
    }
})(Typography);

const CustomCard = withStyles({
    root: {
        maxWidth: '100%',
        maxHeight: '20%',
        width: '100vw',
        // height: '20vh',
        marginLeft: '1%',
        marginTop: '3%',
        marginRight: '1%',
        overflow: 'hidden',

    }
})(Card);

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginRight: '1%',
        padding: `0 ${theme.spacing.unit * 1}px`,
        cursor: 'pointer'
    },
    lightTooltip: {
        backgroundColor: 'white',
        color: 'black',
        boxShadow: '0px 0px 7px -2px rgba(0,0,0,0.75)',
        fontSize: 16,
      },
});

export {CustomTypography,CustomCardHeader,CustomCard,styles }
