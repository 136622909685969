import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import MenuBeacon from '../DropDownManage/Beacon'
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from 'react-socks';
import { stylesHead } from './style';
import Tooltip from '@material-ui/core/Tooltip';
import DetailBeacon from '../../icon/alertBeacon.png';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
setDefaultBreakpoints([
    { xsmall: 0 }, // all mobile devices
    { small: 576 }, // mobile devices (not sure which one's this big)
    { medium: 600 }, // ipad, ipad pro, ipad mini, etc
]);


class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
        };
    };
    handleTooltipClose = () => {
        this.setState({ open: false });
    };

    handleTooltipOpen = () => {
        this.setState({ open: true });
    };

    render() {

        const { classes } = this.props;
        return (
            <div>
                <Paper className={classes.root}>
                    <BreakpointProvider>
                        <div>
                            <Breakpoint small down>
                                <div className={classes.headerpaper}>
                                    <Grid container item xs={12}>
                                        <Grid item xs={12} container direction="row" justify="flex-end" alignItems="center" >
                                        <Tooltip
                                                open={this.state.open}
                                                onClose={this.handleTooltipClose}
                                                title={
                                                    <img src={DetailBeacon} alt="DetailBeacon" height="100%" width="100%" />
                                                }
                                                classes={{ tooltip: classes.lightTooltip }}
                                                placement="bottom-end"
                                            >
                                                <IconButton onTouchEnd={this.handleTooltipOpen} onMouseOver={this.handleTooltipOpen} >
                                                    <ion-icon name="help-circle" color="primary" style={{ fontSize: '30px' }} ></ion-icon>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12} container direction="row" justify="flex-end" alignItems="center" >
                                            <MenuBeacon />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Breakpoint>
                            <Breakpoint medium up>
                                <div className={classes.headerpaper}>
                                    <Grid container item xs={12} direction="column" >
                                        <Grid item xs={12} container justify="flex-end" alignItems="center" >
                                            <Tooltip
                                                open={this.state.open}
                                                onClose={this.handleTooltipClose}
                                                title={
                                                    <img src={DetailBeacon} alt="DetailBeacon" height="100%" width="100%" />
                                                }
                                                classes={{ tooltip: classes.lightTooltip }}
                                                placement="bottom-end"
                                            >
                                                <Button onTouchEnd={this.handleTooltipOpen} onMouseOver={this.handleTooltipOpen}>
                                                    <ion-icon name="help-circle" color="primary" style={{ fontSize: '30px' }} ></ion-icon>
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12} container justify="flex-end" alignItems="center" style={{ marginTop: '0.5vh', }} >
                                            <MenuBeacon />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Breakpoint>
                        </div>
                    </BreakpointProvider>
                </Paper>
            </div>
        )
    }
}
Header.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
};

export default withStyles(stylesHead)(Header)