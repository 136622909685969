import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import '../../App.css'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import RegisterDialog from '../Dialog/Regieter';
import * as UserService from '../../service/UserServices'
import history from "../../history";

const CustomButton = withStyles({
  root: {
    background: "#00a152",
    color: "white",
    width: "4.3cm",
    // padding: '4% 25% 4% 25%',
    marginTop: "10%",
    fontSize: "0.4cm",
  },
})(Button);

class Register extends Component {
  state = {
    username: '',
    password: '',
    conpassword: '',
    email: '',
    phone: '',
    department: '',
    varidattion: '',
    dialogStatus: false
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  onSubmit = async () => {
    const {
      username,
      password,
      conpassword,
      name,
      lastName,
      email,
      department,
      phone
    } = this.state
    if (username.trim().length !== 0 && password.trim().length !== 0 && conpassword.trim().length !== 0 && name.trim().length !== 0 && lastName.trim().length !== 0 && email.trim().length !== 0) {
      if (password === conpassword) {
        let result = await UserService.RegisterUser(username, password, conpassword, name, lastName, email, department, phone)
        if (result === 'ส่งข้อมูลการลงทะเบียน') {
          this.setState({
            varidattion: '',
            dialogStatus: true
          })
        } else {
          this.setState({
            varidattion: result
          })
        }
      } else {
        this.setState({
          varidattion: '*รหัสผ่านไม่ตรงกัน'
        })
      }
    } else {
      this.setState({
        varidattion: '*กรุณาใส่ข้อมูลให้ครบถ้วน'
      })
    }
  }

  handleCloseDialog = () => {
    this.setState({
      handleCloseDialog: false

    })
    setTimeout(() => {
      history.push({
        pathname: '/login',
      });
      window.location.reload()
    }, 100)
  }
  render() {
    console.log(this.state.varidattion)
    const CustomTextfield = {
      marginTop: '2%'
    }
    return (
      <Grid style={{
        display: 'flex',
        flexDirection: 'column',
        alignItem: 'center',
        margin: "auto",
        textAlign: "center"
      }}>
        <Grid xs={12} alignItems="center" justify="center">
          <TextField
            id="username"
            label={<font>ชื่อผู้ใช้งาน</font>}
            value={this.state.username}
            onChange={this.handleChange('username')}
            variant="outlined"
          ></TextField>
        </Grid>

        <Grid xs={12} alignItems="center" justify="center">
          <TextField
            id="password"
            label={<font>รหัสผ่าน</font>}
            type="password"
            value={this.state.password}
            onChange={this.handleChange('password')}
            style={CustomTextfield}
            variant="outlined"
          ></TextField>
        </Grid>
        <Grid xs={12} alignItems="center" justify="center">
          <TextField
            id="confirm-password"
            label={<font>ยืนยันรหัสผ่าน</font>}
            type="password"
            value={this.state.conpassword}
            onChange={this.handleChange('conpassword')}
            style={CustomTextfield}
            variant="outlined"
          ></TextField>
        </Grid>
        <Grid xs={12} alignItems="center" justify="center">
          <TextField
            id="phone"
            label={<font>ชื่อ</font>}
            value={this.state.name}
            onChange={this.handleChange('name')}
            style={CustomTextfield}
            variant="outlined"
          ></TextField>
        </Grid>
        <Grid xs={12} alignItems="center" justify="center">
          <TextField
            id="phone"
            label={<font>นามสกุล</font>}
            value={this.state.lastName}
            onChange={this.handleChange('lastName')}
            style={CustomTextfield}
            variant="outlined"
          ></TextField>
        </Grid>
        <Grid xs={12} alignItems="center" justify="center">
          <TextField
            id="email"
            label={<font>อีเมล</font>}
            value={this.state.email}
            onChange={this.handleChange('email')}
            style={CustomTextfield}
            variant="outlined"
          ></TextField>
        </Grid>
        <Grid xs={12} alignItems="center" justify="center">
          <TextField
            id="phone"
            label={<font>หน่วยงาน</font>}
            value={this.state.department}
            onChange={this.handleChange('department')}
            style={CustomTextfield}
            variant="outlined"
          ></TextField>
        </Grid>
        <Grid xs={12} alignItems="center" justify="center">
          <TextField
            id="phone"
            label={<font>โทรศัพท์</font>}
            value={this.state.phone}
            onChange={this.handleChange('phone')}
            style={CustomTextfield}
            variant="outlined"
          ></TextField>
        </Grid>
        <Grid xs={12} alignItems="center" justify="center">
          <CustomButton variant="contained" color="primary" onClick={this.onSubmit}>
            <font>สมัครสมาชิก</font>
          </CustomButton>
        </Grid>
        <Grid xs={12} alignItems="center" justify="center">
          <div style={{ marginTop: '10px' }}>
            <font color="red">{this.state.varidattion}</font>
          </div>
        </Grid>
        <RegisterDialog dialogStatus={this.state.dialogStatus} handleCloseDialog={this.handleCloseDialog} />
      </Grid >
    )
  }
}

export default Register;